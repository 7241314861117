import { gql } from "@apollo/client";

export const getOrganisationDetails = gql`
  query getOrganisationDetails(
    $organisation_id: Int!
    $user_id: Int!
    $organisation_role: String!
  ) {
    organisation_details(organisation_id: $organisation_id) {
      name
      id
      logo
      new_form
      wage_twenty_one
      wage_eighteen
      wage_sixteen
      wage_twenty_three
      wage_apprentice
      ningi_journey
      banner
    }
    users_roles(
      where: {
        belongs_to_role: {
          name: { _eq: $organisation_role }
          belongs_to_organisation: { enabled: { _eq: true } }
        }
        user_id: { _eq: $user_id }
      }
    ) {
      belongs_to_role {
        id
        name
        belongs_to_organisation {
          id
          name
          belongs_to_scheme(where: { is_active: { _eq: true } }) {
            is_active
            belongs_to_pension_type {
              name
            }
          }
        }
      }
    }
  }
`;

export const getAllOrganisations = gql`
  query getAllOrganisation {
    organisations(where: { enabled: { _eq: true } }) {
      id
      name
      belongs_to_scheme(where: { is_active: { _eq: true } }) {
        is_active
        belongs_to_pension_type {
          name
        }
      }
      has_many_roles(where: { resource_type: { _eq: "Organisation" } }) {
        id
        name
      }
    }
  }
`;

export const getUserOrganisations = gql`
  query getUserOrganisations($user_id: Int!) {
    users_roles(
      where: {
        belongs_to_role: { belongs_to_organisation: { enabled: { _eq: true } } }
        user_id: { _eq: $user_id }
      }
    ) {
      belongs_to_role {
        id
        name
        belongs_to_organisation {
          id
          name
          belongs_to_scheme(where: { is_active: { _eq: true } }) {
            is_active
            belongs_to_pension_type {
              name
            }
          }
        }
      }
    }
  }
`;

export const getExternalSupplierDetails = gql`
  query getExternalSupplierDetails($organisation_id: Int) {
    external_suppliers(
      where: {
        _and: [
          { organisation_id: { _eq: $organisation_id } }
          { sso_supplier: { _eq: 1 } }
        ]
      }
    ) {
      sso_enabled
    }
  }
`;

export const organisationRequiredDetails = gql`
  query organisationRequiredDetails($organisation_id: Int!) {
    pay_periods(
      where: {
        organisation_id: { _eq: $organisation_id }
        enabled: { _eq: true }
      }
    ) {
      id
      period
    }
    pension_funds(
      where: {
        organisation_pension_funds: {
          organisation_id: { _eq: $organisation_id }
        }
      }
      order_by: { name: asc }
    ) {
      id
      name
    }
    providers(
      where: {
        organisation_providers: { organisation_id: { _eq: $organisation_id } }
      }
    ) {
      id
      name
    }
  }
`;

import { useLazyQuery } from "@apollo/client";
import { Box, Container, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getRelatedEvents } from "../../../../../graphql/queries/events";
import SharedHeader from "../../../../generic-components/shared-header/SharedHeader";
import EventsTable from "./EventsTable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import useStepper from "../../../../../hooks/useStepper";
import moment from "moment";
import IconButton from "../../../../generic-components/icon-btn";
import { TRACKING_EVENTS_PAGE_OPTIONS } from "../../../../../constants";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const EventStampPage = ({ organisation, user }) => {
  const params = useParams();
  const navigate = useNavigate();
  const { setErrorToast, setSaveError } = useStepper();
  const [events, setEvents] = useState([]);
  const [fetch, setFetch] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const [fetchRelatedEvents] = useLazyQuery(getRelatedEvents);

  const calculateEventDuration = (startTime, endTime) => {
    if (startTime && endTime) {
      startTime = moment(startTime, "'HH:mm:ss'");
      endTime = moment(endTime, "'HH:mm:ss'");
      const adjustedEndTime =
        endTime.format("HH:mm:ss") === "00:00:00"
          ? moment("24:00:00", "HH:mm:ss")
          : endTime;
      const durationInMilliseconds = adjustedEndTime.diff(startTime);
      return Math.abs(moment.duration(durationInMilliseconds).asMinutes());
    }
  };

  const customizedEventsData = (presentations) => {
    let customeEventsData = [];
    customeEventsData = presentations.map((presentation) => {
      return {
        ...presentation,
        duration:
          calculateEventDuration(
            presentation?.presentation_time,
            presentation?.presentation_end_time
          ) || "",
        available_spaces:
          presentation?.number_of_places - presentation?.booked_places >= 1
            ? presentation?.number_of_places - presentation?.booked_places
            : 0,
        presentation_time: presentation?.presentation_time
          ? moment(presentation?.presentation_time, "HH:mm:ss").format(
              "hh:mm a"
            )
          : "_",
        presentation_date: presentation?.presentation_date
          ? moment(presentation?.presentation_date).format("DD/MM/YYYY")
          : "_",
      };
    });
    setEvents(customeEventsData);
  };

  const getAllRelatedEvents = async () => {
    setIsLoading(true);
    fetchRelatedEvents({
      fetchPolicy: "no-cache",
      variables: {
        organisation_id: organisation.id,
        title: params.title || "",
        user_id: user.id,
        limit: rowsPerPage,
        offset: rowsPerPage * (page - 1),
        current_date: moment().format("YYYY-MM-DD"),
        current_time: moment().format("HH:mm:ss"),
      },
      onCompleted: (data) => {
        if (!data?.presentations_aggregate?.aggregate?.count) {
          navigate("/404");
        }
        setCount(data?.presentations_aggregate?.aggregate?.count || 0);
        customizedEventsData(data?.presentations || []);
        setIsLoading(false);
      },
      onError: (error) => {
        setIsLoading(false);
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };

  useEffect(() => {
    if (organisation.id && user.id && params.title) {
      getAllRelatedEvents();
    }
  }, [organisation.id, user.id, params.title, page, rowsPerPage, fetch]);
  return (
    <Box className="application-page-container mb-100">
      <SharedHeader heading="Events" />
      <Box className="mt-150">
        <Container>
          <IconButton
            trackingDetails={TRACKING_EVENTS_PAGE_OPTIONS}
            buttonTitle="Back to Events"
            styleClass="light-backbtn"
            handleClick={() => navigate("/events")}
            link="#"
            icon={<KeyboardArrowLeftIcon />}
          />
          <Box className="stamp-page mt-30 mb-30">
            <Box className="event-heading-container">
              <Typography className="event-heading">{params?.title}</Typography>
            </Box>
            <Box className="event-details-container">
              <Typography className="mt-18">
                <span className="mr-10">
                  <strong>Target Audience:</strong>
                </span>
                {events[0]?.target_audience || null}
              </Typography>
              <EventsTable
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                events={events}
                fetch={fetch}
                setPage={setPage}
                setFetch={setFetch}
                setRowsPerPage={setRowsPerPage}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state?.user?.user,
    organisation: state?.organisation?.organisation,
  };
};

EventStampPage.propTypes = {
  organisation: PropTypes.object,
  user: PropTypes.object,
};

export default connect(mapStateToProps)(EventStampPage);

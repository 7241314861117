import { formatCurrency } from "../../../../../../helpers";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import MyPlanHeader from "../../cancel-my-shared-cost-avc/cancel-my-shared-cost-content/MyPlanHeader";
import IconInputField from "../../../../../generic-components/icon-inputfield";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import Accordion from "../../../../../generic-components/accordion";
import TextInput from "../../../../../generic-components/input-text";
import FormSelect from "../../../../../generic-components/form-select";
import MaskedInput from "../../../../../generic-components/masked-input";
import DateInput from "../../../../../generic-components/date-input";
import LoadingButton from "../../../../../generic-components/button";
import AmendmentDescriptionContent from "./AmendmentDescriptionContent";
import moment from "moment";
import debounce from "lodash.debounce";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { AccordionDetails, Box, Grid, Stack, Typography } from "@mui/material";
import {
  floatRegex,
  titles,
  TRACKING_MANAGE_MY_SHARED_COST_OPTIONS,
} from "../../../../../../constants";
import { useLazyQuery, useMutation } from "@apollo/client";
import { getPlanAmendmentReasons } from "../../../../../../graphql/queries/manageMySharedCost";
import useStepper from "../../../../../../hooks/useStepper";
import usePlan from "../../../../../../hooks/usePlan";
import Loader from "../../../../../generic-components/loader";
import { useLocation, useNavigate } from "react-router-dom";
import { getUserDetails } from "../../../../../../graphql/queries/user";
import { connect, useDispatch } from "react-redux";
import { setUser } from "../../../../../../reducers/userReducer";
import { getTax } from "../../../../../../graphql/queries/getTax";
import { amendPlanValidationSchema } from "../../../../../../schema";
import ScrollToFieldError from "../../../../../generic-components/scrollToFieldError";
import MaxContributionCalculationForm from "../../../shared-cost-application/forms/MaxContributionCalculationForm";
import { amendMyPlan } from "../../../../../../graphql/mutations/manageMySharedCost";
import { updateUserInfo } from "../../../../../../graphql/mutations/personalDetails";
import ContributionCalculatorModal from "../../../shared-cost-application/modal/ContributionCalculatorModal";
import AmendmentReasons from "../AmendmentReasons";
import IconButton from "../../../../../generic-components/icon-btn";
import RequiredDetailsDialog from "./RequiredDetailsDialog";
import isEmpty from "lodash.isempty";

const AmendMySharedCostContent = ({ plan, scheme }) => {
  const {
    setErrorToast,
    setSaveError,
    setPersonalDetails,
    personalDetails,
    trackingCode,
  } = useStepper();
  const { setPlans } = usePlan();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [showAmendmentReasons, setShowAmendmentReasons] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [amendmentReasons, setAmendmentReasons] = useState([]);
  const [customError, setCustomError] = useState(null);
  const [initialValues, setInitialValues] = useState({});
  const [savings, setSavings] = useState({});
  const { state } = useLocation();

  const [getSavings, { loading: taxLoading }] = useLazyQuery(getTax);
  const [updateUserDetails, { loading: updateUserLoading }] =
    useMutation(updateUserInfo);
  const [amendMySharedCostAVCPlan, { loading: amendMyPlanLoading }] =
    useMutation(amendMyPlan);

  const handleBack = () => {
    navigate(state?.backUrl ? state.backUrl : "/manage_my_shared_cost_avc");
  };

  const getSavingsDetails = (avcAmount) => {
    if (
      plan.annual_salary &&
      plan.avc_interval &&
      (avcAmount || plan.total_avc_amount_requested) &&
      scheme.scheme_id
    ) {
      getSavings({
        variables: {
          annual_salary: plan?.annual_salary,
          total_avc_amount_requested: parseFloat(
            avcAmount ? avcAmount : plan?.total_avc_amount_requested
          ),
          avc_interval: plan?.avc_interval,
          scheme_id: scheme?.scheme_id,
          add_ni_savings_onto_avc: false,
        },
        onCompleted: (data) => {
          setSavings(data);
        },
        onError: (error) => {
          setSaveError(error);
          setErrorToast(true);
        },
      });
    }
  };

  const [fetchAmendmentReasons] = useLazyQuery(getPlanAmendmentReasons, {
    onCompleted: (data) => {
      const planAmendmentReasons =
        data?.plan_reasons?.map((reason) => ({
          name: reason.name,
          value: reason.name,
          id: reason.id,
          increase: reason.increase,
        })) || [];

      setAmendmentReasons(planAmendmentReasons);
      setIsLoading(false);
    },
    onError: (error) => {
      setIsLoading(false);
      setSaveError(error);
      setErrorToast(true);
    },
  });

  const [fetchUserDetails] = useLazyQuery(getUserDetails, {
    onCompleted: (data) => {
      setPersonalDetails(data?.me);
      dispatch(setUser(data?.me));
    },
    onError: (error) => {
      setIsLoading(false);
      setSaveError(error);
      setErrorToast(true);
    },
  });

  const isCustomError = useMemo(() => {
    return !!Object.keys(customError || []).filter((key) => customError[key])
      .length;
  }, [customError]);

  const handleAmendment = (values) => {
    if (Number(values.avcAmount) === Number(plan?.total_avc_amount_requested)) {
      setCustomError({
        ...customError,
        avcAmount: "You don't have made any amendment in your avc amount.",
      });
      return;
    }

    const amendmentReasonDetails =
      Number(values.avcAmount) > Number(plan?.total_avc_amount_requested)
        ? amendmentReasons.find(
            (reason) =>
              reason.name === values.amendmentReason && reason.increase
          )
        : amendmentReasons.find(
            (reason) =>
              reason.name === values.amendmentReason && !reason.increase
          );

    const employeeDetails = {
      email: personalDetails.email,
      employee_number: personalDetails?.employee_number?.trim(),
      ni_number: personalDetails.ni_number,
      title: values.title,
      date_of_birth: values.date_of_birth,
      first_name: values.first_name,
      last_name: values.last_name,
      mobile_number: values.mobile_number,
      telephone_number: values.mobile_number,
      delivery_postcode: values.postcode,
      delivery_town: values.town,
      delivery_address1: values.address1,
      delivery_address2: values.address2,
      delivery_county: values.county,
    };
    updateUserDetails({
      variables: employeeDetails,
      onCompleted: (data) => {
        amendMySharedCostAVCPlan({
          variables: {
            plan_id: Number(plan.id),
            avc_track_code: trackingCode,
            total_avc_amount_requested: Number(values.avcAmount),
            amend_reason_id: Number(amendmentReasonDetails.id),
            amend_reason_other: values.otherReason,
            ...employeeDetails,
          },
          onCompleted: () => {
            setPlans([]);
            navigate("/manage_my_shared_cost_avc");
          },
          onError: (error) => {
            setSaveError(error);
            setErrorToast(true);
          },
        });
        setPersonalDetails({
          ...personalDetails,
          ...data.updateUserDetails,
          address1: data.updateUserDetails.address_1,
          address2: data.updateUserDetails.address_2,
        });
      },
      onError: (error) => {
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };

  const getSavingsDebounce = debounce((avcAmount) => {
    getSavingsDetails(avcAmount);
  }, 1000);

  const handleOtherReason = (_, value, setFieldValue) => {
    if (value !== "Other – please state below") {
      setFieldValue("otherReason", "");
    }
  };

  const handleShowAmendementReasons = (value) => {
    if (value && Number(plan.total_avc_amount_requested) !== Number(value)) {
      setShowAmendmentReasons(true);
      return;
    }
    setShowAmendmentReasons(false);
  };

  useEffect(() => {
    setInitialValues({
      avcAmount: plan?.total_avc_amount_requested,
      income: plan?.annual_salary,
      hours_per_week: plan?.hours_per_week,
      max_contribution_amount: "",
      ni_number: personalDetails?.ni_number || "",
      title: personalDetails?.title || "",
      first_name: personalDetails.first_name || "",
      last_name: personalDetails.last_name || "",
      date_of_birth: personalDetails.date_of_birth || "",
      mobile_number: personalDetails.mobile_number || "",
      address1: personalDetails.address1 || "",
      address2: personalDetails.address2 || "",
      email: personalDetails.email || "",
      town: personalDetails.town || "",
      county: personalDetails.county || "",
      postcode: personalDetails.postcode || "",
      amendmentReason: "",
      otherReason: "",
      isAmendmentReason: true,
    });

    if (
      !isEmpty(personalDetails) &&
      !isEmpty(plan) &&
      (!plan.hours_per_week ||
        !plan.annual_salary ||
        !personalDetails.date_of_birth)
    ) {
      setOpen(true);
    }
  }, [personalDetails, plan]);

  useEffect(() => {
    fetchUserDetails();
    fetchAmendmentReasons();
  }, []);

  useEffect(() => {
    getSavingsDetails();
  }, [plan, scheme]);
  return (
    <>
      <IconButton
        trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
        buttonTitle="Back"
        styleClass="light-backbtn mb-18"
        handleClick={handleBack}
        link="#"
        icon={<KeyboardArrowLeftIcon />}
      />
      <MyPlanHeader heading="My Plans" />
      <Box className={"cancel-plan-content-flex-start width-restriction mb-50"}>
        <Box className={"generic-heading-cotainer flex-start"}>
          <Typography className="generic-heading">
            Amend my Shared Cost AVC plan
          </Typography>
        </Box>
        {isLoading ? (
          <Box className="flex-box-center full-width mt-100">
            <Loader />
          </Box>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={amendPlanValidationSchema}
            enableReinitialize
            onSubmit={handleAmendment}
          >
            {({ values, handleBlur, handleSubmit, setFieldValue }) => (
              <Form onSubmit={handleSubmit}>
                <MaxContributionCalculationForm />
                <ScrollToFieldError
                  isCustomError={isCustomError}
                  customError={customError}
                />
                <Box className="avc-amount-box mt-18">
                  <Box className="shared-cost-application-header long-header">
                    <Box className="choosenAmount">
                      <strong>
                        <span>Current amount:</span>{" "}
                        {formatCurrency(plan.total_avc_amount_requested)}
                      </strong>
                    </Box>
                    <Box className="choosenAmount">
                      <strong>
                        <span className="modified-span">
                          Your annual salary:{" "}
                        </span>
                        {formatCurrency(plan.annual_salary)}
                      </strong>
                    </Box>
                  </Box>
                  <Stack
                    direction={{ md: "row", sm: "column" }}
                    justifyContent="space-between"
                    spacing={3}
                  >
                    <IconInputField
                      id="avcAmount"
                      name="avcAmount"
                      customError={customError}
                      trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
                      label={"Enter your new Shared Cost AVC amount"}
                      value={values.avcAmount}
                      icon={<CurrencyPoundIcon className="input-field-icon" />}
                      isValidValue={(e) => {
                        return (
                          e.target.value > 0 &&
                          floatRegex.test(parseFloat(e.target.value))
                        );
                      }}
                      handleChangeValue={(e, isValidValue) => {
                        setCustomError({ ...customError, [e.target.name]: "" });
                        if (isValidValue) {
                          handleShowAmendementReasons(e.target.value);
                          setFieldValue("avcAmount", e.target.value);
                          getSavingsDebounce(e.target.value);
                        } else setFieldValue("avcAmount", "");
                      }}
                      handleBlurValue={handleBlur}
                    />
                    <Box className="amend-form-tab mt-18">
                      <Typography className="form-text">
                        Want to find out the maximum amount you could pay into a
                        Shared Cost AVC scheme?{" "}
                        <span
                          className="web-link cursor-pointer"
                          onClick={() => setOpenModal(true)}
                        >
                          <span className="underline-text">Click here</span> to
                          use our calculator
                        </span>
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
                {showAmendmentReasons ? (
                  <>
                    <Box className="amend-page-accordian">
                      <Accordion
                        expanded={expanded}
                        setExpanded={setExpanded}
                        summary={
                          "Please confirm all your details below are up-to-date, and amend where required."
                        }
                        summaryClass="summary"
                        summaryTextClass="summary-text"
                      >
                        <AccordionDetails className="accordion-details">
                          <Grid container>
                            <Grid item md={6} lg={6}>
                              <MaskedInput
                                name="ni_number"
                                label="National Insurance Number *"
                                mask="** ****** *"
                                disabled={true}
                                trackingDetails={
                                  TRACKING_MANAGE_MY_SHARED_COST_OPTIONS
                                }
                                value={values.ni_number || ""}
                                styleClass="mt-18"
                              />
                              <FormSelect
                                name="title"
                                labelId="titleList"
                                menuItems={titles}
                                label="Title *"
                                width={30}
                                trackingDetails={
                                  TRACKING_MANAGE_MY_SHARED_COST_OPTIONS
                                }
                              />
                              <TextInput
                                id="first_name"
                                name="first_name"
                                label="First Name *"
                                trackingDetails={
                                  TRACKING_MANAGE_MY_SHARED_COST_OPTIONS
                                }
                                value={values.first_name || ""}
                              />
                              <TextInput
                                id="last_name"
                                name="last_name"
                                label="Last Name *"
                                trackingDetails={
                                  TRACKING_MANAGE_MY_SHARED_COST_OPTIONS
                                }
                                value={values.last_name || ""}
                              />
                              <DateInput
                                id="date_of_birth"
                                name="date_of_birth"
                                label="Date of Birth *"
                                trackingDetails={
                                  TRACKING_MANAGE_MY_SHARED_COST_OPTIONS
                                }
                                value={values.date_of_birth}
                                handleChangeValue={(value) =>
                                  setFieldValue(
                                    "date_of_birth",
                                    moment(value).format("YYYY-MM-DD")
                                  )
                                }
                                handleBlurValue={($event) => handleBlur($event)}
                              />
                              <TextInput
                                id="mobile_number"
                                name="mobile_number"
                                label="Phone Number *"
                                trackingDetails={
                                  TRACKING_MANAGE_MY_SHARED_COST_OPTIONS
                                }
                                value={values.mobile_number || ""}
                              />
                              <TextInput
                                id="email"
                                name="email"
                                label="Email *"
                                trackingDetails={
                                  TRACKING_MANAGE_MY_SHARED_COST_OPTIONS
                                }
                                value={values.email || ""}
                                disabled={true}
                              />
                              <TextInput
                                id="address1"
                                name="address1"
                                label="Home Address#1 *"
                                trackingDetails={
                                  TRACKING_MANAGE_MY_SHARED_COST_OPTIONS
                                }
                                value={values.address1 || ""}
                              />
                              <TextInput
                                id="address2"
                                name="address2"
                                label="Home Address#2"
                                trackingDetails={
                                  TRACKING_MANAGE_MY_SHARED_COST_OPTIONS
                                }
                                value={values.address2 || ""}
                              />
                              <TextInput
                                id="town"
                                name="town"
                                label="Town *"
                                trackingDetails={
                                  TRACKING_MANAGE_MY_SHARED_COST_OPTIONS
                                }
                                value={values.town || ""}
                              />
                              <TextInput
                                id="county"
                                name="county"
                                label="County *"
                                trackingDetails={
                                  TRACKING_MANAGE_MY_SHARED_COST_OPTIONS
                                }
                                value={values.county || ""}
                              />
                              <TextInput
                                id="postcode"
                                name="postcode"
                                label="Post Code *"
                                trackingDetails={
                                  TRACKING_MANAGE_MY_SHARED_COST_OPTIONS
                                }
                                value={values.postcode || ""}
                              />
                            </Grid>
                          </Grid>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                    <Box className="amendment-reason-box mt-12">
                      <Typography>
                        <strong>Amendment reason:</strong>
                      </Typography>
                      <Grid container>
                        <Grid item xs={12} sm={12} md={6} lg={6}>
                          <AmendmentReasons
                            amendmentReasons={amendmentReasons}
                            total_avc_amount_requested={
                              plan.total_avc_amount_requested
                            }
                            handleOtherReason={handleOtherReason}
                          />
                        </Grid>
                      </Grid>
                      <AmendmentDescriptionContent
                        avcAmount={values.avcAmount}
                        savings={savings}
                        isLoading={taxLoading}
                      />
                      <Stack
                        direction="row"
                        justifyContent="center"
                        className="mt-30"
                      >
                        <LoadingButton
                          buttonTitle="Amend my Shared Cost AVC plan"
                          trackingDetails={
                            TRACKING_MANAGE_MY_SHARED_COST_OPTIONS
                          }
                          handleClick={handleSubmit}
                          disabled={amendMyPlanLoading || updateUserLoading}
                          loading={amendMyPlanLoading || updateUserLoading}
                          styleClass="service-button disable-text-transform"
                          type="submit"
                        />
                      </Stack>
                    </Box>
                  </>
                ) : null}
                <ContributionCalculatorModal
                  open={openModal}
                  setOpen={setOpenModal}
                  sharedCostValues={values}
                  applyButtonTitle="Review my amendment request"
                  islargeApplyButton={true}
                  showCloseButton={false}
                />

                <RequiredDetailsDialog
                  open={open}
                  onClose={() => setOpen(false)}
                  initialValues={{
                    plan_id: plan?.id,
                    annual_salary: plan?.annual_salary,
                    hours_per_week: plan?.hours_per_week,
                    date_of_birth: personalDetails?.date_of_birth,
                  }}
                  handleBack={handleBack}
                />
              </Form>
            )}
          </Formik>
        )}
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    scheme: state.scheme.scheme,
  };
};

AmendMySharedCostContent.propTypes = {
  plan: PropTypes.object,
  scheme: PropTypes.object,
};

export default connect(mapStateToProps)(AmendMySharedCostContent);

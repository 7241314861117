import { Typography, Stack, Switch, FormControlLabel } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import useUserEvent from "../../../hooks/useUserEvent";
import useStepper from "../../../hooks/useStepper";
import { findItemBySelectedValue } from "../../../helpers";

const SwitchButton = ({ name, setValue, trackingDetails }) => {
  const { values, handleChange, setFieldValue } = useFormikContext();
  const { userTrackingMutation } = useUserEvent();
  const { trackingCode } = useStepper();

  const handleUserEvent = (element) => {
    const { name, checked } = element?.target || {};
    const { fieldName } = findItemBySelectedValue(name);
    userTrackingMutation({
      variables: {
        ...trackingDetails,
        field_name: fieldName || "",
        field_value: checked
          ? `Select - ${fieldName}`
          : `Unselect - ${fieldName}`,
        avc_track_code: trackingCode,
      },
    });
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center" className="mt-18">
      <Typography className="stepper-content">No</Typography>
      <FormControlLabel
        control={
          <Switch
            name={name}
            checked={values[name]}
            onChange={($event) => {
              handleUserEvent($event);
              handleChange($event);
              setValue && setValue($event.target.value, setFieldValue);
            }}
            className="switch-button"
          />
        }
        name={name}
        id={name}
        value={values[name]}
      />

      <Typography className="stepper-content">Yes</Typography>
    </Stack>
  );
};

SwitchButton.propTypes = {
  name: PropTypes.string,
  setValue: PropTypes.func,
  trackingDetails: PropTypes.object,
};

export default SwitchButton;

import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Stack, TablePagination, TableSortLabel } from "@mui/material";
import LoadingButton from "../../../../generic-components/button";
import PropTypes from "prop-types";
import { TRACKING_EVENTS_PAGE_OPTIONS } from "../../../../../constants";
import Loader from "../../../../generic-components/loader";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import moment from "moment";
import { useState } from "react";
import BookingCancellationDialog from "./BookingCancellationDialog";
import useStepper from "../../../../../hooks/useStepper";
import { useMutation } from "@apollo/client";
import {
  cancelEventBooking,
  markUserPositiveAttendence,
} from "../../../../../graphql/mutations/events";
import {
  isOneHourLeft,
  isTenMinutesLeft,
  redirectToURL,
} from "../../../../../helpers";
import { connect } from "react-redux";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#025496",
    border: "1px solid #025496",
    color: "#fff",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0.625rem",
    fontWeight: "bold",
  },
}));

const StyledTableRow = styled(TableRow)({
  backgroundColor: "transparent",
  border: "1px solid #E3E3E3",
});

const columns = [
  {
    id: "title",
    label: "Webinar Name",
    align: "center",
  },
  {
    id: "presentation_date",
    label: "Date",
    align: "center",
  },
  {
    id: "presentation_time",
    label: "Time",
    align: "center",
  },
  {
    id: "target_audience",
    label: "Target Audience",
    align: "center",
  },
];

const BookingTable = ({
  page,
  user,
  count,
  bookings,
  fetch,
  setFetch,
  setPage,
  rowsPerPage,
  organisation,
  isLoading,
  setIsLoading,
  setRowsPerPage,
}) => {
  const { setErrorToast, setSaveError } = useStepper();
  const [orderBy, setOrderBy] = useState("presentation_date");
  const [cancelConfirmation, setCancelConfirmation] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [order, setOrder] = useState("asc");

  const handleSortChange = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedBookings = [...bookings].sort((a, b) => {
    if (orderBy === "presentation_date") {
      const dateA = moment(a.presentation[orderBy], "YYYY/MM/DD");
      const dateB = moment(b.presentation[orderBy], "YYYY/MM/DD");
      if (order === "asc") {
        return dateA.isAfter(dateB) ? 1 : -1;
      } else {
        return dateA.isBefore(dateB) ? 1 : -1;
      }
    } else {
      if (order === "asc") {
        return a.presentation[orderBy] > b.presentation[orderBy] ? 1 : -1;
      } else {
        return a.presentation[orderBy] < b.presentation[orderBy] ? 1 : -1;
      }
    }
  });

  const [cancelBooking, { loading: cancelLoading }] =
    useMutation(cancelEventBooking);
  const [markUserAttendence, { loading: attendenceLoading }] = useMutation(
    markUserPositiveAttendence
  );

  const handlePageChange = (_, newPage) => {
    setPage(newPage + 1);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(+e?.target?.value);
    setPage(1);
  };

  const handleJoinNow = (booking) => {
    const presentationDate = moment(
      booking?.presentation?.presentation_date
    ).format("DD/MM/YYYY");
    const presentationStartTime = moment(
      booking?.presentation?.presentation_time,
      "HH:mm:ss"
    ).format("hh:mm a");
    const presentationEndTime = moment(
      booking?.presentation?.presentation_end_time,
      "HH:mm:ss"
    ).format("hh:mm a");

    if (
      isTenMinutesLeft(
        presentationDate,
        presentationStartTime,
        presentationEndTime
      ) &&
      !booking.attended
    ) {
      setIsLoading(true);
      markUserAttendence({
        variables: {
          user_id: user?.id,
          presentation_id: booking?.presentation?.id,
          organisation_id: organisation?.id,
        },
        onCompleted: () => {
          setFetch(!fetch);
        },
        onError: (error) => {
          setSaveError(error);
          setErrorToast(true);
          setIsLoading(false);
        },
      });
    }

    redirectToURL(booking?.presentation?.webinar_link, true);
  };

  const handleCloseCancelConfirmation = () => {
    setCancelConfirmation(false);
  };

  const handleEventsBooking = (booking) => {
    setDialogData(booking);
    setCancelConfirmation(true);
  };

  const handleBookingCancellation = async (booking) => {
    if (booking?.id) {
      setIsLoading(true);
      setCancelConfirmation(false);
      cancelBooking({
        variables: {
          booking_id: booking?.id,
        },
        onCompleted: () => {
          setFetch(!fetch);
        },
        onError: (error) => {
          setIsLoading(false);
          setSaveError(error);
          setErrorToast(true);
        },
      });
    } else {
      setCancelConfirmation(false);
      setSaveError({ type: "error" });
      setErrorToast(true);
    }
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          borderRadius: "0",
          boxShadow: "none",
        }}
        className="mt-30"
      >
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              {columns?.map((column) => {
                return (
                  <StyledTableCell
                    key={column.id}
                    align={column.align}
                    sortDirection={orderBy === column.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={() => handleSortChange(column.id)}
                      sx={{
                        "&.MuiTableSortLabel-root .MuiTableSortLabel-icon": {
                          color: "white !important",
                        },
                        "&.MuiTableSortLabel-root": {
                          color: "white !important",
                        },
                        "&.Mui-active": {
                          color: "white !important",
                        },
                      }}
                    >
                      {column.label}
                    </TableSortLabel>
                  </StyledTableCell>
                );
              })}
              <StyledTableCell align="center" width="35%" />
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <StyledTableRow>
                <StyledTableCell colSpan={12} align="center">
                  <Loader size={35} />
                </StyledTableCell>
              </StyledTableRow>
            ) : sortedBookings?.length ? (
              sortedBookings.map((booking, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="center">
                    {booking?.presentation?.title || "_"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {booking?.presentation?.presentation_date
                      ? moment(booking?.presentation?.presentation_date).format(
                          "DD/MM/YYYY"
                        )
                      : "_"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {booking?.presentation?.presentation_time
                      ? moment(
                          booking?.presentation?.presentation_time,
                          "HH:mm:ss"
                        ).format("hh:mm a")
                      : "_"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {booking?.presentation?.target_audience || "_"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Stack direction="row" spacing={3}>
                      <LoadingButton
                        buttonTitle="Cancel Booking"
                        trackingDetails={TRACKING_EVENTS_PAGE_OPTIONS}
                        disabled={
                          attendenceLoading || cancelLoading || isLoading
                        }
                        handleClick={() => handleEventsBooking(booking)}
                        styleClass="booking_btn booked"
                      />
                      {booking?.presentation?.presentation_type === "Webinar" &&
                      isOneHourLeft(
                        moment(booking?.presentation?.presentation_date).format(
                          "DD/MM/YYYY"
                        ),
                        moment(
                          booking?.presentation?.presentation_time,
                          "HH:mm:ss"
                        ).format("hh:mm a"),
                        moment(
                          booking?.presentation?.presentation_end_time,
                          "HH:mm:ss"
                        ).format("hh:mm a")
                      ) ? (
                        <LoadingButton
                          buttonTitle="Join now"
                          icon={
                            <OndemandVideoIcon
                              sx={{ padding: "2px", margin: "4px" }}
                            />
                          }
                          disabled={
                            attendenceLoading || cancelLoading || isLoading
                          }
                          trackingDetails={TRACKING_EVENTS_PAGE_OPTIONS}
                          handleClick={() => handleJoinNow(booking)}
                          styleClass="booking_btn join-now"
                        />
                      ) : null}
                    </Stack>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell colSpan={12} align="center">
                  No records found.
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          page={page - 1}
          rowsPerPageOptions={[5, 10, 15]}
          count={count}
          labelRowsPerPage="Rows per page"
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </TableContainer>
      <BookingCancellationDialog
        open={cancelConfirmation}
        event={dialogData}
        onClose={handleCloseCancelConfirmation}
        isLoading={cancelLoading || isLoading}
        handleBookingCancellation={handleBookingCancellation}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    organisation: state?.organisation?.organisation,
    user: state?.user?.user,
  };
};

BookingTable.propTypes = {
  bookings: PropTypes.array,
  count: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  fetch: PropTypes.bool,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  setPage: PropTypes.func,
  setFetch: PropTypes.func,
  setRowsPerPage: PropTypes.func,
  organisation: PropTypes.object,
  user: PropTypes.object,
};

export default connect(mapStateToProps)(BookingTable);

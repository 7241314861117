import { Box, Stack, Typography } from "@mui/material";
import EventHeaderBg from "../../../../../assets/images/event-header.png";
import { Container } from "@mui/system";

const EventsPageHeader = () => {
  return (
    <Container>
      <Box
        sx={{ backgroundImage: `url(${EventHeaderBg})` }}
        className="event-page-header"
      >
        <Stack
          direction="column"
          justifyContent="flex-start"
          className="ml-30 mr-30"
        >
          <Typography className="event-page-heading bold-heading">
            Book onto a webinar
          </Typography>
          <Typography className="mt-10 event-page-heading">
            You can see the upcoming and available webinars and events below.
          </Typography>
          <Typography className="mt-10 event-page-heading">
            Use the filter to find the webinar you want to join, check the
            available spaces and dates, and get booked on!
          </Typography>
        </Stack>
      </Box>
    </Container>
  );
};

export default EventsPageHeader;

import React from "react";
import { LoadingButton as MUILoadingButton } from "@mui/lab";
import PropTypes from "prop-types";
import useUserEvent from "../../../hooks/useUserEvent";
import useStepper from "../../../hooks/useStepper";

const LoadingButton = ({
  buttonTitle,
  loading = false,
  handleClick,
  trackingDetails,
  styleClass,
  disabled,
  type = "button",
  icon = <></>,
  submitValid = false,
}) => {
  const { userTrackingMutation } = useUserEvent();
  const { trackingCode } = useStepper();

  const handleUserEvent = (element) => {
    userTrackingMutation({
      variables: {
        ...trackingDetails,
        field_name: element.target?.textContent || "Button",
        field_value: element.target?.value?.toString() || "Clicked",
        avc_track_code: trackingCode,
      },
    });
  };

  return (
    <>
      <MUILoadingButton
        className={styleClass}
        loadingPosition="end"
        loading={loading}
        disabled={disabled}
        type={type}
        onClick={(e) => {
          submitValid
            ? handleClick && handleClick()
            : (handleUserEvent(e), handleClick && handleClick());
        }}
      >
        {icon}
        {buttonTitle}
      </MUILoadingButton>
    </>
  );
};

LoadingButton.propTypes = {
  buttonTitle: PropTypes.string,
  loading: PropTypes.bool,
  handleClick: PropTypes.func,
  trackingDetails: PropTypes.object,
  styleClass: PropTypes.string,
  disabled: PropTypes.func,
  type: PropTypes.string,
  submitValid: PropTypes.bool,
  icon: PropTypes.any,
};

export default LoadingButton;

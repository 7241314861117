import { Box, Dialog, DialogTitle, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { TRACKING_EVENTS_PAGE_OPTIONS } from "../../../../../constants";
import LoadingButton from "../../../../generic-components/button";

const BookingCancellationDialog = ({
  open,
  event,
  onClose,
  isLoading,
  handleBookingCancellation,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="event-confirmation-dialog cancel-confirmation-dialog"
    >
      <DialogTitle className="dialog-header">
        <Typography className="dialog-heading">Cancel Booking</Typography>
      </DialogTitle>
      <Box className="detail-heading-box">
        <Typography>
          Are you sure you want to cancel this webinar booking?
        </Typography>
      </Box>
      <Stack
        direction="row"
        justifyContent="space-evenly"
        className="mt-10 mb-18"
        flexWrap="wrap"
      >
        <LoadingButton
          buttonTitle="Keep my booking"
          disabled={isLoading}
          trackingDetails={TRACKING_EVENTS_PAGE_OPTIONS}
          styleClass="confirmation-btn bg-primary white-text mt-10"
          handleClick={onClose}
        />
        <LoadingButton
          buttonTitle="Cancel my booking"
          loading={isLoading}
          disabled={isLoading}
          trackingDetails={TRACKING_EVENTS_PAGE_OPTIONS}
          styleClass="confirmation-btn bg-moderate-red white-text mt-10"
          handleClick={() => {
            handleBookingCancellation(event);
          }}
        />
      </Stack>
    </Dialog>
  );
};

BookingCancellationDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  event: PropTypes.object,
  isLoading: PropTypes.bool,
  handleBookingCancellation: PropTypes.func,
};

export default BookingCancellationDialog;

import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import {
  TRACKING_CHANGE_PORTAL_OPTIONS,
  userRoles,
} from "../../../../constants";
import useStepper from "../../../../hooks/useStepper";
import AutocompleteField from "../../../generic-components/autocomplete-field";
import LoadingButton from "../../../generic-components/button";
import Loader from "../../../generic-components/loader";
import SharedHeader from "../../../generic-components/shared-header/SharedHeader";
import { useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import MyPlanHeader from "../manage-my-shared-cost/cancel-my-shared-cost-avc/cancel-my-shared-cost-content/MyPlanHeader";
import {
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  getAllOrganisations,
  getUserOrganisations,
} from "../../../../graphql/queries/OrganisationDetails";
import { UpdateUserCurrentOrganisation } from "../../../../graphql/mutations/personalDetails";
import {
  compileOrganisationData,
  parameterizedDetail,
  sortArray,
} from "../../../../helpers";
import isEmpty from "lodash.isempty";

const ChangePortalPage = () => {
  const tablet = useMediaQuery("(max-width:900px)");
  const { personalDetails, setSaveError, setErrorToast, setFullScreenLoading } =
    useStepper();
  const navigate = useNavigate();

  const [organisations, setOrganisations] = useState([]);
  const [allOrganisations, setAllOrganisations] = useState([]);
  const [adminOrganisations, setAdminOrganisations] = useState([]);
  const [allAdminOrganisations, setAllAdminOrganisations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [fetchAllOrganisations] = useLazyQuery(getAllOrganisations);
  const [fetchUserOrganisations] = useLazyQuery(getUserOrganisations);
  const [setCurrentOrganisation] = useMutation(UpdateUserCurrentOrganisation);

  const handelRedirectToBackendArea = () => {
    setFullScreenLoading(true);
    setIsLoading(true);
    window.location.href = `${process.env.REACT_APP_AVCWISE_URL}/admin/dashboard`;
  };

  const fetchUserOrganisationsDetails = () => {
    if (personalDetails.backend_user) {
      fetchAllOrganisations({
        onCompleted: (data) => {
          compileOrganisations(
            data?.organisations,
            setOrganisations,
            setAllOrganisations,
            true,
            userRoles.ORGANISATION_EMPLOYEE
          );
          compileOrganisations(
            data?.organisations,
            setAdminOrganisations,
            setAllAdminOrganisations,
            true,
            userRoles.ORGANISATION_ADMIN
          );
          setIsLoading(false);
        },
        onError: (error) => {
          setSaveError(error);
          setErrorToast(true);
          setIsLoading(false);
        },
      });
    } else {
      fetchUserOrganisations({
        variables: {
          user_id: personalDetails.id,
        },
        onCompleted: (data) => {
          const employeeOrganisations = data?.users_roles.filter(
            (role) =>
              role?.belongs_to_role?.name === userRoles.ORGANISATION_EMPLOYEE
          );
          const adminOrganisations = data?.users_roles?.filter(
            (role) =>
              role?.belongs_to_role?.name === userRoles.ORGANISATION_ADMIN
          );
          compileOrganisations(
            employeeOrganisations,
            setOrganisations,
            setAllOrganisations,
            false
          );
          compileOrganisations(
            adminOrganisations,
            setAdminOrganisations,
            setAllAdminOrganisations,
            false
          );
          setIsLoading(false);
        },
        onError: (error) => {
          setSaveError(error);
          setErrorToast(true);
          setIsLoading(false);
        },
      });
    }
  };

  const compileOrganisations = (
    organisations,
    setState,
    setAllState,
    backendUser,
    userRole
  ) => {
    let compiledOrganisations = compileOrganisationData(
      organisations,
      backendUser,
      userRole
    );
    setAllState(compiledOrganisations || []);
    setState(compiledOrganisations || []);
  };

  const findOrganisation = (title, search) => {
    for (let i = 0; i <= title?.length - search?.length; i++) {
      if (title.substring(i, i + search?.length) === search) {
        return true;
      }
    }
    return false;
  };

  const getOrganisations = (search, organisationData, setState) => {
    if (search?.length) {
      const filteredOrganisations = organisationData.filter((organisation) =>
        findOrganisation(
          organisation?.label?.toLowerCase(),
          search?.toLowerCase()
        )
      );
      setState(filteredOrganisations);
    } else {
      setState(organisationData);
    }
  };

  const getOrganisationById = async (
    value,
    organisationData,
    isAdminDropDown
  ) => {
    setFullScreenLoading(true);
    setIsLoading(true);
    const organisation = organisationData.find(
      (organisation) => organisation.value === value
    );
    setCurrentOrganisation({
      variables: {
        user_id: personalDetails?.id,
        current_organisation_id: organisation?.value,
        current_role_id: organisation?.role,
      },
      onCompleted: () => {
        if (isAdminDropDown) {
          window.location.href = `${
            process.env.REACT_APP_AVCWISE_URL
          }/organisations/${parameterizedDetail(
            organisation?.value,
            organisation?.label
          )}/dashboard`;
          return;
        }

        if (organisation?.isNHS) {
          window.location.href = `${process.env.REACT_APP_AVCWISE_URL}/schemes/my_salary_sacrifice_avc`;
          return;
        }

        window.location.href = `${window.location.origin}/home`;
      },
      onError: (error) => {
        setIsLoading(false);
        setFullScreenLoading(false);
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };

  useEffect(() => {
    if (
      !isEmpty(personalDetails) &&
      personalDetails.role_id &&
      !personalDetails.is_employer_and_employee &&
      !personalDetails.backend_user
    ) {
      navigate("/home");
    } else if (
      !isEmpty(personalDetails) &&
      (personalDetails.is_employer_and_employee || personalDetails.backend_user)
    ) {
      fetchUserOrganisationsDetails();
    }
  }, [personalDetails]);

  const initialValues = {
    organisations: "",
    adminOrganisations: "",
  };

  return (
    <Box className="application-page-container mb-100">
      <SharedHeader heading="Change Organisation" displayLogo={false} />
      <Container className="mt-150">
        <MyPlanHeader heading="Select portal to access organisations" />
        <Formik initialValues={initialValues} onSubmit={() => {}}>
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                className="organisation-selector-box"
              >
                <Stack
                  direction={{ md: "row", sm: "column" }}
                  justifyContent={
                    personalDetails.backend_user && !isLoading
                      ? "space-between"
                      : "center"
                  }
                  spacing={2}
                  className="organisation-box"
                >
                  {isLoading ? (
                    <Box className="mt-30">
                      <Loader />
                    </Box>
                  ) : (
                    <>
                      <Stack
                        direction="column"
                        className="organisation-drop-down"
                      >
                        <Typography className="organisation-page-heading">
                          <strong>Change your organisation</strong>
                        </Typography>
                        <AutocompleteField
                          name="organisations"
                          label="Organisations"
                          icon={<></>}
                          disablePortal={false}
                          trackingDetails={TRACKING_CHANGE_PORTAL_OPTIONS}
                          options={[...organisations].sort((a, b) =>
                            sortArray(a, b, "label")
                          )}
                          getOptions={(search) =>
                            getOrganisations(
                              search,
                              allOrganisations,
                              setOrganisations
                            )
                          }
                          getOptionById={(value) =>
                            getOrganisationById(value, allOrganisations, false)
                          }
                        />
                        <Typography className="mt-18 organisation-page-heading">
                          <strong>Change your admin organisation</strong>
                        </Typography>
                        <AutocompleteField
                          name="adminOrganisations"
                          label="Organisations"
                          icon={<></>}
                          disablePortal={false}
                          trackingDetails={TRACKING_CHANGE_PORTAL_OPTIONS}
                          options={[...adminOrganisations].sort((a, b) =>
                            sortArray(a, b, "label")
                          )}
                          getOptions={(search) =>
                            getOrganisations(
                              search,
                              allAdminOrganisations,
                              setAdminOrganisations
                            )
                          }
                          getOptionById={(value) =>
                            getOrganisationById(
                              value,
                              allAdminOrganisations,
                              true
                            )
                          }
                        />
                      </Stack>
                      {personalDetails.backend_user ? (
                        <>
                          <Box
                            className={tablet ? "mt-18" : ""}
                            sx={{ border: "1px solid #E7E7E7" }}
                          ></Box>
                          <Stack direction="column">
                            <Typography className="organisation-page-heading">
                              <strong>
                                Click here to enter backend admin area
                              </strong>
                            </Typography>
                            <LoadingButton
                              buttonTitle="Enter backend admin area"
                              disabled={isLoading}
                              trackingDetails={TRACKING_CHANGE_PORTAL_OPTIONS}
                              handleClick={handelRedirectToBackendArea}
                              styleClass="btn contained-btn org-btn mt-18"
                            />
                          </Stack>
                        </>
                      ) : null}
                    </>
                  )}
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
      </Container>
    </Box>
  );
};

export default ChangePortalPage;

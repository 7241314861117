import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { Stack, TablePagination } from "@mui/material";
import LoadingButton from "../../../../generic-components/button";
import PropTypes from "prop-types";
import { TRACKING_EVENTS_PAGE_OPTIONS } from "../../../../../constants";
import { useMutation } from "@apollo/client";
import useStepper from "../../../../../hooks/useStepper";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import Loader from "../../../../generic-components/loader";
import BookingFastTag from "../../../../../assets/images/booking-fast-tag.svg";
import {
  bookNewEvent,
  cancelEventBooking,
  markUserPositiveAttendence,
} from "../../../../../graphql/mutations/events";
import BookingConfirmationDialog from "./BookingConfirmationDialog";
import { connect } from "react-redux";
import { useState } from "react";
import BookingCancellationDialog from "./BookingCancellationDialog";
import {
  isOneHourLeft,
  isTenMinutesLeft,
  redirectToURL,
} from "../../../../../helpers";
import moment from "moment";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#025496",
    border: "1px solid #025496",
    color: "#fff",
    fontWeight: "bold",
    cursor: "pointer",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "0.625rem",
    fontWeight: "bold",
  },
}));

const StyledTableRow = styled(TableRow)({
  backgroundColor: "transparent",
  border: "1px solid #E3E3E3",
});

const columns = [
  {
    id: "presentation_date",
    label: "Date",
    align: "center",
    width: "12%",
  },
  {
    id: "presentation_time",
    label: "Time",
    align: "center",
    width: "12%",
  },
  {
    id: "available_spaces",
    label: "Available spaces",
    align: "center",
    width: "23%",
  },
  {
    id: "duration",
    label: "Duration (minutes)",
    align: "center",
    width: "23%",
  },
];

const EventsTable = ({
  user,
  page,
  count,
  events,
  fetch,
  setFetch,
  setPage,
  isLoading,
  setIsLoading,
  rowsPerPage,
  organisation,
  setRowsPerPage,
}) => {
  const { setErrorToast, setSaveError } = useStepper();
  const [bookingConfirmation, setBookingConfirmation] = useState(false);
  const [cancelConfirmation, setCancelConfirmation] = useState(false);
  const [dialogData, setDialogData] = useState({});
  const [orderBy, setOrderBy] = useState("presentation_date");
  const [order, setOrder] = useState("asc");

  const handleSortChange = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedEvents = [...events].sort((a, b) => {
    if (orderBy === "presentation_date") {
      const dateA = moment(a[orderBy], "DD/MM/YYYY");
      const dateB = moment(b[orderBy], "DD/MM/YYYY");
      if (order === "asc") {
        return dateA.isAfter(dateB) ? 1 : -1;
      } else {
        return dateA.isBefore(dateB) ? 1 : -1;
      }
    } else {
      if (order === "asc") {
        return a[orderBy] > b[orderBy] ? 1 : -1;
      } else {
        return a[orderBy] < b[orderBy] ? 1 : -1;
      }
    }
  });

  const [cancelBooking, { loading: cancelLoading }] =
    useMutation(cancelEventBooking);

  const [bookEvent, { loading: bookLoading }] = useMutation(bookNewEvent);
  const [markUserAttendence, { loading: attendenceLoading }] = useMutation(
    markUserPositiveAttendence
  );

  const handlePageChange = (_, newPage) => {
    setPage(newPage + 1);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(+e?.target?.value);
    setPage(1);
  };

  const handleCloseBookingConfirmation = () => {
    setBookingConfirmation(false);
  };

  const handleCloseCancelConfirmation = () => {
    setCancelConfirmation(false);
  };

  const findBooking = (event) => {
    return event?.booked_user_presentations?.find(
      (event) => !event.cancelled_at
    );
  };

  const handleJoinNow = (event) => {
    const booking = findBooking(event);

    if (!booking) return;

    if (
      isTenMinutesLeft(
        event?.presentation_date,
        event?.presentation_time,
        event?.presentation_end_time
      ) &&
      !booking.attended
    ) {
      setIsLoading(true);
      markUserAttendence({
        variables: {
          user_id: user.id,
          presentation_id: event.id,
          organisation_id: organisation.id,
        },
        onCompleted: () => {
          setFetch(!fetch);
        },
        onError: (error) => {
          setIsLoading(false);
          setSaveError(error);
          setErrorToast(true);
        },
      });
    }

    redirectToURL(event.webinar_link, true);
  };

  const checkEventStatus = (event) => {
    if (
      Array.isArray(event?.booked_user_presentations) &&
      event?.booked_user_presentations?.length &&
      event?.booked_user_presentations?.find((entry) => !entry.cancelled_at)
    ) {
      return "Cancel booking";
    } else if (event?.number_of_places - event?.booked_places) {
      return "Book";
    } else {
      return "Fully booked";
    }
  };

  const handleBooking = async (event, values) => {
    if (organisation.id && event.id) {
      setIsLoading(true);
      setBookingConfirmation(false);
      bookEvent({
        variables: {
          five_years_to_retirement: values?.five_years_to_retirement || false,
          organisation_id: organisation.id,
          presentation_id: event.id,
        },
        onCompleted: () => {
          setFetch(!fetch);
        },
        onError: (error) => {
          setSaveError(error);
          setErrorToast(true);
          setIsLoading(false);
        },
      });
    } else {
      setBookingConfirmation(false);
      setSaveError({ type: "error" });
      setErrorToast(true);
    }
  };

  const handleBookingCancellation = async (event) => {
    const bookingId = event?.booked_user_presentations?.find(
      (entry) => !entry.cancelled_at
    ).id;
    if (bookingId) {
      setIsLoading(true);
      setCancelConfirmation(false);
      cancelBooking({
        variables: {
          booking_id: bookingId,
        },
        onCompleted: () => {
          setFetch(!fetch);
        },
        onError: (error) => {
          setIsLoading(false);
          setSaveError(error);
          setErrorToast(true);
        },
      });
    } else {
      setCancelConfirmation(false);
      setSaveError({ type: "error" });
      setErrorToast(true);
    }
  };

  const handleEventsBooking = async (event) => {
    setDialogData(event);
    if (
      Array.isArray(event?.booked_user_presentations) &&
      event?.booked_user_presentations?.length &&
      event?.booked_user_presentations?.find((entry) => !entry.cancelled_at)
    ) {
      setCancelConfirmation(true);
    } else {
      setBookingConfirmation(true);
    }
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          width: "100%",
          borderRadius: "0",
          boxShadow: "none",
        }}
        className="mt-30"
      >
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                return (
                  <StyledTableCell
                    key={column.id}
                    width={column.width}
                    align={column.align}
                    sortDirection={orderBy === column.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={orderBy === column.id ? order : "asc"}
                      onClick={() => handleSortChange(column.id)}
                      sx={{
                        "&.MuiTableSortLabel-root .MuiTableSortLabel-icon": {
                          color: "white !important",
                        },
                        "&.MuiTableSortLabel-root": {
                          color: "white !important",
                        },
                        "&.Mui-active": {
                          color: "white !important",
                        },
                      }}
                    >
                      {column.label}
                    </TableSortLabel>
                  </StyledTableCell>
                );
              })}
              <StyledTableCell width="30%" align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <StyledTableRow>
                <StyledTableCell colSpan={12} align="center">
                  <Loader size={35} />
                </StyledTableCell>
              </StyledTableRow>
            ) : sortedEvents?.length ? (
              sortedEvents.map((event, index) => (
                <StyledTableRow key={index}>
                  {columns.map((column) => {
                    return (
                      <StyledTableCell
                        key={column.id}
                        width={column.width}
                        align="center"
                      >
                        {event[column.id]}
                      </StyledTableCell>
                    );
                  })}
                  <StyledTableCell align="center">
                    <Stack direction="row" spacing={3}>
                      <LoadingButton
                        buttonTitle={checkEventStatus(event)}
                        trackingDetails={TRACKING_EVENTS_PAGE_OPTIONS}
                        handleClick={() => handleEventsBooking(event)}
                        disabled={
                          checkEventStatus(event) === "Fully booked" ||
                          bookLoading ||
                          cancelLoading ||
                          attendenceLoading ||
                          isLoading
                        }
                        styleClass={`booking_btn ${
                          checkEventStatus(event) === "Fully booked" ||
                          checkEventStatus(event) === "Cancel booking"
                            ? "booked"
                            : "not-booked"
                        }`}
                      />
                      {(checkEventStatus(event) === "Cancel booking" &&
                        event?.presentation_type === "Webinar" &&
                        isOneHourLeft(
                          event?.presentation_date,
                          event?.presentation_time,
                          event?.presentation_end_time
                        )) ||
                      (checkEventStatus(event) === "Book" &&
                        event?.number_of_places - event?.booked_places < 30) ? (
                        <LoadingButton
                          buttonTitle={
                            checkEventStatus(event) === "Book"
                              ? "Booking fast"
                              : "Join now"
                          }
                          icon={
                            checkEventStatus(event) === "Cancel booking" ? (
                              <OndemandVideoIcon
                                sx={{ padding: "2px", margin: "4px" }}
                              />
                            ) : (
                              <img
                                src={BookingFastTag}
                                style={{
                                  margin: "5px",
                                  height: "12px",
                                }}
                                alt="book-fast-tag"
                              />
                            )
                          }
                          disabled={attendenceLoading || isLoading}
                          trackingDetails={TRACKING_EVENTS_PAGE_OPTIONS}
                          handleClick={() => {
                            checkEventStatus(event) === "Cancel booking"
                              ? handleJoinNow(event)
                              : handleEventsBooking(event);
                          }}
                          styleClass={`booking_btn ${
                            checkEventStatus(event) === "Cancel booking"
                              ? "join-now"
                              : "book-fast"
                          }`}
                        />
                      ) : null}
                    </Stack>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell colSpan={12} align="center">
                  No records found.
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          page={page - 1}
          rowsPerPageOptions={[5, 10, 15]}
          count={count}
          labelRowsPerPage="Rows per page"
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </TableContainer>
      <BookingConfirmationDialog
        open={bookingConfirmation}
        event={dialogData}
        onClose={handleCloseBookingConfirmation}
        handleBooking={handleBooking}
        loading={bookLoading || isLoading}
      />
      <BookingCancellationDialog
        open={cancelConfirmation}
        event={dialogData}
        onClose={handleCloseCancelConfirmation}
        isLoading={cancelLoading || isLoading}
        handleBookingCancellation={handleBookingCancellation}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    organisation: state?.organisation?.organisation,
    user: state?.user?.user,
  };
};

EventsTable.propTypes = {
  events: PropTypes.array,
  count: PropTypes.number,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  fetch: PropTypes.bool,
  isLoading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  organisation: PropTypes.object,
  user: PropTypes.object,
  setPage: PropTypes.func,
  setFetch: PropTypes.func,
  setRowsPerPage: PropTypes.func,
};

export default connect(mapStateToProps)(EventsTable);

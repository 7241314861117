import formattedDate from "./date";
import * as Sentry from "@sentry/react";
import { organisationWarningMessages, UserEventsDetails } from "../constants";
import { removeCookie } from "../utils/cookies";
import moment from "moment";
import { mainSchemeTypes, pensionType } from "../constants/multiForm";

const checkFieldError = (touched, errors, fieldName, index, subField) => {
  if (index || index === 0) {
    if (
      !(
        touched[fieldName] &&
        touched[fieldName][index] &&
        touched[fieldName][index][subField] &&
        errors[fieldName] &&
        errors[fieldName][index] &&
        errors[fieldName][index][subField]
      )
    ) {
      return false;
    } else {
      return (
        touched[fieldName][index][subField] &&
        errors[fieldName][index][subField]
      );
    }
  } else {
    if (!(touched[fieldName] && errors[fieldName])) {
      return false;
    } else return touched[fieldName] && errors[fieldName];
  }
};

const convertToNumber = (inputString) => {
  if (!inputString) {
    return "";
  }

  return parseInt(inputString.replace(/[^0-9]/g, ""));
};

const getPayPeriodName = (period) => {
  switch (period) {
    case 0:
      return "Weekly";

    case 1:
      return "Fortnightly";

    case 2:
      return "4-Weekly";

    case 3:
      return "Monthly";
  }
};

const poundConverter = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
});

const transformTempInvestmentChoices = (tempOptions) => {
  if (!(Array.isArray(tempOptions) && tempOptions.length)) {
    return [];
  }

  return tempOptions.map((option) => ({
    value: option.investment_option.id,
    name: option.investment_option.id,
    percentage: option.specialist_value,
  }));
};

const getFieldErrorNames = (formikErrors) => {
  const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) return;

      let nextKey = prefix ? `${prefix}.${key}` : key;

      if (Number(key) && typeof Number(key) === "number") {
        nextKey = prefix ? `${prefix}[${key}]` : key;
      }

      if (typeof value === "object") {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};

const formatCurrency = (amount) => {
  if (!amount) {
    amount = 0;
  }

  return poundConverter.format(Number(amount).toFixed(2));
};

const fixedAmountToDecimal = (amount, decimal = 2) => {
  return Number(amount || 0).toFixed(decimal);
};

const decodeURLToString = (url) => {
  if (!url) {
    return "";
  }

  try {
    return decodeURI(url);
  } catch (error) {
    Sentry.captureException(error);
    console.log("Error while decoding url", url);
    return url;
  }
};

const truncateString = (inputString, maxLength) => {
  if (inputString.length <= maxLength) {
    return inputString;
  } else {
    return inputString.slice(0, maxLength) + "...";
  }
};

const removeAllCookies = () => {
  var cookies = document.cookie.split("; ");
  for (var c = 0; c < cookies.length; c++) {
    var d = window.location.hostname.split(".");
    while (d.length > 0) {
      var cookieBase =
        encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
        "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
        d.join(".") +
        " ;path=";
      var p = location.pathname.split("/");
      document.cookie = cookieBase + "/";
      while (p.length > 0) {
        document.cookie = cookieBase + p.join("/");
        p.pop();
      }
      d.shift();
    }
  }
};

const removeCookies = () => {
  removeAllCookies();
  removeCookie("user_session");
  removeCookie("organisation_id");
  removeCookie("scheme_id");
};

function serializeError(value) {
  if (value instanceof Error) {
    return {
      ...value,
      name: value.name,
      message: value.message,
      stack: value.stack,
    };
  }

  return value;
}

const isDecimalNumber = (number) => {
  if (!number) {
    return true;
  }

  if (!Number(number)) {
    return true;
  }

  return number - Math.floor(number) !== 0;
};

const sortArray = (a, b, keyName) => {
  const key = keyName;
  if (a[key].toLowerCase() < b[key].toLowerCase()) {
    return -1;
  }
  if (a[key].toLowerCase() > b[key].toLowerCase()) {
    return 1;
  }
  return 0;
};

const findItemBySelectedValue = (name, value, menucontainer) => {
  const foundItemByName = UserEventsDetails()?.find(
    (item) => item.name === name
  );
  const foundItemByValue = menucontainer?.find((item) => item.value === value);

  const fieldName = foundItemByName?.field_name || name;
  const fieldValue = foundItemByValue?.name || name;

  return {
    fieldName,
    fieldValue,
  };
};

const findScheme = (schemes) => {
  const scheme = schemes?.find(
    (scheme) => scheme?.belongs_to_pension_type?.name === pensionType.NHS
  );

  return !!scheme;
};

const findBackendUserRoleId = (roles, userRole) => {
  const roleId = roles?.find((role) => role?.name === userRole)?.id;
  return roleId;
};

const compileOrganisationData = (
  organisations,
  backendUser = false,
  userRole
) => {
  let compiledOrganisations = organisations?.map((organisation) => {
    return {
      label: backendUser
        ? organisation?.name
        : organisation?.belongs_to_role?.belongs_to_organisation?.name,
      value: backendUser
        ? organisation?.id
        : organisation?.belongs_to_role?.belongs_to_organisation?.id,
      role: backendUser
        ? findBackendUserRoleId(organisation?.has_many_roles, userRole)
        : organisation?.belongs_to_role?.id,
      isNHS: backendUser
        ? findScheme(organisation?.belongs_to_scheme)
        : findScheme(
            organisation?.belongs_to_role?.belongs_to_organisation
              ?.belongs_to_scheme
          ),
    };
  });

  const filteredOrganisations = compiledOrganisations.filter(
    (organisation) => organisation.role
  );

  return filteredOrganisations;
};

const parameterizedDetail = (id, name) => {
  const formattedName = name?.trim()?.toLowerCase()?.replace(/\s+/g, "-");
  const result = `${id}-${formattedName}`;
  return result;
};

const redirectToURL = (url, newTab) => {
  const tab = newTab ? "_blank" : "_self";
  if (url) {
    window.open(url, tab);
  }
};

const transformEventDateAndTime = (eventDate, eventTime, eventEndTime) => {
  const targetMoment = moment(
    `${eventDate} ${eventTime}`,
    "DD/MM/YYYY hh:mm a"
  );
  const targetEndTimeMoment = moment(
    `${eventDate} ${eventEndTime}`,
    "DD/MM/YYYY hh:mm a"
  );

  const currentTime = moment();

  return { targetMoment, targetEndTimeMoment, currentTime };
};

const isOneHourLeft = (eventDate, eventTime, eventEndTime) => {
  if (!eventDate || !eventTime) return false;
  const { targetMoment, targetEndTimeMoment, currentTime } =
    transformEventDateAndTime(eventDate, eventTime, eventEndTime);

  const oneHourBeforePresentation = targetMoment.subtract(1, "hours");

  return currentTime.isBetween(oneHourBeforePresentation, targetEndTimeMoment);
};

const isTenMinutesLeft = (eventDate, eventTime, eventEndTime) => {
  if (!eventDate || !eventTime) return false;
  const { targetMoment, targetEndTimeMoment, currentTime } =
    transformEventDateAndTime(eventDate, eventTime, eventEndTime);

  const tenMinutesBeforePresentation = targetMoment.subtract(10, "minutes");

  return currentTime.isBetween(
    tenMinutesBeforePresentation,
    targetEndTimeMoment
  );
};

const calculateIsTimeBetween = (startTime, endTime) => {
  if (!startTime || !endTime) {
    return;
  }
  const userTimeZoneOffset = new Date().getTimezoneOffset();
  const currentTime = moment();
  const liveStartTime = moment(startTime).utcOffset(userTimeZoneOffset);
  const liveEndTime = moment(endTime).utcOffset(userTimeZoneOffset);
  return currentTime.isBetween(liveStartTime, liveEndTime);
};

const findPageBanner = (banners, pageName) => {
  const eventPageBanner = banners.find(
    (banner) =>
      banner?.appear_on_employee_pages &&
      banner?.employee_pages?.length &&
      banner?.employee_pages?.find(
        (page) => page?.page_name?.toLowerCase() === pageName?.toLowerCase()
      ) &&
      calculateIsTimeBetween(banner?.live_start_date, banner?.live_end_date)
  );
  return eventPageBanner;
};

const findActiveSchemeDetails = (schemes, pensionType, schemeTypeName) => {
  return schemes.find(
    (scheme) =>
      scheme.pension_type?.toLowerCase() === pensionType?.toLowerCase() &&
      scheme.scheme_type_name?.toLowerCase() === schemeTypeName?.toLowerCase()
  );
};

const checkOrganisationDetailsValidation = (
  payPeriods,
  pensionFunds,
  providers
) => {
  if (!payPeriods) {
    return organisationWarningMessages.PAY_PERIOD;
  } else if (!pensionFunds) {
    return organisationWarningMessages.PENSION_FUND;
  } else if (!providers) {
    return organisationWarningMessages.PROVIDER;
  }
};

const checkActiveSchemes = (personalDetails) => {
  const activeSchemes = personalDetails?.active_schemes || [];
  return activeSchemes?.find((scheme) =>
    mainSchemeTypes?.includes(scheme?.scheme_type_name)
  );
};

export {
  checkFieldError,
  removeAllCookies,
  serializeError,
  convertToNumber,
  getPayPeriodName,
  decodeURLToString,
  formatCurrency,
  getFieldErrorNames,
  formattedDate,
  fixedAmountToDecimal,
  isDecimalNumber,
  transformTempInvestmentChoices,
  checkOrganisationDetailsValidation,
  sortArray,
  truncateString,
  findItemBySelectedValue,
  parameterizedDetail,
  compileOrganisationData,
  removeCookies,
  isOneHourLeft,
  findPageBanner,
  calculateIsTimeBetween,
  redirectToURL,
  isTenMinutesLeft,
  findActiveSchemeDetails,
  checkActiveSchemes,
};

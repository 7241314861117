import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { StepperProvider } from "./hooks/useStepper";
import { PlanProvider } from "./hooks/usePlan";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  ApolloLink,
} from "@apollo/client";
import { Provider } from "react-redux";
import store from "./store";
import isEmpty from "lodash.isempty";
import { onError } from "apollo-link-error";
import FallbackComponent from "./components/FallbackComponent";
import { BrowserRouter } from "react-router-dom";
import { customFetch } from "./middleware";
import { getAllCookies, getCookie } from "./utils/cookies";
import * as Sentry from "@sentry/react";

const SIGN_IN_URL = process.env.REACT_APP_AVCWISE_URL + "/users/sign_in";
const IS_LOCAL_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT !== "local";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_HASURA_API_URL,
  fetch: customFetch,
});

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_URL,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: process.env.REACT_APP_SENTRY_SAMPLE_RATE || 0.2, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: process.env.REACT_APP_ENVIRONMENT,
  ignoreErrors: ["^(Token is (expired|invalid))$", "ApolloError"],
  release: process.env.REACT_APP_SENTRY_RELEASE_VERSION,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: `Bearer ${getCookie("user_session")}`,
      "x-hasura-admin-secret": process.env.REACT_APP_HASURA_SECRET_KEY,
    },
  });

  return forward(operation);
});

if (
  (isEmpty(getAllCookies()) || !getCookie("user_session")) &&
  IS_LOCAL_ENVIRONMENT
) {
  window.location.href = SIGN_IN_URL;
}

const logoutLink = onError((operation) => {
  const { graphQLErrors, operation: graphqlOperation } = operation;

  graphQLErrors?.forEach((graphQLError) => {
    if (
      graphQLError?.extensions?.code === "unexpected" &&
      (graphQLError.message.includes("Token has expired") ||
        graphQLError.message.includes("Token is invalid"))
    ) {
      return;
    }

    const userDetail = store.getState().user?.user || {};
    const scheme = store.getState().scheme?.scheme || {};
    const formDetails = store.getState().form?.form || {};

    const customizedTags = {
      temp_plan_id: formDetails?.id,
      step_number: formDetails?.step_number,
      organisation_id: userDetail.organisation_id,
      scheme_id: scheme.scheme_id,
      user_id: userDetail.id,
      user_email: userDetail.email,
    };

    const extraDetails = {
      payload: graphqlOperation.query.loc.source,
      variables: graphqlOperation?.variables,
      user_session: getCookie("user_session"),
      refresh_token: getCookie("refresh_token"),
      ...customizedTags,
    };

    try {
      const customMessage =
        graphqlOperation?.operationName?.toUpperCase() ||
        "Graphql Request Failed";

      const customError = new Error(customMessage);

      customError.name = customMessage;
      customError.stack = JSON.stringify(graphQLError, null, 2);
      customError.message = JSON.stringify(graphQLError, null, 2);

      throw customError;
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setExtra("error", graphQLError);
        scope.setLevel("error");
        for (const key in customizedTags) {
          const value = customizedTags[key];
          Sentry.setTag(`${key}`, value);
        }
        Sentry.captureException(error, {
          extra: extraDetails,
        });
      });
    }
  });
});

const client = new ApolloClient({
  link: authMiddleware.concat(logoutLink.concat(httpLink)),
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Sentry.ErrorBoundary fallback={() => <FallbackComponent />}>
            <StepperProvider>
              <PlanProvider>
                <App />
              </PlanProvider>
            </StepperProvider>
          </Sentry.ErrorBoundary>
        </BrowserRouter>
      </ApolloProvider>
    </Provider>
  </React.StrictMode>
);

import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { TRACKING_EVENTS_PAGE_OPTIONS } from "../../../../../constants";
import LoadingButton from "../../../../generic-components/button";
import { truncateString } from "../../../../../helpers";

const EventStamp = ({ targetAudience, title }) => {
  const navigate = useNavigate();

  const onClickRedirection = (url) => {
    navigate(url);
  };

  return (
    <Box className="stamp-box mt-18 mb-18">
      <Box className="stamp-header">
        <Typography className="bold-heading">
          {truncateString(title, 38)}
        </Typography>
        <Typography className="standard-heading mt-5">
          Target audience: {truncateString(targetAudience, 10)}
        </Typography>
      </Box>
      <Box className="stamp-button-box">
        <LoadingButton
          buttonTitle="Check availability"
          trackingDetails={TRACKING_EVENTS_PAGE_OPTIONS}
          handleClick={() => {
            onClickRedirection(`/events/${encodeURIComponent(title)}`);
          }}
          styleClass="btn event-availablilty-btn"
        />
      </Box>
    </Box>
  );
};

EventStamp.propTypes = {
  title: PropTypes.string,
  targetAudience: PropTypes.string,
};

export default EventStamp;

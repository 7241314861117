import { Box } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const GlobalHeaderLinksection = () => {
  return (
    <>
      <Box className="header-links-layout">
        <Box className="header-links-container">
          <Link to="/" className="header-links">
            About AVC Wise
          </Link>
          <Link to="/" className="header-links">
            Knowledge Hub
          </Link>
          <Link to="/" className="header-links">
            FAQ
          </Link>
          <Link to="/" className="header-links">
            Case Study
          </Link>
          <Link to="/" className="header-links">
            Events
          </Link>
          <Link to="/" className="header-links">
            Contact
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default GlobalHeaderLinksection;

import { Box, Dialog, DialogTitle, Stack, Typography } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ScheduleIcon from "@mui/icons-material/Schedule";
import PropTypes from "prop-types";
import moment from "moment";
import FormikCheckbox from "../../../../generic-components/formik-checkbox";
import { TRACKING_EVENTS_PAGE_OPTIONS } from "../../../../../constants";
import { Form, Formik } from "formik";
import LoadingButton from "../../../../generic-components/button";

const BookingConfirmationDialog = ({
  open,
  onClose,
  loading,
  event,
  handleBooking,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="event-confirmation-dialog"
    >
      <DialogTitle className="dialog-header">
        <Typography className="dialog-heading">Booking Confirmation</Typography>
      </DialogTitle>
      <Box className="detail-heading-box">
        <Typography>
          You are about to book on the <strong>{event?.title}</strong> webinar
          on <strong>{event?.presentation_date}</strong> at{" "}
          <strong>
            {event?.presentation_time
              ? moment(event?.presentation_time, "hh:mm a").format("hh:mmA")
              : "_"}
          </strong>
        </Typography>
        <Typography>
          The webinar's target audience is for{" "}
          <strong>{event?.target_audience}</strong>
        </Typography>
      </Box>
      <Box className="detail-box">
        <Box className="time-detail-box">
          <Box className="time-header">
            <Typography className="time-heading">{event?.title}</Typography>
          </Box>
          <Stack
            className="p-lr-30 mt-12"
            direction="row"
            justifyContent="space-between"
          >
            <Stack
              direction={{ sm: "row", md: "row", xs: "column" }}
              spacing={1}
            >
              <CalendarMonthIcon />
              <Typography>{event?.presentation_date}</Typography>
            </Stack>
            <Stack
              direction={{ sm: "row", md: "row", xs: "column" }}
              spacing={1}
            >
              <ScheduleIcon color="disabled" />
              <Typography className="disabled-text">
                {event?.presentation_time
                  ? moment(event?.presentation_time, "hh:mm a").format("hh:mmA")
                  : "_"}
              </Typography>
            </Stack>
          </Stack>
        </Box>
        <Box className="mt-18">
          <Typography className="confirm-heading mb-18">
            If this is the right webinar for you. please click the 'Confirm
            Booking' button below and a confirmation email will be sent to you.
          </Typography>
          <Formik
            initialValues={{ five_years_to_retirement: false }}
            onSubmit={(values) => {
              handleBooking(event, values);
            }}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <FormikCheckbox
                  trackingDetails={TRACKING_EVENTS_PAGE_OPTIONS}
                  name="five_years_to_retirement"
                  label={`It helps us to understand if you plan to retire within 5 years so we can ensure our webinar hosts knows what specific information to cover. Please tick this box if you have less than 5 years to retirement.`}
                  styleClass="small-text"
                />
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  className="mt-18 mb-18"
                >
                  <LoadingButton
                    buttonTitle="Confirm Booking"
                    loading={loading}
                    disabled={loading}
                    trackingDetails={TRACKING_EVENTS_PAGE_OPTIONS}
                    styleClass="btn eventbtn"
                    type="submit"
                  />
                </Stack>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Dialog>
  );
};

BookingConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  event: PropTypes.object,
  onClose: PropTypes.func,
  handleBooking: PropTypes.func,
};

export default BookingConfirmationDialog;

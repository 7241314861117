import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ErrorIcon from "@mui/icons-material/Error";
import NavigationLinks from "../../../generic-components/navigation-links";
import { TRACKING_HEADER_OPTIONS } from "../../../../constants";
import IconButton from "../../../generic-components/icon-btn";
import ReactLinks from "../../../generic-components/navigation-links/ReactLinks";
import { useNavigate } from "react-router-dom";
import { isNHSApplication } from "../../../../reducers/multiFormReducer";
import usePlan from "../../../../hooks/usePlan";

const HeaderLinksection = ({
  scheme,
  user,
  isNHS,
  organisation,
  sharedTitle,
}) => {
  const { isStandardAVCPlan, isOptOutPendingPlan } = usePlan();
  const [contactUrl, setContactUrl] = useState(
    `${process.env.REACT_APP_AVCWISE_URL}/contact_us`
  );
  const navigate = useNavigate();

  const handleNavigation = (url) => {
    if (url) {
      navigate(url);
    }
  };

  const handleRedirectionURL = (defaultURL) => {
    const baseURL = `${process.env.REACT_APP_AVCWISE_URL}/users/${user.id}/plans`;
    if (isNHS) {
      return defaultURL === "/home"
        ? `${process.env.REACT_APP_AVCWISE_URL}/schemes/my_salary_sacrifice_avc`
        : `${baseURL}?scheme_id=${scheme?.scheme_id}`;
    } else if (isOptOutPendingPlan || isStandardAVCPlan) {
      return isOptOutPendingPlan
        ? `${baseURL}/${isOptOutPendingPlan.id}/edit_my_avcwise`
        : `${baseURL}/${isStandardAVCPlan.id}/show_my_avcwise`;
    } else {
      return defaultURL;
    }
  };

  useEffect(() => {
    if (user?.latest_plan) {
      setContactUrl(
        `${process.env.REACT_APP_AVCWISE_URL}/users/${user.id}/plans/${user?.latest_plan}/show_contact_form`
      );
    }
  }, [user]);

  return (
    <Box className="header-links-layout">
      <Box className="header-links-container">
        <NavigationLinks
          styleClass="header-links nav-links"
          link={handleRedirectionURL("/home")}
          name="Home"
          navigateLink={!(isNHS || isOptOutPendingPlan || isStandardAVCPlan)}
          trackingDetails={TRACKING_HEADER_OPTIONS}
        />
        <NavigationLinks
          styleClass="header-links nav-links"
          link={`${process.env.REACT_APP_AVCWISE_URL}/users/${user.id}/edit`}
          name="My Profile"
          trackingDetails={TRACKING_HEADER_OPTIONS}
        />
        <NavigationLinks
          styleClass="header-links nav-links"
          link={handleRedirectionURL("/manage_my_shared_cost_avc")}
          navigateLink={!(isNHS || isOptOutPendingPlan || isStandardAVCPlan)}
          name={`Manage my ${sharedTitle}`}
          trackingDetails={TRACKING_HEADER_OPTIONS}
        />
        {user?.investment_guidance && (
          <NavigationLinks
            styleClass="header-links nav-links"
            link={`${process.env.REACT_APP_AVCWISE_URL}/schemes/${scheme.scheme_id}/avc_investment_guidance`}
            name="My Investment Guidance"
            trackingDetails={TRACKING_HEADER_OPTIONS}
          />
        )}{" "}
        {organisation?.ningi_journey && (
          <ReactLinks
            styleClass="header-links nav-links"
            link={`/my_investment_advice`}
            name="My Investment Advice"
            trackingDetails={TRACKING_HEADER_OPTIONS}
          />
        )}{" "}
        <IconButton
          styleClass="header-links nav-links"
          buttonTitle="Events"
          trackingDetails={TRACKING_HEADER_OPTIONS}
          handleClick={() => {
            handleNavigation("/events");
          }}
          icon={
            user?.future_presentations && (
              <ErrorIcon className="error-outline" />
            )
          }
        />
        {user?.backend_user && (
          <NavigationLinks
            styleClass="header-links nav-links"
            link="/change_portal"
            name="Change Organisation"
            navigateLink={true}
            trackingDetails={TRACKING_HEADER_OPTIONS}
          />
        )}
        <NavigationLinks
          styleClass="header-links nav-links"
          link={contactUrl}
          name="Contact"
          trackingDetails={TRACKING_HEADER_OPTIONS}
        />
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
    scheme: state.scheme.scheme,
    organisation: state.organisation.organisation,
    sharedTitle: state.multiForm.sharedTitle,
    isNHS: isNHSApplication(state),
  };
};

HeaderLinksection.propTypes = {
  user: PropTypes.object,
  organisation: PropTypes.object,
  scheme: PropTypes.object,
  sharedTitle: PropTypes.string,
  isNHS: PropTypes.bool,
};

export default connect(mapStateToProps)(HeaderLinksection);

import { Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { formatCurrency } from "../../../../../helpers";
import { calculateMaximumContribution } from "../../../../../utils";

const MaximumContribution = () => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values.income && values.hours_per_week && values.age) {
      const avcAmount = calculateMaximumContribution(values);

      setFieldValue("avcAmount", avcAmount);
    } else {
      setFieldValue("avcAmount", 0);
    }
  }, [
    values.income,
    values.hours_per_week,
    values.age,
    values.salarySacrifice,
    values.PTCheckbox,
  ]);

  return (
    <>
      <Typography className="sub-heading mt-18">
        Maximum monthly amount you can contribute
      </Typography>
      <Typography className="stepper-content mt-12">
        To maintain your salary above the national living wage
      </Typography>
      <Typography className="amount mt-12">
        {formatCurrency(values.avcAmount || "0.00")}
      </Typography>
    </>
  );
};

export default MaximumContribution;

import { gql } from "@apollo/client";

export const saveTempPlan = gql`
  mutation saveTempPlan(
    $id: Int!
    $step_number: Int
    $employee_number: String
    $referral_code: String
    $title: String
    $delivery_address1: String
    $delivery_address2: String
    $delivery_county: String
    $date_of_birth: date
    $email: String
    $first_name: String
    $last_name: String
    $mobile_number: String
    $ni_number: String
    $delivery_postcode: String
    $delivery_town: String
    $telephone_number: String
    $total_avc_amount_requested: float8
    $annual_salary: Int
    $hours_per_week: float8
    $avc_interval: String
    $additional_avc_amount: Int
    $flexibly_accessed_any_pensions: Boolean
    $lifestyling_option: Boolean
    $pension_fund_id: Int
    $max_contribution_amount: float8
    $previous_amount_added: float8
    $provider_id: Int
    $retirement_age: Int
    $pension_age: String
    $choosen_relevant_investment_option: Boolean
    $having_previous_employer_plan: Boolean
    $combining_with_this_employer: Boolean
    $keep_separate_with_employer: Boolean
    $triggered_the_mpaa: Boolean
    $mpaa_date: date
    $flexibly_accessed_date: date
    $terms_acknowledged: Boolean
    $signatures: String
    $prudential_declaration: Boolean
    $nhs_pension_scheme_benefits: Boolean
    $investment_advice: Boolean
    $creation_reason_id: Int
    $creation_reason_other: String
    $updated_at: timestamp
  ) {
    update_temp_plans(
      _set: {
        title: $title
        step_number: $step_number
        employee_number: $employee_number
        referral_code: $referral_code
        mobile_number: $mobile_number
        telephone_number: $telephone_number
        date_of_birth: $date_of_birth
        delivery_address1: $delivery_address1
        delivery_address2: $delivery_address2
        delivery_county: $delivery_county
        delivery_postcode: $delivery_postcode
        delivery_town: $delivery_town
        email: $email
        ni_number: $ni_number
        last_name: $last_name
        first_name: $first_name
        max_contribution_amount: $max_contribution_amount
        previous_amount_added: $previous_amount_added
        total_avc_amount_requested: $total_avc_amount_requested
        annual_salary: $annual_salary
        avc_interval: $avc_interval
        hours_per_week: $hours_per_week
        additional_avc_amount: $additional_avc_amount
        flexibly_accessed_any_pensions: $flexibly_accessed_any_pensions
        lifestyling_option: $lifestyling_option
        pension_fund_id: $pension_fund_id
        provider_id: $provider_id
        retirement_age: $retirement_age
        pension_age: $pension_age
        choosen_relevant_investment_option: $choosen_relevant_investment_option
        having_previous_employer_plan: $having_previous_employer_plan
        combining_with_this_employer: $combining_with_this_employer
        keep_separate_with_employer: $keep_separate_with_employer
        triggered_the_mpaa: $triggered_the_mpaa
        mpaa_date: $mpaa_date
        flexibly_accessed_date: $flexibly_accessed_date
        terms_acknowledged: $terms_acknowledged
        signatures: $signatures
        prudential_declaration: $prudential_declaration
        nhs_pension_scheme_benefits: $nhs_pension_scheme_benefits
        investment_advice: $investment_advice
        creation_reason_id: $creation_reason_id
        creation_reason_other: $creation_reason_other
      }
      where: { id: { _eq: $id } }
    ) {
      returning {
        id
        organisation_id
        additional_avc_amount
        first_name
        delivery_address1
        title
        employee_number
        delivery_address1
        delivery_address2
        delivery_county
        date_of_birth
        email
        hours_per_week
        first_name
        last_name
        mobile_number
        ni_number
        referral_code
        delivery_postcode
        delivery_town
        telephone_number
        total_avc_amount_requested
        annual_salary
        avc_interval
        max_contribution_amount
        investment_advice
        incomplete_journey_consent
        signatures
        flexibly_accessed_any_pensions
        lifestyling_option
        pension_fund_id
        provider_id
        retirement_age
        pension_age
        terms_acknowledged
        previous_amount_added
        contribution_amount_updated
        signatures
        prudential_declaration
        nhs_pension_scheme_benefits
        step_number
        show_ningi_journey_consent
        choosen_relevant_investment_option
        having_previous_employer_plan
        combining_with_this_employer
        keep_separate_with_employer
        triggered_the_mpaa
        mpaa_date
        flexibly_accessed_date
        creation_reason_id
        creation_reason_other
      }
    }
  }
`;

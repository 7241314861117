import { Box } from "@mui/material";
import PropTypes from "prop-types";

const Banner = ({ portalBanner }) => {
  const handleClickUrl = () => {
    if (portalBanner?.banner_clickthrough_url) {
      window.open(portalBanner.banner_clickthrough_url, "_blank");
    }
  };

  return (
    <Box className="portal-banner">
      <Box
        className="banner"
        component="img"
        src={portalBanner?.artwork}
        alt="banner"
        onClick={handleClickUrl}
      ></Box>
    </Box>
  );
};

Banner.propTypes = {
  portalBanner: PropTypes.object,
};

export default Banner;

import { useLazyQuery } from "@apollo/client";
import { Box, Dialog, DialogTitle, Stack, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import moment from "moment";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useState } from "react";
import { TRACKING_EVENTS_PAGE_OPTIONS } from "../../../../../constants";
import { getDistinctEvents } from "../../../../../graphql/queries/events";
import { eventsFilterValidationSchema } from "../../../../../schema";
import LoadingButton from "../../../../generic-components/button";
import DateInput from "../../../../generic-components/date-input";
import SearchIcon from "@mui/icons-material/Search";
import EventFilterButton from "./EventFilterButton";
import useStepper from "../../../../../hooks/useStepper";
import AutocompleteField from "../../../../generic-components/autocomplete-field";

const EventFilterDialog = ({
  open,
  onClose,
  filter,
  setFilter,
  setPage,
  organisation,
  setNoRecordsMessege,
}) => {
  let firstDayOfWeek = moment().clone().weekday(1).format("YYYY-MM-DD");
  let lastDayOfWeek = moment().clone().weekday(7).format("YYYY-MM-DD");

  const { setErrorToast, setSaveError } = useStepper();
  const [eventsTitle, setEventsTitle] = useState([]);
  const [allEvents, setEvents] = useState([]);
  const [startDateKey, setStartDateKey] = useState(true);
  const [endDateKey, setEndDateKey] = useState(false);
  const [initialValues, setInitialValues] = useState({
    events: filter?.events || "",
    start_date: filter?.start_date || firstDayOfWeek,
    end_date: filter?.end_date || lastDayOfWeek,
  });

  const compileEvents = (events) => {
    let compiledEvents = events?.map((event) => {
      return { label: event.title, value: event.id };
    });
    setEvents(compiledEvents);
    setEventsTitle(compiledEvents);
  };

  const currentDate = new Date();

  const handleSubmit = (values) => {
    if (values?.start_date || values?.events) {
      setNoRecordsMessege("There are no events for the selected dates.");
      const selectedFilter = {
        start_date: values?.start_date || null,
        end_date: values?.end_date || null,
        events: values?.events,
      };

      setPage(1);
      setInitialValues(selectedFilter);
      setFilter(selectedFilter);
    }
    onClose();
  };

  const [fetchDistinctEvents] = useLazyQuery(getDistinctEvents);

  const getAllEventsTitle = async () => {
    fetchDistinctEvents({
      variables: {
        organisation_id: organisation.id,
        current_date: moment().format("YYYY-MM-DD"),
        current_time: moment().format("HH:mm:ss"),
        extra_filter: {},
      },
      onCompleted: (data) => {
        compileEvents(data?.presentations || []);
      },
      onError: (error) => {
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };

  const handleResetForm = (setFieldValue, setErrors) => {
    setFieldValue("events", "");
    setFieldValue("start_date", null);
    setFieldValue("end_date", null);
    setStartDateKey(!startDateKey);
    setEndDateKey(!endDateKey);
    setErrors({});
    setInitialValues({
      events: "",
      start_date: null,
      end_date: null,
    });
    setNoRecordsMessege("No records found");

    if (filter?.events || filter?.start_date || filter?.end_date) {
      setPage(1);
      setFilter({});
    }
  };

  const findEventTitle = (title, search) => {
    for (let i = 0; i <= title?.length - search?.length || 0; i++) {
      if (title.substring(i, i + search?.length) === search) {
        return true;
      }
    }
    return false;
  };

  const getEvents = (search) => {
    if (search?.length) {
      const filteredEvents = allEvents.filter((event) =>
        findEventTitle(event?.label?.toLowerCase(), search?.toLowerCase())
      );
      setEventsTitle(filteredEvents);
    } else {
      setEventsTitle(allEvents);
    }
  };

  const getEventById = (value, values) => {
    const event = allEvents.find((event) => event.value === value);
    setInitialValues({ ...values, events: event.label });
  };

  useEffect(() => {
    if (organisation?.id) {
      getAllEventsTitle();
    }
  }, [organisation?.id]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="event-filter-dialog"
    >
      <DialogTitle className="dialog-header">
        <Stack direction="row" justifyContent="center">
          <EventFilterButton disabled={true} />
        </Stack>
      </DialogTitle>
      <Box className="ml-18 mr-18">
        <Formik
          initialValues={initialValues}
          validationSchema={eventsFilterValidationSchema}
          enableReinitialize
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ values, handleBlur, handleSubmit, setFieldValue, setErrors }) => (
            <Form onSubmit={handleSubmit}>
              <Stack direction="column" justifyContent="space-between">
                <AutocompleteField
                  name="events"
                  label="Events"
                  icon={<SearchIcon sx={{ fontSize: "1.25rem" }} />}
                  options={eventsTitle}
                  getOptions={getEvents}
                  getOptionById={getEventById}
                  disablePortal={false}
                  trackingDetails={TRACKING_EVENTS_PAGE_OPTIONS}
                />
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <DateInput
                    id="startDate"
                    name="start_date"
                    label="Start Date"
                    key={startDateKey}
                    openMonth={true}
                    openToCalender={true}
                    minDate={moment()}
                    maxDate={moment(`${currentDate.getFullYear() + 1}-12-31`)}
                    trackingDetails={TRACKING_EVENTS_PAGE_OPTIONS}
                    value={values.start_date || null}
                    handleChangeValue={(value) => {
                      if (value) {
                        setFieldValue(
                          "start_date",
                          moment(value).format("YYYY-MM-DD")
                        );
                      } else {
                        setFieldValue("start_date", null);
                      }
                    }}
                    handleBlurValue={($event) => handleBlur($event)}
                  />
                  <Typography className="mt-30">-</Typography>
                  <DateInput
                    id="endDate"
                    name="end_date"
                    label="End Date"
                    key={endDateKey}
                    openMonth={true}
                    openToCalender={true}
                    minDate={moment()}
                    maxDate={moment(`${currentDate.getFullYear() + 1}-12-31`)}
                    trackingDetails={TRACKING_EVENTS_PAGE_OPTIONS}
                    value={values.end_date || null}
                    handleChangeValue={(value) => {
                      if (value) {
                        setFieldValue(
                          "end_date",
                          moment(value).format("YYYY-MM-DD")
                        );
                      } else {
                        setFieldValue("end_date", null);
                      }
                    }}
                    handleBlurValue={($event) => handleBlur($event)}
                  />
                </Stack>
              </Stack>
              <Stack
                Stack
                direction="row"
                justifyContent="flex-end"
                className="mt-18 mb-18"
              >
                <Stack
                  Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <LoadingButton
                    buttonTitle="Clear Filter"
                    disabled={false}
                    trackingDetails={TRACKING_EVENTS_PAGE_OPTIONS}
                    handleClick={() =>
                      handleResetForm(setFieldValue, setErrors)
                    }
                    styleClass="btn outlined-btn mediumbtn"
                  />
                  <LoadingButton
                    buttonTitle="Apply Filter"
                    disabled={false}
                    trackingDetails={TRACKING_EVENTS_PAGE_OPTIONS}
                    handleClick={handleSubmit}
                    styleClass="bg-ligter-primary mediumbtn"
                    type="submit"
                  />
                </Stack>
              </Stack>
            </Form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    organisation: state?.organisation?.organisation,
  };
};

EventFilterDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  setFilter: PropTypes.func,
  setPage: PropTypes.func,
  filter: PropTypes.object,
  organisation: PropTypes.object,
  setNoRecordsMessege: PropTypes.func,
};

export default connect(mapStateToProps)(EventFilterDialog);

import { useLazyQuery } from "@apollo/client";
import useStepper from "./useStepper";
import usePlan from "./usePlan";
import {
  getCancelledPlan,
  getLatestActivePlans,
} from "../graphql/queries/manageMySharedCost";
import { useLocation, useNavigate } from "react-router-dom";
import { newApplicationFormPath } from "../constants/path";
import { useState } from "react";
import { schemeTypeNames } from "../constants/multiForm";

const usePlanDetails = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const {
    plans,
    setPlans,
    setLatestActivePlan,
    setIsStandardAVCPlan,
    setIsOptOutPendingPlan,
  } = usePlan();
  const { setErrorToast, setSaveError, setFullScreenLoading } = useStepper();

  const [isPlanLoading, setPlanLoading] = useState(true);

  const [fetchLatestActivePlan] = useLazyQuery(getLatestActivePlans);
  const [fetchCancelledPlan] = useLazyQuery(getCancelledPlan);

  const navigateToManageMySharedCost = () => {
    navigate("/manage_my_shared_cost_avc");
    setFullScreenLoading(false);
    setPlanLoading(false);
    return true;
  };

  const conditionalNavigateToManageMySharedCost = (plans) => {
    const hasPlansLength = plans?.length;
    const isNotAmended = hasPlansLength && hasPlansLength < 2;
    const planStatus = hasPlansLength ? plans[0]?.plan_status?.name : "";

    if (
      [newApplicationFormPath.AMEND_MY_SHARED_COST_AVC].includes(pathname) &&
      !(isNotAmended && planStatus === "Accepted")
    ) {
      return navigateToManageMySharedCost();
    } else if (
      [newApplicationFormPath.CANCEL_MY_PLAN].includes(pathname) &&
      !(isNotAmended && !["Cancelled", "Opted-out"].includes(planStatus))
    ) {
      return navigateToManageMySharedCost();
    } else {
      return false;
    }
  };

  const handleCancelledPlan = (userId, organisationId) => {
    fetchCancelledPlan({
      fetchPolicy: "no-cache",
      variables: {
        user_id: userId,
        organisation_id: organisationId,
      },
      onCompleted: (data) => {
        const cancelledPlanLength = data?.plans?.length;
        let cancelledPlans = data?.plans || [];

        if (cancelledPlanLength === 2) {
          const isOptedOutExist = findPlanStatus(cancelledPlans, "Opted-out");
          cancelledPlans = isOptedOutExist
            ? cancelledPlans.filter(
                (plan) => plan?.plan_status?.name !== "Opted-out"
              )
            : cancelledPlans.splice(1, 1);
        }

        setLatestActivePlan(null);
        setIsStandardAVCPlan(null);
        setIsOptOutPendingPlan(null);
        setPlans(cancelledPlans);
        setPlanLoading(false);
        setFullScreenLoading(false);
      },
      onError: (error) => {
        setPlanLoading(false);
        setFullScreenLoading(false);
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };

  const handleRedirectionToLegacy = (
    userId,
    isOptOutPendingPlan,
    isStandardAVCPlan
  ) => {
    const baseURL = `${process.env.REACT_APP_AVCWISE_URL}/users/${userId}/plans`;
    const redirectionLink = isOptOutPendingPlan
      ? `${baseURL}/${isOptOutPendingPlan.id}/edit_my_avcwise`
      : `${baseURL}/${isStandardAVCPlan.id}/show_my_avcwise`;

    window.location.href = redirectionLink;
  };

  const filterPlansData = (data) => {
    const isMultiplePlans = data?.plans?.length > 1;
    const isOptOutPendingExist = findPlanStatus(data.plans, "Opt Out Pending");
    const filteredPlans =
      isMultiplePlans && isOptOutPendingExist
        ? data.plans.splice(1, 1)
        : data.plans;
    return filteredPlans;
  };

  const getActivePlanId = (filteredPlans) => {
    const isMulipleFilteredPlans = filteredPlans.length > 1;
    const activePlanId = isMulipleFilteredPlans
      ? findPlanStatus(filteredPlans, "Accepted")?.id
      : filteredPlans.find((plan) => plan).id;
    return activePlanId;
  };

  const findPlanStatus = (plans, status) => {
    return plans.find((plan) => plan?.plan_status?.name === status);
  };

  const findPlanScheme = (plans, schemeName) => {
    return plans.find((plan) => plan?.scheme_types?.name === schemeName);
  };

  const fetchCompletePlanDetails = async (
    userId,
    organisationId,
    isFetch = false
  ) => {
    setPlanLoading(true);
    if (plans.length && !isFetch) {
      conditionalNavigateToManageMySharedCost(plans);
      setPlanLoading(false);
      return;
    }
    fetchLatestActivePlan({
      fetchPolicy: "no-cache",
      variables: {
        user_id: userId,
        organisation_id: organisationId,
      },
      onCompleted: (data) => {
        const plansLength = data?.plans?.length;
        if (conditionalNavigateToManageMySharedCost(data?.plans)) {
          return;
        }

        if (plansLength) {
          const filteredPlans = filterPlansData(data);
          const activePlanId = getActivePlanId(filteredPlans);
          const isOptOutPendingInFilteredPlans = findPlanStatus(
            filteredPlans,
            "Opt Out Pending"
          );
          const isStandardAVCPlanExist = findPlanScheme(
            filteredPlans,
            schemeTypeNames.STANDARD_AVC
          );

          setIsStandardAVCPlan(isStandardAVCPlanExist);
          setIsOptOutPendingPlan(isOptOutPendingInFilteredPlans);
          setLatestActivePlan(activePlanId);
          setPlans(filteredPlans);

          const paths = [
            newApplicationFormPath.DEFAULT_APPLICATION_PATH,
            newApplicationFormPath.NEW_APPLICATION_FORM,
            newApplicationFormPath.NHS_APPLICATION_FORM,
            newApplicationFormPath.REDIRECT_APPLICATION_FORM,
            newApplicationFormPath.MAX_CONTRIBUTION_CALCULATOR,
            newApplicationFormPath.HOME,
          ];

          const extendedPaths = [
            ...paths,
            newApplicationFormPath.CANCEL_MY_PLAN,
            newApplicationFormPath.AMEND_MY_SHARED_COST_AVC,
            newApplicationFormPath.MANAGE_MY_SHARED_COST_AVC,
          ];

          const isRedirectionToLegacyApp =
            extendedPaths.includes(pathname) &&
            (isOptOutPendingInFilteredPlans || isStandardAVCPlanExist);

          if (isRedirectionToLegacyApp) {
            handleRedirectionToLegacy(
              userId,
              isOptOutPendingInFilteredPlans,
              isStandardAVCPlanExist
            );
            return;
          }

          if (paths.includes(pathname)) {
            navigateToManageMySharedCost();
          }

          setPlanLoading(false);
          setFullScreenLoading(false);
          return;
        }
        handleCancelledPlan(userId, organisationId);
      },
      onError: (error) => {
        setPlanLoading(false);
        setFullScreenLoading(false);
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };
  return { isPlanLoading, fetchCompletePlanDetails };
};

export default usePlanDetails;

import { Box, Stack, Typography } from "@mui/material";
import { TRACKING_MANAGE_MY_SHARED_COST_OPTIONS } from "../../../../../constants";
import LoadingButton from "../../../../generic-components/button";

const CustomerServiceFooter = () => {
  const liveChatTrigger = () => {
    window?.zE && window?.zE("messenger", "show");
    window?.zE && window?.zE("messenger", "open");
  };

  const handleOpenEmailClient = () => {
    const email = process.env.REACT_APP_SUPPORT_EMAIL;
    const mailtoLink = `mailto:${email}`;
    window.open(mailtoLink);
  };

  return (
    <Box className="customer-service-container mt-30 mb-30">
      <Typography variant="body1" className="customer-service-content">
        Have any questions or need assistance? Connect with the AVC Wise
        customer service team!
      </Typography>
      <Stack
        direction={{ md: "row", sm: "row", xs: "column" }}
        spacing={2}
        className="mt-18"
      >
        <LoadingButton
          buttonTitle="Live Chat"
          trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
          handleClick={liveChatTrigger}
          styleClass="customer-service-button"
        />
        <LoadingButton
          buttonTitle="Email Us"
          trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
          handleClick={handleOpenEmailClient}
          styleClass="customer-service-button"
        />
      </Stack>
    </Box>
  );
};

export default CustomerServiceFooter;

import {
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import SelectForm from "./SelectForm";
import PropTypes from "prop-types";
import { connect } from "react-redux";

export const RegistrationTerms = ({ pensionName }) => {
  const items = [
    { name: "Poster", value: "Poster" },
    { name: "Email", value: "Email" },
    { name: "Internet", value: "Internet" },
    {
      name: "E-Newsletter",
      value: "E-Newsletter",
    },
  ];

  return (
    <Box className="RegistrationTerms mt-30">
      <SelectForm
        name="Where did you hear about us?"
        menuItems={items}
        label="Where did you hear about us?"
      />
      <TextField
        id="referal-email"
        name="referal-email"
        label="Referal-Email"
        variant="outlined"
        className="mt-18"
        type="email"
        fullWidth
      />
      <FormGroup className="mt-18">
        <FormControlLabel
          control={<Checkbox />}
          name="checkbox"
          id="checkbox"
          type="checkbox"
          label={
            <Typography className="stepper-content ml-10 lower-content">
              I confirm that I am a current member of, or I am eligible to join,
              the {pensionName}.
            </Typography>
          }
        />
      </FormGroup>
      <FormGroup className="mt-18">
        <FormControlLabel
          control={<Checkbox />}
          name="checkbox"
          id="checkbox"
          type="checkbox"
          label={
            <Typography className="ml-10 lower-content">
              I agree to the{" "}
              <Link to="#" className="termsAndCondition">
                terms and conditions
              </Link>
              .
            </Typography>
          }
        />
      </FormGroup>
      <Typography className="KeepInTouch mt-18">Keeping in touch</Typography>
      <Typography className="lower-content mt-18">
        AVC Wise will only send you communications on your employer's behalf
        that relates to the employee benefit scheme(s) available to you via the
        AVC Wise website.
      </Typography>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    pensionName: state.multiForm.pensionName,
  };
};

RegistrationTerms.propTypes = {
  pensionName: PropTypes.string,
};

export default connect(mapStateToProps)(RegistrationTerms);

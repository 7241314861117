const personalValidationWithSteps = {
  employee_number: 0,
  ni_number: 0,
  title: 0,
  first_name: 0,
  last_name: 0,
  date_of_birth: 0,
  mobile_number: 0,
  email: 0,
  address1: 0,
  town: 0,
  county: 0,
  postcode: 0,
};

const validationWithSteps = {
  hours_per_week: 1,
  total_avc_amount_requested: 1,
  annual_salary: 1,
  pension_fund_id: 2,
  provider_id: 2,
  choosen_relevant_investment_option: 2,
  retirement_age: 2,
};

export { validationWithSteps, personalValidationWithSteps };

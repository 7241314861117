import { gql } from "@apollo/client";

export const getPayPeriod = gql`
  query getPayPeriod($organisation_id: Int!) {
    pay_periods(
      where: {
        organisation_id: { _eq: $organisation_id }
        enabled: { _eq: true }
      }
    ) {
      id
      period
    }
  }
`;

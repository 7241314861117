import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import React from "react";
import frontImage from "../../../../assets/images/front-image.png";
import avcwiseLogoDark from "../../../../assets/images/avc-logo-dark.png";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

const EmailVerificationPage = () => {
  const navigate = useNavigate();

  const emailvalidation = yup.object({
    email: yup
      .string()
      .email(
        "The provided email address does not conform to the standard format for an email address."
      )
      .required("In order to proceed, please enter a valid email address !!"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: emailvalidation,
    onSubmit: () => {
      navigate("/verifiedscreen");
    },
  });

  return (
    <>
      <Box
        className="front-screen-container container-wrapper"
        sx={{ backgroundImage: `url(${frontImage})` }}
      >
        <Container>
          <Grid container>
            <Grid item xs={12} className="content-wrapper">
              <Grid item xs={12} sm={10} md={7} sx={{ margin: "0 auto" }}>
                <form onSubmit={formik.handleSubmit}>
                  <Box className="content-container">
                    <Box className="inner-content-container">
                      <Box className="img-container mb-50">
                        <Box
                          component="img"
                          className="logo-img"
                          src={avcwiseLogoDark}
                          alt="logo-dark"
                        />
                      </Box>
                      <Typography className="content">
                        Before you start your journey to a more comfortable
                        retirement, tell us your email address so you can save
                        your application progress
                      </Typography>

                      <TextField
                        id="email"
                        name="email"
                        label="Email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        fullWidth
                        variant="outlined"
                        className="mt-18"
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                      />
                      <Button
                        type="submit"
                        variant="contained"
                        className="signinflow-btn mt-30"
                      >
                        Continue
                      </Button>
                    </Box>
                  </Box>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default EmailVerificationPage;

import { fixedAmountToDecimal } from "../helpers";
import store from "../store";

const calculateMaximumContribution = (values) => {
  const primaryThreshold = 9880;
  let minimumWageBasedOnAge = 0;
  let annualSalary = values.income || 0.0;
  let otherReductions = values.salarySacrifice || 0.0;
  // set minimum wage based on age(NMW/NLW 2020 figures)
  let age = values.age || 0.0;
  //calculate organisation's NLW as per user's age
  let organisation = store.getState().organisation.organisation;

  let sharedCostContribution =
    store.getState()?.scheme?.scheme?.shared_cost_contribution;
  //set pay periods
  let payPeriod = 12;

  if (age < 18) minimumWageBasedOnAge = organisation?.wage_sixteen || 4.81;
  else if (age >= 18 && age < 21)
    minimumWageBasedOnAge = organisation?.wage_eighteen || 6.83;
  else if (age >= 21 && age < 23)
    minimumWageBasedOnAge = organisation?.wage_twenty_one || 9.18;
  else minimumWageBasedOnAge = organisation?.wage_twenty_three || 9.5;

  //calculation for Maximum Contribution
  let monthlyPay = annualSalary / payPeriod;
  // NLW check
  let monthlySalAtNlw = fixedAmountToDecimal(
    minimumWageBasedOnAge *
      (((values.hours_per_week || 0) * 52.143) / payPeriod)
  );
  // NIC check

  let monthlyPrimaryThreshold = fixedAmountToDecimal(
    primaryThreshold / payPeriod
  );

  let maximumAmount = 0;
  //Maximum Amount You Can Contribute
  if (!values.PTCheckbox) {
    // Don't Fall Below PRIMARY THRESHOLD
    // Maximum based on the NLW, and if that's negative, we show 0
    maximumAmount =
      monthlyPay -
        otherReductions -
        monthlySalAtNlw -
        parseInt(sharedCostContribution) || 0;
  } else {
    // Fall Below PRIMARY THRESHOLD
    // Maximum based on BOTH the PT and the NLW checks
    let maximumAmountWithPt =
      monthlyPay -
        otherReductions -
        monthlyPrimaryThreshold -
        parseInt(sharedCostContribution) || 0;
    let maximumAmountWithNlw =
      monthlyPay -
        otherReductions -
        monthlySalAtNlw -
        parseInt(sharedCostContribution) || 0;
    // (whichever is the lowest), and if either is negative, we show 0
    maximumAmount =
      maximumAmountWithNlw < maximumAmountWithPt
        ? maximumAmountWithNlw
        : maximumAmountWithPt;
  }
  return maximumAmount >= 0 && fixedAmountToDecimal(maximumAmount);
};

const avcUniqueTrackingCode = () => {
  var current_date = new Date();
  var components = [
    "T",
    current_date.getYear(),
    current_date.getMonth(),
    current_date.getDate(),
    current_date.getHours(),
    current_date.getMinutes(),
    current_date.getSeconds(),
    current_date.getMilliseconds(),
    "R",
    Math.floor(Math.random() * 100 + 1),
  ];
  return components.join("");
};

export { calculateMaximumContribution, avcUniqueTrackingCode };

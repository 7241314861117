import { gql } from "@apollo/client";

export const updateFundDetailOnly = gql`
  mutation updateFundDetailOnly(
    $id: Int!
    $pension_fund_id: Int
    $step_number: Int!
    $updated_at: timestamp!
  ) {
    update_temp_plans(
      _set: {
        pension_fund_id: $pension_fund_id
        updated_at: $updated_at
        step_number: $step_number
      }
      where: { id: { _eq: $id } }
    ) {
      returning {
        pension_fund_id
        step_number
      }
    }
  }
`;

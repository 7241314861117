import PropTypes from "prop-types";
import { Box, Stack, Typography } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";

const EventFilterButton = ({ handleClick, disabled = false }) => {
  return (
    <Box
      onClick={() => {
        !disabled && handleClick && handleClick();
      }}
      className={
        disabled ? "filter-button filter-button-disabled" : "filter-button"
      }
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography className="filter-text">Filter by</Typography>
        <FilterListIcon />
      </Stack>
    </Box>
  );
};

EventFilterButton.propTypes = {
  handleClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default EventFilterButton;

import { useLazyQuery } from "@apollo/client";
import { Box, Container, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { getPeronalBookings } from "../../../../../graphql/queries/events";
import SharedHeader from "../../../../generic-components/shared-header/SharedHeader";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import useStepper from "../../../../../hooks/useStepper";
import moment from "moment";
import BookingTable from "./BookingTable";
import IconButton from "../../../../generic-components/icon-btn";
import { TRACKING_EVENTS_PAGE_OPTIONS } from "../../../../../constants";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router-dom";

const PersonalBookings = ({ organisation, user }) => {
  const { setErrorToast, setSaveError } = useStepper();
  const [bookings, setBookings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fetch, setFetch] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const [fetchPeronalBookings] = useLazyQuery(getPeronalBookings);
  const navigate = useNavigate();

  const getAllPersonalBookings = async () => {
    setIsLoading(true);
    fetchPeronalBookings({
      fetchPolicy: "no-cache",
      variables: {
        organisation_id: organisation.id,
        user_id: user.id,
        limit: rowsPerPage,
        offset: rowsPerPage * (page - 1),
        current_date: moment().format("YYYY-MM-DD"),
        current_time: moment().format("HH:mm:ss"),
      },
      onCompleted: (data) => {
        setCount(
          data?.booked_user_presentations_aggregate?.aggregate?.count || 0
        );
        setBookings(data?.booked_user_presentations || []);
        setIsLoading(false);
      },
      onError: (error) => {
        setIsLoading(false);
        setSaveError(error);
        setErrorToast(true);
      },
    });
  };

  useEffect(() => {
    if (organisation.id && user.id) {
      getAllPersonalBookings();
    }
  }, [organisation.id, user.id, page, rowsPerPage, fetch]);

  return (
    <Box className="application-page-container mb-100">
      <SharedHeader heading="Events" />
      <Box className="mt-150">
        <Container>
          <IconButton
            trackingDetails={TRACKING_EVENTS_PAGE_OPTIONS}
            buttonTitle="Back to Events"
            styleClass="light-backbtn"
            handleClick={() => navigate("/events")}
            link="#"
            icon={<KeyboardArrowLeftIcon />}
          />
          <Box className="stamp-page mt-30 mb-30">
            <Box className="event-heading-container">
              <Typography className="event-heading">My Bookings</Typography>
            </Box>
            <Box className="event-details-container">
              <Typography className="mt-18">
                <span className="mr-10">
                  <strong>View your scheduled events below</strong>
                </span>
              </Typography>
              <BookingTable
                count={count}
                page={page}
                rowsPerPage={rowsPerPage}
                bookings={bookings}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                fetch={fetch}
                setPage={setPage}
                setFetch={setFetch}
                setRowsPerPage={setRowsPerPage}
              />
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state?.user?.user,
    organisation: state?.organisation?.organisation,
  };
};

PersonalBookings.propTypes = {
  organisation: PropTypes.object,
  user: PropTypes.object,
};

export default connect(mapStateToProps)(PersonalBookings);

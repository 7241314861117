import InputMask from "react-input-mask";
import { debounce, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import { checkFieldError } from "../../../helpers";
import PropTypes from "prop-types";
import useUserEvent from "../../../hooks/useUserEvent";
import useStepper from "../../../hooks/useStepper";
import { dashesRegex } from "../../../constants";
import { useEffect, useState } from "react";

const MaskedInput = ({
  name,
  label,
  mask,
  value,
  updateRedux,
  handleChangeValue,
  customError,
  trackingDetails,
  disabled = false,
  styleClass = "mt-30",
}) => {
  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    setFieldValue,
    isValid,
  } = useFormikContext();

  const { trackingCode } = useStepper();
  const [fieldValue, setValue] = useState(values[name] || value);
  const { userTrackingMutation } = useUserEvent();

  const handleUserEvent = (element) => {
    if (element?.target?.value && !dashesRegex.test(element?.target?.value)) {
      userTrackingMutation({
        variables: {
          ...trackingDetails,
          field_name: element?.target?.name || values[name] || "",
          field_value: element?.target?.value?.toString() || "",
          avc_track_code: trackingCode,
        },
      });
    }
  };

  useEffect(() => {
    setValue(values[name] || value);
  }, [values[name], value]);

  const debouncedHandleChange = debounce(($event) => {
    handleChange($event);
    handleChangeValue && handleChangeValue($event);
    updateRedux &&
      updateRedux(name, $event.target.value, setFieldValue, isValid);
  }, 500);

  return (
    <InputMask
      alwaysShowMask={false}
      overwrite
      mask={mask}
      disabled={disabled}
      value={fieldValue || ""}
      onChange={($event) => {
        debouncedHandleChange($event);
        setValue($event?.target?.value);
      }}
      onFocus={($event) => {
        handleUserEvent($event);
      }}
      onBlur={($event) => {
        handleBlur($event);
        updateRedux &&
          updateRedux(name, $event.target.value, setFieldValue, isValid);
        handleUserEvent($event);
      }}
    >
      {() => (
        <TextField
          id={name}
          name={name}
          label={label}
          disabled={disabled}
          fullWidth
          variant="outlined"
          className={styleClass}
          error={
            customError && customError[name]
              ? customError[name]
              : checkFieldError(touched, errors, name)
          }
          helperText={
            customError && customError[name]
              ? customError[name]
              : checkFieldError(touched, errors, name)
          }
        />
      )}
    </InputMask>
  );
};

MaskedInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  styleClass: PropTypes.string,
  disabled: PropTypes.bool,
  customError: PropTypes.object,
  mask: PropTypes.string,
  updateRedux: PropTypes.func,
  handleChangeValue: PropTypes.func,
  trackingDetails: PropTypes.object,
};

export default MaskedInput;

import { Box, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import CalculateIcon from "@mui/icons-material/Calculate";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import ContributionCalculatorModal from "../modal/ContributionCalculatorModal";
import FormSelect from "../../../../generic-components/form-select";
import { getPayPeriodName, fixedAmountToDecimal } from "../../../../../helpers";
import FormHeader from "../header/FormHeader";
import { sharedCostFormValidationSchema } from "../../../../../schema";
import { Formik } from "formik";
import FooterButtons from "../footer/FooterButtons";
import useStepper from "../../../../../hooks/useStepper";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";
import { useLazyQuery, useMutation } from "@apollo/client";
import { updateSharedCostForm } from "../../../../../graphql/mutations/sharedCostAVCAmount";
import { setForm } from "../../../../../reducers/formReducer";
import moment from "moment";
import { getPayPeriod } from "../../../../../graphql/queries/payPeriods";
import Loader from "../../../../generic-components/loader";
import { useToast } from "../../../../../hooks/useToast";
import ScrollToFieldError from "../../../../generic-components/scrollToFieldError";
import {
  TRACKING_NAF_OPTIONS,
  floatRegex,
  referralCodeRegex,
} from "../../../../../constants";
import IconInputField from "../../../../generic-components/icon-inputfield";
import TextInput from "../../../../generic-components/input-text";
import SavePlan from "../../../../generic-components/save-plan";
import MaxContributionCalculationForm from "./MaxContributionCalculationForm";
import useUserEvent from "../../../../../hooks/useUserEvent";
import { checkReferralCode } from "../../../../../graphql/queries/validator";

const SharedCostForm = ({ form, organisation, formSharedTitle }) => {
  const { showToast } = useToast();
  const dispatch = useDispatch();
  const {
    handleNext,
    formValues,
    sharedFormIndex,
    activeStep,
    setFormValues,
    setIsLoadingSave,
    setIsLoadingNext,
    btnClicked,
    setSavedToast,
    setSaveError,
    setErrorToast,
    isLoading,
    trackingCode,
    personalDetails,
  } = useStepper();

  const [open, setOpen] = useState(true);
  const [updateSharedCost] = useMutation(updateSharedCostForm);
  const [payPeriod, setPayPeriods] = useState([]);
  const [customError, setCustomError] = useState(null);

  const { userTrackingMutation } = useUserEvent();

  const [checkReferralCodeExist, { loading: referralCodeLoading }] =
    useLazyQuery(checkReferralCode);

  const validateReferralCode = async (value, fieldName, avcAmount) => {
    setCustomError({ ...customError, [fieldName]: "" });
    if (!value?.trim() || !referralCodeRegex.test(value?.trim()) || !avcAmount)
      return;

    checkReferralCodeExist({
      variables: {
        referral_code: value.trim(),
        total_avc_amount_requested: avcAmount,
      },
      onError: (error) => {
        if (
          error?.message &&
          ["Referral code not valid"].includes(error?.message)
        ) {
          setCustomError({ ...customError, [fieldName]: error?.message });
        } else {
          setSaveError(error);
          setErrorToast(true);
        }
      },
    });
  };

  const debounceValidateReferralCode = debounce(
    (value, fieldName, avcAmount) => {
      validateReferralCode(value, fieldName, avcAmount);
    },
    1000
  );

  const [getPayPeriodDetails, { loading: payPeriodLoader, error }] =
    useLazyQuery(getPayPeriod, {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        setPayPeriods(
          data.pay_periods.map((item) => ({
            name: getPayPeriodName(item.period),
            value: getPayPeriodName(item.period),
          }))
        );
      },
    });

  useEffect(() => {
    if (organisation.id)
      getPayPeriodDetails({
        variables: {
          organisation_id: organisation.id,
        },
      });
  }, [organisation]);

  const initialValues = {
    income: formValues.income,
    avcAmount:
      Number(
        formValues.additional_avc_amount
          ? fixedAmountToDecimal(
              Number(formValues.avcAmount) + formValues.additional_avc_amount
            )
          : fixedAmountToDecimal(formValues.avcAmount)
      ) || null,
    hours_per_week: formValues.hours_per_week || null,
    incomeFrequency: formValues.incomeFrequency,
    additional_avc_amount: formValues.additional_avc_amount,
    max_contribution_amount: formValues.max_contribution_amount,
    referral_code: formValues.referral_code || "",
  };

  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => {
    setOpenModal(true);
    handleUserEvent();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const queryLoaders = useMemo(() => payPeriodLoader || isLoading);

  const isCustomError = useMemo(() => {
    return !!Object.keys(customError || []).filter((key) => customError[key])
      .length;
  }, [customError]);

  const handleUserEvent = () => {
    userTrackingMutation({
      variables: {
        ...TRACKING_NAF_OPTIONS,
        field_name: "Calculator",
        field_value: "NLW Maximum Contribution Calculator",
        avc_track_code: trackingCode,
      },
    });
  };

  return (
    <>
      <Formik
        validationSchema={sharedCostFormValidationSchema}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={(values) => {
          if (isCustomError || referralCodeLoading) return;
          setSaveError("");
          setFormValues((prevValues) => ({
            ...prevValues,
            ...values,
            income: parseInt(values.income),
            avcAmount: Number(
              values.additional_avc_amount
                ? Number(values.avcAmount) - values.additional_avc_amount
                : values.avcAmount
            ),
            annual_salary: parseInt(values.income),
          }));
          if (btnClicked === "save_and_next") {
            setIsLoadingNext(true);
          } else if (btnClicked === "save") {
            setIsLoadingSave(true);
          }

          updateSharedCost({
            variables: {
              id: form.id,
              updated_at: moment().format(),
              total_avc_amount_requested: formValues.additional_avc_amount
                ? Number(values.avcAmount) - formValues.additional_avc_amount
                : values.avcAmount,
              annual_salary: parseInt(values.income),
              hours_per_week: values.hours_per_week || 0,
              avc_interval: values.incomeFrequency,
              max_contribution_amount: values.max_contribution_amount || 0,
              previous_amount_added: values.previous_amount_added || 0,
              contribution_amount_updated:
                values.contribution_amount_updated || false,
              additional_avc_amount: values.additional_avc_amount,
              step_number:
                btnClicked === "save"
                  ? activeStep + sharedFormIndex
                  : activeStep + sharedFormIndex + 1,
              referral_code: values.referral_code || "",
            },
            onCompleted: (data) => {
              dispatch(setForm(data.update_temp_plans.returning[0]));
              if (btnClicked === "save") {
                setSavedToast(true);
              }
              if (btnClicked === "save_and_next") {
                handleNext();
              }
              setIsLoadingNext(false);
              setIsLoadingSave(false);
            },
            onError: (error) => {
              setIsLoadingNext(false);
              setIsLoadingSave(false);
              setSaveError(error);
              setErrorToast(true);
            },
          });
        }}
      >
        {({ values, handleBlur, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            {queryLoaders ? (
              <Box className="mt-30">
                <Loader />
              </Box>
            ) : (
              <>
                <Box className="application-page-container mb-50">
                  {error && showToast(error.message, open, setOpen, "error")}
                  <FormHeader heading={`${formSharedTitle} Amount`} />
                  <ScrollToFieldError
                    customError={customError}
                    isCustomError={isCustomError}
                  />
                  <SavePlan isCustomError={isCustomError} />
                  <MaxContributionCalculationForm />
                  <Box className="stepper-field">
                    <IconInputField
                      id="income"
                      name="income"
                      label="How much do you get paid annually? *"
                      trackingDetails={TRACKING_NAF_OPTIONS}
                      value={values.income}
                      icon={<CurrencyPoundIcon className="input-field-icon" />}
                      isValidValue={(e) => {
                        if (
                          e?.target?.value > 0 &&
                          e?.target?.value?.length <= 9 &&
                          floatRegex.test(parseFloat(e?.target?.value))
                        ) {
                          return true;
                        } else {
                          return false;
                        }
                      }}
                      handleChangeValue={(e, isValidValue) => {
                        if (isValidValue) {
                          setFieldValue("income", e?.target?.value);
                        } else {
                          setFieldValue("income", "");
                        }
                      }}
                      handleBlurValue={handleBlur}
                    />

                    <TextInput
                      id="hours_per_week"
                      name="hours_per_week"
                      label="Working hours per week *"
                      trackingDetails={TRACKING_NAF_OPTIONS}
                      value={values.hours_per_week || ""}
                      isValidValue={(e) => {
                        if (
                          e?.target?.value > 0 &&
                          e?.target?.value <= 40 &&
                          floatRegex.test(parseFloat(e?.target?.value))
                        )
                          return true;
                        else return false;
                      }}
                      handleChangeValue={(e, isValidValue) => {
                        if (isValidValue)
                          setFieldValue("hours_per_week", e?.target?.value);
                        else setFieldValue("hours_per_week", "");
                      }}
                      handleBlurValue={handleBlur}
                    />

                    <FormSelect
                      name="incomeFrequency"
                      labelId="incomeFrequencyList"
                      menuItems={payPeriod}
                      label="How often do you get paid? *"
                      trackingDetails={TRACKING_NAF_OPTIONS}
                    />

                    <IconInputField
                      id="avcAmount"
                      name="avcAmount"
                      trackingDetails={TRACKING_NAF_OPTIONS}
                      label={`How much can you afford to pay per pay period into a ${formSharedTitle}? *  `}
                      value={values.avcAmount}
                      icon={<CurrencyPoundIcon className="input-field-icon" />}
                      isValidValue={(e) => {
                        if (
                          e.target.value > 0 &&
                          floatRegex.test(parseFloat(e.target.value))
                        ) {
                          return true;
                        } else {
                          return false;
                        }
                      }}
                      handleChangeValue={(e, isValidValue) => {
                        if (isValidValue) {
                          setFieldValue("avcAmount", e.target.value);
                          debounceValidateReferralCode(
                            values.referral_code,
                            "referral_code",
                            e.target.value
                          );
                        } else setFieldValue("avcAmount", "");

                        setFieldValue("additional_avc_amount", 0);
                        setFieldValue("contribution_amount_updated", false);
                        setFieldValue("previous_amount_added", 0);
                      }}
                      handleBlurValue={handleBlur}
                    />

                    {personalDetails.allow_referral_code && (
                      <TextInput
                        id="referral_code"
                        name="referral_code"
                        label="Referral Code"
                        trackingDetails={TRACKING_NAF_OPTIONS}
                        handleChangeValue={(e) => {
                          debounceValidateReferralCode(
                            e?.target?.value,
                            "referral_code",
                            formValues.additional_avc_amount
                              ? Number(values.avcAmount) -
                                  formValues.additional_avc_amount
                              : values.avcAmount
                          );
                        }}
                        customError={customError}
                        loading={referralCodeLoading}
                        value={values.referral_code || ""}
                      />
                    )}

                    <Box
                      className="ContributionCalculator mt-18"
                      onClick={handleOpen}
                    >
                      <Box className="shared-cost-form-footer">
                        <Typography className="content">
                          Want to find out the maximum you <br /> can pay into
                          {""} {formSharedTitle}?{" "}
                          <span className="web-link cursor-pointer mr-5">
                            Use our calculator, click here:
                          </span>
                        </Typography>
                      </Box>
                      <Box className="calculator-icon-container">
                        <CalculateIcon
                          className="calculator-icon"
                          fontSize="large"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <FooterButtons isCustomLoading={referralCodeLoading} />
                <ContributionCalculatorModal
                  open={openModal}
                  setOpen={setOpenModal}
                  sharedCostValues={values}
                  applyButtonTitle="Apply for maximum contribution"
                  closeButtonTitle="Return to Application"
                />
              </>
            )}
          </form>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    formSharedTitle: state.multiForm.formSharedTitle,
    organisation: state.organisation.organisation,
    form: state.form.form,
  };
};

SharedCostForm.propTypes = {
  form: PropTypes.object,
  organisation: PropTypes.object,
  formSharedTitle: PropTypes.string,
};

export default connect(mapStateToProps)(SharedCostForm);

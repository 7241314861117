import {
  Box,
  Container,
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
  FormHelperText,
} from "@mui/material";
import React from "react";
import frontImage from "../../../../assets/images/front-image.png";
import avcwiseLogoDark from "../../../../assets/images/avc-logo-dark.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

const SignIn = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const navigate = useNavigate();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const passwordRegexp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const SigninValidation = yup.object({
    email: yup
      .string()
      .email(
        "The provided email address does not conform to the standard format for an email address."
      )
      .required("In order to proceed, please enter a valid email address !!"),
    Password: yup
      .string()
      .matches(
        passwordRegexp,
        "The password must be at least 8 characters in length."
      )
      .required(
        "A password is required, please enter one in the designated field."
      ),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      Password: "",
    },
    validationSchema: SigninValidation,
    onSubmit: () => {
      navigate("/aftersignin");
    },
  });
  return (
    <>
      <Box
        className="front-screen-container container-wrapper"
        sx={{ backgroundImage: `url(${frontImage})` }}
      >
        <Grid container>
          <Grid item xs={12}>
            <Container>
              <form onSubmit={formik.handleSubmit}>
                <Box className="content-wrapper">
                  <Box className="content-container signIn-container">
                    <Box className="inner-content-container">
                      <Box className="img-container mb-50">
                        <Box
                          component="img"
                          className="logo-img"
                          src={avcwiseLogoDark}
                          alt="logo-dark"
                        />
                      </Box>
                      <Typography className="signIn-heading">
                        Sign In
                      </Typography>
                      <Box className="text-field mt-30">
                        <TextField
                          id="email"
                          name="email"
                          label="Email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          fullWidth
                          variant="outlined"
                          className=""
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                        />

                        <FormControl
                          variant="outlined"
                          className="mt-18"
                          fullWidth
                        >
                          <InputLabel
                            htmlFor="outlined-adornment-password"
                            error={
                              formik.touched.Password &&
                              Boolean(formik.errors.Password)
                            }
                          >
                            Password
                          </InputLabel>
                          <OutlinedInput
                            id="Password"
                            name="Password"
                            type={showPassword ? "text" : "password"}
                            value={formik.values.Password}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.Password &&
                              Boolean(formik.errors.Password)
                            }
                            helperText={
                              formik.touched.Password && formik.errors.Password
                            }
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Password"
                          />
                          <FormHelperText
                            error={
                              formik.touched.Password &&
                              Boolean(formik.errors.Password)
                            }
                          >
                            {formik.touched.Password && formik.errors.Password}
                          </FormHelperText>
                        </FormControl>
                      </Box>
                      <Button
                        type="submit"
                        variant="contained"
                        className="signinflow-btn mt-30"
                      >
                        Sign in
                      </Button>

                      <Box className="instruction-links mt-30">
                        <Link to="#" className="link">
                          Forgotten password?
                        </Link>
                        <Link to="#" className="link">
                          Didn't receive confirmation instructions?
                        </Link>
                        <Link to="#" className="link">
                          Didn't receive unlock instructions?
                        </Link>
                      </Box>
                      <Box className="registerdetails-container mt-50">
                        <Typography className="registered-heading">
                          Not registered?
                        </Typography>
                        <Typography className="registered-details mt-30">
                          If your organization is already registered with AVC
                          Wise, you can create an account today.
                        </Typography>
                        <Button
                          component={Link}
                          to="/register"
                          variant="contained"
                          className="signinflow-btn mt-30"
                        >
                          Register
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </form>
            </Container>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SignIn;

import React from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  Stack,
} from "@mui/material";
import avcwise from "../../../assets/images/avcwise-logo.png";
import {
  footerLinks,
  footerSocialMediaLinks,
  TRACKING_FOOTER_OPTIONS,
} from "../../../constants";
import NavigationLinks from "../../generic-components/navigation-links";
import SocialMediaLinks from "../../generic-components/socialmedia-links";
import LogoButton from "../../generic-components/logo-btn";
import IconButton from "../../generic-components/icon-btn";
import CallIcon from "@mui/icons-material/Call";

const Footer = () => {
  const mobile = useMediaQuery("(max-width:600px)");
  const tablet = useMediaQuery("(max-width:900px)");

  const mobileFooterOne = footerLinks.slice(0, 2);
  const mobileFooterTwo = footerLinks.slice(-2);

  return (
    <>
      <Box className="footer">
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <Box className="footer-content">
                <Grid container>
                  <Grid item xs={12} md={2.5}>
                    {tablet ? (
                      <>
                        <Stack direction="row" justifyContent="space-between">
                          <Box className="footer-logo-container">
                            <LogoButton
                              name="AVCWISE"
                              logoPath={avcwise}
                              trackingDetails={TRACKING_FOOTER_OPTIONS}
                              styleClass="footer-logo"
                            />
                          </Box>
                          <Box className="footer-socialmedia-container">
                            <Box className="footer-socialmedia">
                              <IconButton
                                trackingDetails={TRACKING_FOOTER_OPTIONS}
                                buttonTitle="01252 959 779"
                                styleClass="call"
                                link="tel:123-456-7890"
                                icon={<CallIcon />}
                              />
                              <Box className="socialmedia" mt={2}>
                                {footerSocialMediaLinks.map((val, index) => (
                                  <>
                                    <SocialMediaLinks
                                      link={val.link}
                                      name={val.name}
                                      iconPath={val.iconPath}
                                      trackingDetails={TRACKING_FOOTER_OPTIONS}
                                      key={index}
                                    />
                                  </>
                                ))}
                              </Box>
                            </Box>
                          </Box>
                        </Stack>
                      </>
                    ) : (
                      <Box className="footer-logo-container">
                        <LogoButton
                          name="AVCWISE"
                          logoPath={avcwise}
                          trackingDetails={TRACKING_FOOTER_OPTIONS}
                          styleClass="footer-logo"
                        />
                      </Box>
                    )}
                  </Grid>

                  <Grid item xs={12} md={7}>
                    <Box className="footer-links">
                      <Box className="links">
                        {mobile ? (
                          <Stack
                            direction="column"
                            spacing={2}
                            justifyContent="center"
                          >
                            <Stack
                              direction="row"
                              spacing={4}
                              justifyContent="center"
                            >
                              {mobileFooterOne.map((val, index) => (
                                <>
                                  <NavigationLinks
                                    styleClass="footerlinks nav-links"
                                    link={val.link}
                                    name={val.name}
                                    trackingDetails={TRACKING_FOOTER_OPTIONS}
                                    newTab={true}
                                    key={index}
                                  />

                                  <Typography
                                    variant="body1"
                                    className="divider"
                                  >
                                    |
                                  </Typography>
                                </>
                              ))}
                            </Stack>
                            <Stack
                              direction="row"
                              spacing={4}
                              justifyContent="center"
                            >
                              {mobileFooterTwo.map((val, index) => (
                                <>
                                  <NavigationLinks
                                    styleClass="footerlinks nav-links"
                                    link={val.link}
                                    name={val.name}
                                    trackingDetails={TRACKING_FOOTER_OPTIONS}
                                    key={index}
                                    newTab={true}
                                  />

                                  <Typography
                                    variant="body1"
                                    className="divider"
                                  >
                                    |
                                  </Typography>
                                </>
                              ))}
                            </Stack>
                          </Stack>
                        ) : (
                          <>
                            {footerLinks.map((val, index) => (
                              <>
                                <NavigationLinks
                                  styleClass="footerlinks nav-links"
                                  link={val.link}
                                  name={val.name}
                                  trackingDetails={TRACKING_FOOTER_OPTIONS}
                                  newTab={true}
                                  key={index}
                                />

                                <Typography variant="body1" className="divider">
                                  |
                                </Typography>
                              </>
                            ))}
                          </>
                        )}
                      </Box>
                      <Box className="lower-content">
                        <Typography mt={2} className="content">
                          © 2023, AVC Wise Limited. All Rights reserved. Company
                          Number 11406613
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  {tablet ? (
                    ""
                  ) : (
                    <Grid item xs={6} md={2.5}>
                      <Box className="footer-socialmedia-container">
                        <Box className="footer-socialmedia">
                          <IconButton
                            trackingDetails={TRACKING_FOOTER_OPTIONS}
                            buttonTitle="01252 959 779"
                            styleClass="call"
                            link="tel:123-456-7890"
                            icon={<CallIcon />}
                          />
                          <Box className="socialmedia" mt={2}>
                            {footerSocialMediaLinks.map((val, index) => (
                              <>
                                <SocialMediaLinks
                                  styleClass="footerlinks nav-links"
                                  link={val.link}
                                  name={val.name}
                                  iconPath={val.iconPath}
                                  trackingDetails={TRACKING_FOOTER_OPTIONS}
                                  key={index}
                                />
                              </>
                            ))}
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};
export default Footer;

import { Box, Container, Stack, Typography } from "@mui/material";
import { TRACKING_EVENTS_PAGE_OPTIONS } from "../../../../../constants";
import LoadingButton from "../../../../generic-components/button";

const IMHeader = () => {
  const handleRedirection = () => {
    if (process.env.REACT_APP_INDIVIDUAL_MEETING_URL) {
      window.location.href = process.env.REACT_APP_INDIVIDUAL_MEETING_URL;
    }
  };

  return (
    <Container>
      <Box className="im-header mb-30">
        <Stack
          direction={{ sm: "row", md: "row", xs: "column" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography className="im-heading">
            Book your free individual meeting with a retirement expert
          </Typography>
          <LoadingButton
            buttonTitle="Book individual meeting"
            trackingDetails={TRACKING_EVENTS_PAGE_OPTIONS}
            styleClass="btn im-btn"
            handleClick={handleRedirection}
          />
        </Stack>
      </Box>
    </Container>
  );
};

export default IMHeader;

import {
  Checkbox as MuiCheckbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { checkFieldError, findItemBySelectedValue } from "../../../helpers";
import useUserEvent from "../../../hooks/useUserEvent";
import useStepper from "../../../hooks/useStepper";

const Checkbox = ({ name, label, handleChange, trackingDetails }) => {
  const { values, errors, touched } = useFormikContext();

  const { userTrackingMutation } = useUserEvent();
  const { trackingCode } = useStepper();

  const handleUserEvent = (element) => {
    const { name, checked } = element?.target || {};
    const { fieldName } = findItemBySelectedValue(name);
    userTrackingMutation({
      variables: {
        ...trackingDetails,
        field_name: fieldName || "",
        field_value: checked
          ? `Select - ${fieldName}`
          : `Unselect - ${fieldName}`,
        avc_track_code: trackingCode,
      },
    });
  };

  return (
    <FormGroup className="checkbox-container">
      <FormControlLabel
        control={
          <MuiCheckbox
            checked={values[name]}
            sx={{
              "&.Mui-checked": {
                color: "#0F5395",
              },
            }}
            onChange={handleChange}
            onClick={handleUserEvent}
          />
        }
        name={name}
        id={name}
        type="checkbox"
        value={values[name]}
        error={checkFieldError(touched, errors, name)}
        label={<Typography className="checkbox-label">{label}</Typography>}
      />
      <FormHelperText error={checkFieldError(touched, errors, name)}>
        {checkFieldError(touched, errors, name)}
      </FormHelperText>
    </FormGroup>
  );
};
Checkbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  handleChange: PropTypes.func,
  trackingDetails: PropTypes.object,
};
export default Checkbox;

import { Autocomplete, TextField, Stack } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import useStepper from "../../../hooks/useStepper";
import useUserEvent from "../../../hooks/useUserEvent";

const AutocompleteField = ({
  name,
  label,
  icon,
  options,
  getOptions,
  getOptionById,
  trackingDetails,
  disablePortal = true,
}) => {
  const { values, handleChange } = useFormikContext();
  const { userTrackingMutation } = useUserEvent();
  const { trackingCode } = useStepper();

  const handleUserEvent = (element) => {
    if (element.target?.value || values[name]) {
      userTrackingMutation({
        variables: {
          ...trackingDetails,
          field_name: element.target?.name || name || "",
          field_value: element.target?.value?.toString() || values[name] || "",
          avc_track_code: trackingCode,
        },
      });
    }
  };

  return (
    <Autocomplete
      disablePortal={disablePortal}
      options={options}
      fullWidth
      className="mt-18"
      noOptionsText={`No ${name} found`}
      filterOptions={(x) => x}
      value={values[name]}
      onChange={(e, option) => {
        option && getOptionById(option.value, values);
      }}
      onInputChange={(e) => {
        e && getOptions(e.target.value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          id={name}
          name={name}
          value={values[name]}
          label={
            <Stack direction="row">
              {icon}
              {label}
            </Stack>
          }
          variant="outlined"
          onChange={handleChange}
          onFocus={(e) => {
            handleUserEvent(e);
          }}
          onBlur={(e) => {
            handleChange(e);
            handleUserEvent(e);
          }}
        />
      )}
    />
  );
};

AutocompleteField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  getOptions: PropTypes.func,
  getOptionById: PropTypes.func,
  icon: PropTypes.element,
  trackingDetails: PropTypes.object,
  disablePortal: PropTypes.bool,
};

export default AutocompleteField;

import {
  Box,
  Stack,
  InputAdornment,
  TextField,
  Typography,
  AccordionDetails,
  FormHelperText,
} from "@mui/material";
import FormCheckbox from "../../../../generic-components/formik-checkbox";
import { useEffect, useMemo, useRef, useState } from "react";
import Accordion from "../../../../generic-components/accordion";
import { useFormikContext } from "formik";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PercentIcon from "@mui/icons-material/Percent";
import {
  TRACKING_NAF_OPTIONS,
  investmentChoicesCheckboxes,
} from "../../../../../constants";
import {
  decodeURLToString,
  fixedAmountToDecimal,
} from "../../../../../helpers";
import CheckboxGroup from "../../../../generic-components/checkbox-group";
import Tooltip from "../../../../generic-components/tooltip";
import ChosenInvestmentTable from "./ChosenInvestmentTable";
import useStepper from "../../../../../hooks/useStepper";
import ProviderDetail from "./provider-info/ProviderDetail";
import { getInvestmentOptions } from "../../../../../graphql/queries/investmentOptions";
import { useLazyQuery, useQuery } from "@apollo/client";
import InvestmentChoicesStep2Options from "./InvestmentChoicesStep2Options";
import Loader from "../../../../generic-components/loader";
import { useToast } from "../../../../../hooks/useToast";
import ProviderNote from "./provider-info/ProviderNote";
import PropTypes from "prop-types";
import { getPensionFundById } from "../../../../../graphql/queries/pensionFunds";
import { fetchLinks } from "../../../../../graphql/queries/fetchLinks";
import PreviousAvcAndPensionsInfo from "../previous-avc-and-pensions-info/PreviousAvcAndPensionsInfo";
import ClericalOptions from "./ClericalOptions";
import TextInput from "../../../../generic-components/input-text";
import NINGI_JOURNEY_STATUS from "../../../../../constants/ningiJourneyStatus";
import { connect } from "react-redux";

const InvestmentChoicesStep2Provider = ({
  form,
  scheme,
  expanded,
  setExpanded,
  formSharedTitle,
  pensionType,
  providers,
  handleNingiChange,
  resetInvestmentChoices,
}) => {
  const { formValues, setSaveError, setErrorToast } = useStepper();
  const [providerName, setProviderName] = useState("");
  const [investmentChoicesOptions, setInvestmentChoicesOptions] = useState([]);
  const [defaultInvestmentOptions, setDefaultInvestmentOption] = useState(null);
  const [open, setOpen] = useState(true);
  const [prudentialLink, setPrudentialLink] = useState("");
  const [legalGeneralLink, setLegalGeneralLink] = useState("");
  const [beSpokeLink, setBeSpokeLink] = useState("");
  const inputRef = useRef();

  const { values, errors, handleChange, handleBlur, setFieldValue } =
    useFormikContext();

  const { showToast } = useToast();

  const [getBeSpokeLink, { loading: beSpokeLoading, error: beSpokeError }] =
    useLazyQuery(fetchLinks, {
      variables: {
        scheme_id: scheme.scheme_id,
      },
      onCompleted: (data) => {
        const beSpokeGuide = decodeURLToString(
          data.scheme_details.be_spoke_guide
        );

        if (!beSpokeGuide) return;

        setBeSpokeLink(
          beSpokeGuide.startsWith("https") || beSpokeGuide.startsWith("http")
            ? beSpokeGuide
            : `${process.env.REACT_APP_AVCWISE_URL + beSpokeGuide}`
        );
      },
    });

  const { loading: linksLoading, error: linksError } = useQuery(
    getPensionFundById,
    {
      variables: {
        id: values.pensionFund,
      },
      onCompleted: (data) => {
        const pensionFunds = data.pension_funds.find((fund) => fund);
        setPrudentialLink(
          pensionFunds.prudential_pension_fund_url ||
            "https://www.pru.co.uk/rz/localgov/"
        );
        setLegalGeneralLink(
          pensionFunds.legal_and_general_fund_guide_link ||
            "https://www.legalandgeneral.com/westsussexavc"
        );
      },
    }
  );

  const [getInvestmentChoicesOptions, { loading, error }] = useLazyQuery(
    getInvestmentOptions,
    {
      onCompleted: (data) => {
        setInvestmentChoicesOptions(
          data.investment_options.map((choice) => ({
            name: choice.display_name,
            value: choice.id,
            lifestyle: choice.lifestyle,
          }))
        );

        if (
          !(
            data.investment_options_pension_funds.length &&
            data.investment_options.length
          )
        ) {
          return;
        }

        const defaultPensionFund = data.investment_options_pension_funds.find(
          (option) => option
        );

        const isDefaultOptionAvailable = data.investment_options.find(
          (option) => option.id === defaultPensionFund?.investment_option?.id
        );

        if (!isDefaultOptionAvailable) {
          return;
        }

        setDefaultInvestmentOption(defaultPensionFund?.investment_option);

        if (values.investmentChoices.length) {
          return;
        }

        if (defaultPensionFund?.investment_option?.lifestyle) {
          setFieldValue("listCheckbox", false);
          setFieldValue("lifestyleCheckbox", true);
          setFieldValue("investmentChoices", [
            {
              name: defaultPensionFund.investment_option.id,
              percentage: 100,
            },
          ]);
          return;
        }

        setFieldValue("investmentChoices", [
          isDefaultOptionAvailable
            ? {
                name: defaultPensionFund?.investment_option.id,
                percentage: 100,
              }
            : { name: "", percentage: "" },
        ]);
      },
      onError: (error) => {
        console.log(error);
        setSaveError(error);
        setErrorToast(true);
      },
    }
  );

  const calculateSum = () => {
    return values.investmentChoices.reduce(
      (accumulator, currentValue) =>
        accumulator + Number(currentValue.percentage),
      0
    );
  };

  useEffect(() => {
    if (values.pensionFund && values.provider) {
      getInvestmentChoicesOptions({
        variables: {
          pensionId: values.pensionFund,
          providerId: values.provider,
        },
      });
    }
  }, [values.pensionFund, values.provider]);

  useEffect(() => {
    setProviderName(
      providers.find((provider) => provider.value === values.provider)?.name
    );
  }, [values, providers]);

  useEffect(() => {
    getBeSpokeLink();
  }, []);

  const queryErrors = useMemo(
    () => error || linksError || beSpokeError,
    [error, linksError, beSpokeError]
  );

  const queryLoaders = useMemo(
    () => loading || linksLoading || beSpokeLoading,
    [loading, linksLoading, beSpokeLoading]
  );

  const contributedAvcAmount = useMemo(() => {
    if (!formValues.additional_avc_amount) {
      return formValues.avcAmount;
    }

    return Number(formValues.avcAmount) + formValues.additional_avc_amount;
  }, [formValues.avcAmount]);

  useEffect(() => {
    if (!values.retirementAge && inputRef.current) {
      inputRef.current.value = "";
    }
  }, [values.retirementAge]);

  const filteredInvestmentOptions = useMemo(
    () => [
      ...investmentChoicesOptions.filter(
        (option) => option.lifestyle === values.lifestyleCheckbox
      ),
    ],
    [investmentChoicesOptions, values.lifestyleCheckbox]
  );

  useEffect(() => {
    if (
      form?.ningi_journey?.journey_status ===
        NINGI_JOURNEY_STATUS.COMPLETED_NINGI_JOURNEY &&
      form?.investmentChoices?.length &&
      form?.temp_plan_investments?.every(
        (option) => !option.investment_option.lifestyle
      ) &&
      !values.investmentChoices.length &&
      !values.lifestyleCheckbox
    ) {
      setFieldValue("investmentChoices", form?.investmentChoices);
    } else if (!values.investmentChoices.length) {
      if (defaultInvestmentOptions?.lifestyle && values.lifestyleCheckbox) {
        setFieldValue("investmentChoices", [
          {
            name: defaultInvestmentOptions.id,
            percentage: 100,
          },
        ]);
        return;
      } else if (
        defaultInvestmentOptions &&
        !defaultInvestmentOptions.lifestyle &&
        !values.lifestyleCheckbox
      ) {
        setFieldValue("investmentChoices", [
          {
            name: defaultInvestmentOptions.id,
            percentage: 100,
          },
        ]);
        return;
      }

      if (filteredInvestmentOptions.length) {
        setFieldValue("investmentChoices", [{ name: "", percentage: "" }]);
      } else {
        setFieldValue("investmentChoices", []);
      }
    }
  }, [values.lifestyleCheckbox, filteredInvestmentOptions]);

  return (
    <Box className="investment-choices-step-2-provider">
      {queryLoaders ? (
        <Box className="mt-30">
          <Loader />
        </Box>
      ) : (
        <>
          <Box>
            {queryErrors &&
              showToast(queryErrors.message, open, setOpen, "error")}
            {providerName === "Clerical Medical" && <ClericalOptions />}

            {providerName === "Prudential" && <PreviousAvcAndPensionsInfo />}

            <Typography className="imp-text stepper-content mt-30">
              <strong>
                IMPORTANT : Some important information you must read:
              </strong>
            </Typography>

            <Accordion
              expanded={expanded}
              setExpanded={setExpanded}
              summary={`You now need to select how your ${formSharedTitle} fund is invested by
          your provider, ${providerName}.`}
            >
              <AccordionDetails className="accordion-details">
                <ProviderDetail
                  providerName={providerName}
                  prudentialLink={prudentialLink}
                  legalGeneralLink={legalGeneralLink}
                  beSpokeLink={beSpokeLink}
                />
                <Typography className="stepper-content mt-18">
                  In all cases we recommend that you consult an Independent
                  Financial Advisor to discuss your personal financial
                  circumstances. Please note that neither your employer nor AVC
                  Wise can offer financial advice of any kind.
                </Typography>

                <Typography className="stepper-content mt-18">
                  Please note that you are responsible for the decisions you
                  have recorded on this form.
                </Typography>
                <ProviderNote providerName={providerName} />
                {providerName === "Clerical Medical" ||
                providerName === "Legal and General" ? (
                  <FormCheckbox
                    name="RnUCheckbox"
                    label={`I check the box to confirm that I know how my funds will be invested, and select relevant option from the list of Investment funds.`}
                    trackingDetails={TRACKING_NAF_OPTIONS}
                  />
                ) : (
                  <FormCheckbox
                    name="RnUCheckbox"
                    label={`By ticking this box, I can confirm that I have read and understand how my ${formSharedTitle} fund is invested by my provider, ${providerName}.`}
                    trackingDetails={TRACKING_NAF_OPTIONS}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box className="mt-30">
            <Typography className="stepper-content">
              <strong>Would you like to..</strong>
            </Typography>
            <Box className="mt-18">
              <CheckboxGroup
                checkboxes={investmentChoicesCheckboxes}
                trackingDetails={TRACKING_NAF_OPTIONS}
                handleCustomChange={(_, _1, setFieldValue) => {
                  resetInvestmentChoices(null, null, setFieldValue);
                  handleNingiChange();
                }}
              />
            </Box>
          </Box>

          <InvestmentChoicesStep2Options
            investmentChoicesOptions={filteredInvestmentOptions}
            handleNingiChange={handleNingiChange}
          />
          <Typography className="mt-18 stepper-content">
            <strong>TOTAL ALLOCATION (MUST BE EXACTLY 100%):</strong>
          </Typography>
          <Stack direction="row" spacing={2} className="mt-18">
            <TextField
              variant="outlined"
              className="textbox"
              name="totalPercent"
              value={calculateSum()}
              sx={{ width: { xs: "50%", sm: "30%" } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PercentIcon className="input-field-icon" />
                  </InputAdornment>
                ),
                readOnly: true,
              }}
              error={errors.totalPercent}
            />

            <TextField
              variant="outlined"
              className="textbox"
              sx={{ width: { xs: "50%", sm: "30%" } }}
              value={fixedAmountToDecimal(
                (contributedAvcAmount / 100) * calculateSum()
              )}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CurrencyPoundIcon className="input-field-icon" />
                  </InputAdornment>
                ),
                readOnly: true,
              }}
            />
          </Stack>
          {!errors.totalPercent && (
            <Typography className="primary-text mt-18">
              *Please ensure that your selected investment options add up to
              100% in total.
            </Typography>
          )}
          <FormHelperText error={errors.totalPercent}>
            {errors.totalPercent}
          </FormHelperText>
          {values.investmentChoices && values.investmentChoices.length ? (
            <>
              <Typography className="primary-text stepper-content mt-18">
                <strong>Here are your chosen investments</strong>
              </Typography>
              {/* chosen investments */}
              <ChosenInvestmentTable
                values={values}
                investmentOptions={investmentChoicesOptions}
              />
            </>
          ) : (
            ""
          )}
          <Typography className="mt-30">
            <strong>And finally….</strong>
          </Typography>
          <Stack direction="row" spacing={1} className="mt-18">
            <Typography className="stepper-content">
              <strong>
                What age do you plan to take your {formSharedTitle}/
                {pensionType}
                {""} benefits?
              </strong>
            </Typography>
            <Tooltip title="Age should be between 55 and 75">
              <HelpOutlineIcon />
            </Tooltip>
          </Stack>

          <TextInput
            id="retirementAge"
            name="retirementAge"
            label="Retirement Age *"
            value={values.retirementAge}
            trackingDetails={TRACKING_NAF_OPTIONS}
            handleChangeValue={handleChange}
            handleBlurValue={handleBlur}
            type="number"
            styleClass="stepper-field"
          />
        </>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    formSharedTitle: state.multiForm.formSharedTitle,
    pensionType: state.multiForm.pensionType,
    scheme: state.scheme.scheme,
  };
};

InvestmentChoicesStep2Provider.propTypes = {
  expanded: PropTypes.bool,
  setExpanded: PropTypes.func,
  form: PropTypes.object,
  scheme: PropTypes.object,
  providers: PropTypes.array,
  handleNingiChange: PropTypes.func,
  resetInvestmentChoices: PropTypes.func,
  formSharedTitle: PropTypes.string,
  pensionType: PropTypes.string,
};

export default connect(mapStateToProps)(InvestmentChoicesStep2Provider);

import { Box, Container, Stack, Typography } from "@mui/material";
import isEmpty from "lodash.isempty";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { checkActiveSchemes } from "../../../../helpers";
import useStepper from "../../../../hooks/useStepper";
import SharedHeader from "../../../generic-components/shared-header/SharedHeader";

const SchemeNotFound = () => {
  const { personalDetails } = useStepper();
  const navigate = useNavigate();

  const checkRequiredActiveSchemes = (personalDetails) => {
    const requiredActiveScheme = checkActiveSchemes(personalDetails);

    if (requiredActiveScheme) {
      navigate("/home");
    }
  };

  useEffect(() => {
    if (!isEmpty(personalDetails)) {
      checkRequiredActiveSchemes(personalDetails);
    }
  }, [personalDetails]);

  return (
    <Box className="application-page-container mt-150">
      <SharedHeader heading="Scheme not found" />
      <Container className="mt-200">
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Typography className="large-heading primary-text">
            <strong>
              There is no scheme for your organisation launched yet.
            </strong>
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
};

export default SchemeNotFound;

import { Box, Dialog, DialogTitle, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "../../../../generic-components/icon-btn";
import { TRACKING_HOME_OPTIONS } from "../../../../../constants";
import LoadingButton from "../../../../generic-components/button";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import useStepper from "../../../../../hooks/useStepper";

const StandardAVCDialog = ({
  open,
  onClose,
  standardAVCScheme,
  isAlreadyApplySAVC,
  organisation,
  user,
}) => {
  const navigate = useNavigate();
  const { setSaveError, setErrorToast } = useStepper();

  const handleRedirection = (url, navigation) => {
    navigation ? navigate(url) : (window.location.href = url);
  };

  const handleApplyStandardAVC = () => {
    if (isAlreadyApplySAVC) {
      setSaveError({
        message:
          "You have already submitted the request. You will be contacted by our Customer Service team within 1 working day.",
        overRideCustom: true,
      });
      setErrorToast(true);
      return;
    }
    handleRedirection(standardAVCURL, false);
  };

  const isNavigation = organisation?.new_form;
  const standardAVCURL = `${process.env.REACT_APP_AVCWISE_URL}/users/${user?.id}/new_cs_application?scheme_id=${standardAVCScheme?.scheme_id}`;
  const sharedCostUrl = isNavigation
    ? "/new-application-form"
    : `${process.env.REACT_APP_AVCWISE_URL}/users/${user?.id}/new_scavc_application`;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="event-confirmation-dialog"
    >
      <DialogTitle className="dialog-header">
        <Typography className="dialog-heading">
          Benefits of a Shared Cost AVC vs. a Standard AVC
        </Typography>
        <IconButton
          trackingDetails={TRACKING_HOME_OPTIONS}
          extraStyles={{
            position: "absolute",
            right: 0,
            top: 20,
            color: (theme) => theme.palette.grey[500],
          }}
          handleClick={onClose}
          icon={<CloseIcon />}
        />
      </DialogTitle>
      <Box className="p-lr-30">
        <Box>
          <Typography className="mt-18">
            You are about to start an application for a Standard AVC.
          </Typography>
          <Typography className="mt-18">
            A Standard AVC will only achieve savings in Income Tax, rather than
            the available savings of Income Tax as well as National Insurance
            Contributions via a Shared Cost AVC.
          </Typography>
          <Typography className="mt-18">
            <strong>Based on a basic rate taxpayer:</strong>
          </Typography>
          <Typography className="mt-18">
            Standard AVC: £100 costs you £80
          </Typography>
          <Typography>Shared Cost AVC: £100 costs you £68.12</Typography>
          <Typography className="mt-18">
            Are you sure you want to make a Standard AVC application?
          </Typography>
        </Box>
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={1}
          className="mt-30 mb-18"
        >
          <LoadingButton
            buttonTitle="Make a Shared Cost AVC application"
            trackingDetails={TRACKING_HOME_OPTIONS}
            styleClass="btn primary-clr-btn largebtn"
            handleClick={() => handleRedirection(sharedCostUrl, isNavigation)}
          />
          <LoadingButton
            buttonTitle="Make standard AVC application"
            trackingDetails={TRACKING_HOME_OPTIONS}
            styleClass="btn largebtn primary-text"
            handleClick={handleApplyStandardAVC}
          />
        </Stack>
      </Box>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    organisation: state?.organisation?.organisation,
    user: state?.user?.user,
  };
};

StandardAVCDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  standardAVCScheme: PropTypes.object,
  isAlreadyApplySAVC: PropTypes.bool,
  organisation: PropTypes.object,
  user: PropTypes.object,
};

export default connect(mapStateToProps)(StandardAVCDialog);

import { Box, Container, Grid, Typography, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import endPageImg from "../../../assets/images/EndPage.png";
import tick from "../../../assets/images/tick-thankyou.png";
import PropTypes from "prop-types";
import { setUser } from "../../../reducers/userReducer";
import useStepper from "../../../hooks/useStepper";
import usePlan from "../../../hooks/usePlan";
import LoadingButton from "../../generic-components/button";
import { TRACKING_NAF_OPTIONS } from "../../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import { resetForm } from "../../../reducers/formReducer";
import { resetTempPlan } from "../../../reducers/tempPlan";
import { isNHSApplication } from "../../../reducers/multiFormReducer";

const ThankyouPage = ({ formSharedTitle, isNHS, user, scheme }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setLatestActivePlan, setPlans } = usePlan();
  const { state } = useLocation();

  const {
    setIsLoadingNext,
    setIsLoadingSave,
    setPersonalDetails,
    personalDetails,
    setInitialValues,
  } = useStepper();

  const updatePersonalDetails = () => {
    if (state?.id) {
      setPlans([]);
      setLatestActivePlan(state.id);
      setPersonalDetails({
        ...personalDetails,
        latest_active_plan: state.id,
        latest_temp_plan: null,
      });
      dispatch(resetForm());
      dispatch(resetTempPlan());
      setInitialValues();
      dispatch(
        setUser({ latest_active_plan: state.id, latest_temp_plan: null })
      );
    }
    setIsLoadingNext(false);
    setIsLoadingSave(false);
  };

  useEffect(() => {
    updatePersonalDetails();
  }, []);

  const feedbackForm =
    "https://forms.microsoft.com/Pages/ResponsePage.aspx?id=QTy2QGFewUG2guXOAMlS3oxWKFv_lk1LsLWn_qfpyTBUOE82TUNRUlUwU1hHSU1SWVpWWUhNQzc4Qi4u";

  const RedirectToFeedbackform = () => window.open(feedbackForm, "_blank");

  const redirectToManageMySharedCost = () => {
    isNHS
      ? (window.location.href = `${process.env.REACT_APP_AVCWISE_URL}/users/${user.id}/plans?scheme_id=${scheme?.scheme_id}`)
      : navigate("/manage_my_shared_cost_avc");
  };

  return (
    <>
      <Box
        className="thankyou-page-container"
        sx={{ backgroundImage: `url(${endPageImg})` }}
      >
        <Container>
          <Grid container>
            <Grid item xs={12} className="content-wrapper">
              <Grid item xs={12} sm={10} md={7} sx={{ margin: "0 auto" }}>
                <Box className="content-container mt-30">
                  <div className="img-container">
                    <img className="logo-dark" src={tick} alt="logo-dark" />
                  </div>
                  <Typography className="head-content content mt-30">
                    Thank you for your application!
                  </Typography>
                  <Typography className="content mt-30">
                    This is now being processed by your employer and you will
                    receive an email notification confirming your application
                    and next steps. You can find the status of your application
                    in the 'Manage My {formSharedTitle}' section once signed in.
                  </Typography>
                  <Typography className="content mt-18">
                    We would love to hear your feedback on the application
                    process. Please click the 'Give us your feedback' button
                    below to complete our short survey.
                  </Typography>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={2}
                    className="mt-30"
                  >
                    <LoadingButton
                      buttonTitle={`Manage My ${formSharedTitle}`}
                      trackingDetails={TRACKING_NAF_OPTIONS}
                      handleClick={redirectToManageMySharedCost}
                      styleClass="contained-btn thankyou-btn"
                    />

                    <LoadingButton
                      buttonTitle="Give us your feedback"
                      trackingDetails={TRACKING_NAF_OPTIONS}
                      handleClick={RedirectToFeedbackform}
                      styleClass="outlined-btn thankyou-btn"
                    />
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state?.user?.user,
    scheme: state?.scheme?.scheme,
    formSharedTitle: state.multiForm.formSharedTitle,
    isNHS: isNHSApplication(state),
  };
};

ThankyouPage.propTypes = {
  formSharedTitle: PropTypes.string,
  user: PropTypes.object,
  scheme: PropTypes.object,
  isNHS: PropTypes.bool,
};

export default connect(mapStateToProps)(ThankyouPage);

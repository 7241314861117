import { Typography } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const ProviderDetail = ({
  providerName,
  prudentialLink,
  legalGeneralLink,
  beSpokeLink,
  formSharedTitle,
}) => {
  const { values } = useFormikContext();
  const getProviderDetail = () => {
    switch (providerName) {
      case "Prudential":
        return (
          <>
            {values.lifestyleCheckbox ? (
              <Typography className="stepper-content mt-18">
                If you know the name of a specific Lifestyling Option available
                to your Employer, you can type the profile name/number here.
              </Typography>
            ) : (
              <Typography className="stepper-content mt-18">
                Please choose the funds to which contributions are to be
                invested and specify the percentage of your total
                {""} {formSharedTitle} amount for each one below.
              </Typography>
            )}
            <Typography className="stepper-content mt-18">
              For further information about the investment choices you must read
              the Prudential fund guide available on this page:{" "}
              <a
                href={prudentialLink}
                target="_blank"
                className="fund-link"
                rel="noreferrer"
              >
                Download Fund Guide
              </a>
            </Typography>
            <Typography className="stepper-content mt-18">
              You should consider and choose the funds to suit your needs.
            </Typography>
            {values.lifestyleCheckbox ? (
              <Typography className="stepper-content mt-18">
                If a lifestyle option is selected, you cannot select any
                additional investment options.
              </Typography>
            ) : (
              <Typography className="stepper-content mt-18">
                If you do not complete this section the default fund will be
                selected.
              </Typography>
            )}
            <Typography className="stepper-content mt-18">
              If you need further help with your investment fund options, you
              can telephone Prudential’s dedicated helpline on 0345 600 0343.{" "}
              <span className="stepper-content mt-18">
                This telephone line is open Monday – Friday, 8.30am – 6.00pm.
              </span>
            </Typography>
          </>
        );

      case "Standard Life":
        return (
          <>
            <Typography className="stepper-content mt-18">
              Please choose the funds to which contributions are to be invested
              and the percentage of your total {formSharedTitle} amount for each
              one below.
            </Typography>
            <Typography className="stepper-content mt-18">
              For further information about the investment choices you must read
              the Standard Life guide available here:{" "}
              <a
                href="http://library.standardlife.com/gpen4.pdf"
                target="_blank"
                className="web-link"
                download
                rel="noreferrer"
              >
                http://library.standardlife.com/gpen4.pdf
              </a>
              {beSpokeLink && (
                <span>
                  {" "}
                  and{" "}
                  <a
                    href={beSpokeLink}
                    target="_blank"
                    className="fund-link"
                    rel="noreferrer"
                  >
                    lifestyle guide.
                  </a>
                </span>
              )}
            </Typography>
            <Typography className="stepper-content mt-18">
              You should consider and choose the funds to suit your needs.
            </Typography>
            <Typography className="stepper-content mt-18">
              If you do not complete this section or you choose not to make an
              active selection at this time, contributions will be invested in
              the Default Fund, if a Default Fund is in place.
            </Typography>
            <Typography className="stepper-content mt-18">
              If you need further help with your investment fund options, you
              can telephone Standard Life’s direct dedicated helpline on 0345 60
              60 047. The telephone line is open between 9am and 5pm.
            </Typography>
          </>
        );
      case "Clerical Medical":
        return (
          <>
            <Typography className="stepper-content mt-18">
              Please choose the funds to which contributions are to be invested
              and the percentage of your total {formSharedTitle} amount for each
              one below.
            </Typography>
            <Typography className="stepper-content mt-18">
              For further information about the investment choices you can read
              the following Clerical Medical guide:{" "}
              <a
                href={`${process.env.REACT_APP_AVCWISE_URL}/fund_guides/Clerical%20Medical%20Fund%20Booklet.pdf`}
                target="_blank"
                download
                className="fund-link"
                rel="noreferrer"
              >
                Investor’s Guide - Clerical Medical Group Pension Funds
              </a>
            </Typography>
            <Typography className="stepper-content mt-18">
              You should consider and choose the funds to suit your needs.
            </Typography>
            <Typography className="stepper-content mt-18">
              If you do not complete this section or you choose not to make an
              active selection at this time, contributions will be invested in
              the Default Fund, if a Default Fund is in place.
            </Typography>
            <Typography className="stepper-content mt-18">
              If you need further help with your investment fund options, you
              can telephone Clerical Medical’s direct helpline on 0345 603 6770
              during the following times: Monday to Friday 8.30am to 6pm.
            </Typography>
          </>
        );
      case "Legal & General":
        return (
          <>
            <Typography className="stepper-content mt-18">
              Please choose the funds to which contributions are to be invested
              and the percentage of your total {formSharedTitle} amount for each
              one below.
            </Typography>
            <Typography className="stepper-content mt-18">
              For further information about the investment choices you must read
              the Legal & General fund guide available on this page:{" "}
              <a
                href={legalGeneralLink}
                className="web-link"
                target="_blank"
                rel="noreferrer"
              >
                {legalGeneralLink}
              </a>
            </Typography>
            <Typography className="stepper-content mt-18">
              You should consider and choose the funds to suit your needs.
            </Typography>
            <Typography className="stepper-content mt-18">
              If you do not complete this section or you choose not to make an
              active selection at this time, contributions will be invested in
              the Default Fund, if a Default Fund is in place.
            </Typography>
            <Typography className="stepper-content mt-18">
              If you need further help with your investment fund options, you
              can telephone Legal & General on 0345 0708686. The telephone line
              is open between 9am and 9pm Monday – Friday and 7am and 12pm
              Saturday.
            </Typography>
          </>
        );
    }
  };
  return getProviderDetail();
};

const mapStateToProps = (state) => {
  return {
    formSharedTitle: state.multiForm.formSharedTitle,
  };
};

ProviderDetail.propTypes = {
  providerName: PropTypes.string,
  prudentialLink: PropTypes.string,
  legalGeneralLink: PropTypes.string,
  beSpokeLink: PropTypes.string,
  formSharedTitle: PropTypes.string,
};

export default connect(mapStateToProps)(ProviderDetail);

import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/system";
import PropTypes from "prop-types";

const Loader = ({ className, size }) => {
  return (
    <Box className="loader-container">
      <CircularProgress className={`loader ${className} `} size={size} />
    </Box>
  );
};

export default Loader;
Loader.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};

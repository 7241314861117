import {
  Box,
  Container,
  Stack,
  TablePagination,
  Typography,
} from "@mui/material";
import { useState, React, useEffect } from "react";
import EventStamp from "./EventStamp";
import EventFilterButton from "./EventFilterButton";
import EventFilterDialog from "./EventFilterDialog";
import { useLazyQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getDistinctEvents } from "../../../../../graphql/queries/events";
import Loader from "../../../../generic-components/loader";
import useStepper from "../../../../../hooks/useStepper";
import moment from "moment";
import { TRACKING_EVENTS_PAGE_OPTIONS } from "../../../../../constants";
import LoadingButton from "../../../../generic-components/button";
import { useNavigate } from "react-router-dom";

const AvailableAndBookedEvents = ({ organisation }) => {
  const { setErrorToast, setSaveError } = useStepper();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState({});
  const [count, setCount] = useState(0);
  const [NoRecordsMessege, setNoRecordsMessege] = useState("No records found");
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(12);
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState([]);

  const [fetchDistinctEvents] = useLazyQuery(getDistinctEvents);

  const transformEventsFilter = (variables) => {
    if (filter?.events) {
      variables.extra_filter.title = { _eq: filter?.events };
    }

    if (filter?.start_date && filter?.end_date) {
      variables.extra_filter.presentation_date = {
        _gte: filter?.start_date,
        _lte: filter?.end_date,
      };
    } else if (filter?.start_date && !filter?.end_date) {
      variables.extra_filter.presentation_date = {
        _gte: filter?.start_date,
      };
    }

    return variables;
  };

  const getEvents = async () => {
    setIsLoading(true);
    let variables = {
      organisation_id: organisation.id,
      limit: rowsPerPage,
      offset: rowsPerPage * (page - 1),
      current_date: moment().format("YYYY-MM-DD"),
      current_time: moment().format("HH:mm:ss"),
      extra_filter: {},
    };

    fetchDistinctEvents({
      variables: transformEventsFilter(variables),
      onCompleted: (data) => {
        setCount(data?.presentations_aggregate?.aggregate?.count || 0);
        setEvents(data?.presentations || []);
        setIsLoading(false);
      },
      onError: (error) => {
        setSaveError(error);
        setErrorToast(true);
        setIsLoading(false);
      },
    });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePageChange = (_, newPage) => {
    setPage(newPage + 1);
  };

  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(+e?.target?.value);
    setPage(1);
  };

  useEffect(() => {
    if (organisation.id) {
      getEvents();
    }
  }, [page, rowsPerPage, organisation.id, filter]);

  return (
    <Container className="mb-50">
      <EventFilterDialog
        open={open}
        filter={filter}
        onClose={handleClose}
        setFilter={setFilter}
        setPage={setPage}
        setNoRecordsMessege={setNoRecordsMessege}
      />
      <Stack
        className="mt-30"
        direction={{ sm: "column", md: "row" }}
        justifyContent="space-between"
        spacing={{ xs: 2, sm: 2, md: 1 }}
      >
        <EventFilterButton
          handleClick={() => (isLoading ? null : handleOpen())}
        />
        <LoadingButton
          buttonTitle="Click here to view your bookings"
          trackingDetails={TRACKING_EVENTS_PAGE_OPTIONS}
          handleClick={() => navigate("/my_bookings")}
          styleClass="btn eventbtn"
        />
      </Stack>
      <Box className="event-box mt-30">
        {isLoading ? (
          <Box className="mt-50">
            <Loader />
          </Box>
        ) : (
          <Stack
            direction="row"
            gap={3}
            justifyContent={events?.length ? "flex-start" : "center"}
            flexWrap="wrap"
          >
            {events?.length ? (
              events.map((event) => (
                <EventStamp
                  key={event?.id}
                  title={event?.title}
                  targetAudience={event?.target_audience}
                />
              ))
            ) : (
              <Typography>
                <strong>{NoRecordsMessege}</strong>
              </Typography>
            )}
          </Stack>
        )}
      </Box>
      <TablePagination
        component="div"
        page={page - 1}
        rowsPerPageOptions={[4, 8, 12]}
        count={count}
        labelRowsPerPage="Records per page"
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    organisation: state?.organisation?.organisation,
  };
};

AvailableAndBookedEvents.propTypes = {
  organisation: PropTypes.object,
};

export default connect(mapStateToProps)(AvailableAndBookedEvents);

import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import useStepper from "../../../../hooks/useStepper";
import SharedHeader from "../../../generic-components/shared-header/SharedHeader";
import Banner from "../banner/Banner";
import AvailableAndBookedEvents from "./event-page-content/AvailableAndBookedEvents";
import EventsPageHeader from "./event-page-content/EventPageHeader";
import IMHeader from "./event-page-content/IMHeader";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { findPageBanner } from "../../../../helpers";

const EventsPage = ({ organisation }) => {
  const { webinarAttendance } = useStepper();
  const [portalBanner, setPortalBanner] = useState(null);

  useEffect(() => {
    if (organisation?.banner?.length) {
      const banner = findPageBanner(organisation?.banner, "Presentations");
      setPortalBanner(banner);
    }
  }, [organisation]);
  return (
    <Box className="application-page-container mt-150">
      <SharedHeader heading="Events" />
      {portalBanner ? <Banner portalBanner={portalBanner} /> : null}
      <EventsPageHeader />
      <AvailableAndBookedEvents />
      {webinarAttendance ? <IMHeader /> : null}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    organisation: state?.organisation?.organisation,
  };
};

EventsPage.propTypes = {
  organisation: PropTypes.object,
};

export default connect(mapStateToProps)(EventsPage);

import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { Formik } from "formik";
import React, { useState } from "react";
import { contributionCalculatorValidationSchema } from "../../../../../schema";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import PropTypes from "prop-types";
import IconInputField from "../../../../generic-components/icon-inputfield";
import {
  TRACKING_NAF_OPTIONS,
  floatRegex,
  TRACKING_NAF_STARTED_OPTIONS,
} from "../../../../../constants";
import FormikCheckbox from "../../../../generic-components/formik-checkbox";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import TextInput from "../../../../generic-components/input-text";
import MaximumContribution from "./MaximumContribution";
import LoadingButton from "../../../../generic-components/button";
import { useLocation, useNavigate } from "react-router-dom";
import useStepper from "../../../../../hooks/useStepper";
import usePlan from "../../../../../hooks/usePlan";
import { connect, useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import { updateSharedCostForm } from "../../../../../graphql/mutations/sharedCostAVCAmount";
import moment from "moment";
import { setForm } from "../../../../../reducers/formReducer";
import { createAvcAmountPersonalDetails } from "../../../../../graphql/mutations/personalDetails";
import useUserEvent from "../../../../../hooks/useUserEvent";
import { setUser } from "../../../../../reducers/userReducer";
import IconButton from "../../../../generic-components/icon-btn";
import CloseIcon from "@mui/icons-material/Close";
import { newApplicationFormPath } from "../../../../../constants/path";
import { isNHSApplication } from "../../../../../reducers/multiFormReducer";
import { checkActiveSchemes } from "../../../../../helpers";

const ContributionCalculator = ({
  initialValues = {
    income: "",
    hours_per_week: "",
    isExistingPlanConversion: false,
    age: "",
    salarySacrifice: "",
    PTCheckbox: false,
  },
  user,
  isNHS,
  scheme,
  handleClose,
  sharedCostValues = {},
  isModalCalculator = false,
  formDetails = {},
  organisation,
  applyButtonTitle = "Apply",
  closeButtonTitle = "Close",
  convertButtonTitle,
  showApplyButton = true,
  showCloseButton = true,
  showConvertButton = false,
  islargeApplyButton = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    setFormValues,
    personalDetails,
    setSaveError,
    setErrorToast,
    setActiveStep,
    setSharedFormIndex,
    setInvestmentFormIndex,
    setSubmitFormIndex,
    setPersonalDetails,
    trackingCode,
  } = useStepper();
  const { isLatestActivePlan } = usePlan();

  const [updateSharedCost] = useMutation(updateSharedCostForm);
  const [createApplication] = useMutation(createAvcAmountPersonalDetails);
  const { userTrackingMutation } = useUserEvent();
  const [convertPlanLoading, setConvertPlanLoading] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { pathname } = useLocation();

  const handleUserEvent = () => {
    userTrackingMutation({
      variables: {
        ...TRACKING_NAF_STARTED_OPTIONS,
        field_name: "Started",
        field_value: "",
        avc_track_code: trackingCode,
      },
    });
  };

  const handleNavigationToApplicationForm = (activeStep) => {
    const isApplicationForm = [
      newApplicationFormPath.NEW_APPLICATION_FORM,
      newApplicationFormPath.NHS_APPLICATION_FORM,
    ].includes(pathname);

    if (!isApplicationForm) {
      const redirectionPath = isNHS
        ? "/nhs-application-form"
        : "/new-application-form";
      navigate(redirectionPath);
      setActiveStep(activeStep);
      setSharedFormIndex(1);
      setInvestmentFormIndex(1);
      setSubmitFormIndex(1);
    }
    setLoading(false);
    handleClose && handleClose();
  };

  const handleSubmitForm = (values) => {
    setLoading(true);
    const requiredActiveScheme = checkActiveSchemes(personalDetails);

    if (!requiredActiveScheme) {
      navigate("/scheme_not_found");
      setLoading(false);
      return;
    }

    if (
      personalDetails.latest_temp_plan &&
      !formDetails.total_avc_amount_requested
    ) {
      updateSharedCost({
        variables: {
          id: formDetails.id,
          updated_at: moment().format(),
          total_avc_amount_requested: values.avcAmount || null,
          annual_salary: parseInt(values.income),
          avc_interval: "Monthly",
          hours_per_week: values.hours_per_week || 0,
          max_contribution_amount: values.avcAmount
            ? parseFloat(values.avcAmount)
            : 0,
          previous_amount_added: values.previous_amount_added || 0,
          contribution_amount_updated:
            values.contribution_amount_updated || false,
          additional_avc_amount: values.additional_avc_amount || 0,
          step_number: 1,
        },
        onCompleted: (data) => {
          dispatch(setForm(data.update_temp_plans.returning[0]));
          setFormValues((prevValues) => ({
            ...prevValues,
            ...sharedCostValues,
            ...values,
            ...data.update_temp_plans.returning[0],
            incomeFrequency: "Monthly",
            max_contribution_amount: values.avcAmount
              ? parseFloat(values.avcAmount)
              : "",
            avcAmount: values.avcAmount ? parseFloat(values.avcAmount) : "",
          }));
          handleNavigationToApplicationForm(1);
        },
        onError: (error) => {
          setSaveError(error);
          setErrorToast(true);
          setLoading(false);
        },
      });
    } else if (
      personalDetails.latest_temp_plan &&
      formDetails.total_avc_amount_requested
    ) {
      handleNavigationToApplicationForm(1);
    } else if (
      !personalDetails.latest_temp_plan &&
      organisation.id &&
      scheme.scheme_type_id &&
      scheme.scheme_id
    ) {
      handleUserEvent();
      createApplication({
        variables: {
          scheme_id: scheme.scheme_id,
          scheme_type_id: scheme.scheme_type_id,
          user_id: personalDetails.id,
          organisation_id: parseInt(organisation.id),
          employee_number: personalDetails.employee_number?.trim(),
          title: personalDetails.title,
          delivery_address1: personalDetails.address1,
          delivery_address2: personalDetails.address2,
          delivery_county: personalDetails.county,
          date_of_birth: personalDetails.date_of_birth || null,
          email: personalDetails.email,
          first_name: personalDetails.first_name,
          last_name: personalDetails.last_name,
          mobile_number: personalDetails.mobile_number,
          ni_number: personalDetails.ni_number,
          delivery_postcode: personalDetails.postcode,
          delivery_town: personalDetails.town,
          telephone_number: personalDetails.mobile_number,
          step_number: 0,
          tracking_code: trackingCode,
          total_avc_amount_requested: values.avcAmount || null,
          annual_salary: parseInt(values.income),
          hours_per_week: values.hours_per_week || 0,
          avc_interval: "Monthly",
          max_contribution_amount: values.avcAmount
            ? parseFloat(values.avcAmount)
            : 0,
          previous_amount_added: values.previous_amount_added || 0,
          contribution_amount_updated:
            values.contribution_amount_updated || false,
          created_at: moment().format(),
          updated_at: moment().format(),
        },
        onCompleted: (data) => {
          setFormValues((prevValues) => ({
            ...prevValues,
            ...values,
            ...data.insert_temp_plans.returning[0],
            incomeFrequency: "Monthly",
            max_contribution_amount: values.avcAmount
              ? parseFloat(values.avcAmount)
              : "",
            avcAmount: values.avcAmount ? parseFloat(values.avcAmount) : "",
          }));
          setPersonalDetails((prevValues) => ({
            ...prevValues,
            latest_temp_plan: data.insert_temp_plans.returning[0].id,
          }));
          dispatch(
            setUser({
              latest_temp_plan: data.insert_temp_plans.returning[0].id,
            })
          );
          dispatch(setForm(data.insert_temp_plans.returning[0]));
          handleNavigationToApplicationForm(0);
        },
        onError: (error) => {
          setSaveError(error);
          setLoading(false);
          setErrorToast(true);
        },
      });
    } else {
      setSaveError({ type: "error" });
      setErrorToast(true);
      setLoading(false);
    }
  };

  return (
    <Box className="contribution-calculator-modal">
      {isModalCalculator ? (
        <IconButton
          trackingDetails={TRACKING_NAF_OPTIONS}
          extraStyles={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          handleClick={handleClose}
          styleClass="call"
          icon={<CloseIcon />}
        />
      ) : null}
      <Typography className="sub-heading">
        NLW Maximum Contribution Calculator
      </Typography>
      <Typography className="stepper-content contact-us mt-18">
        This calculator is only applicable to full-time and part-time salaried
        workers. For any other type of employment, please{" "}
        <a
          href={`${process.env.REACT_APP_AVCWISE_URL}/contact_us`}
          className="web-link"
        >
          Contact Us
        </a>
      </Typography>
      <Formik
        validationSchema={contributionCalculatorValidationSchema}
        initialValues={initialValues}
        onSubmit={(values) => {
          if (isLatestActivePlan) {
            navigate("/amend_my_shared_cost_avc");
            handleClose && handleClose();
            return;
          } else if (values.isExistingPlanConversion) {
            setConvertPlanLoading(true);
            window.location.href = `${
              process.env.REACT_APP_AVCWISE_URL
            }/users/${user.id}/convert_existing_avc?hours=${
              values.hours_per_week
            }&maximum_contribution_value=${
              values.avcAmount ? parseFloat(values.avcAmount) : 0
            }&plan_annual_salary=${parseInt(values.income)}`;
            return;
          }
          handleSubmitForm(values);
        }}
      >
        {({ values, handleBlur, handleSubmit, setFieldValue }) => (
          <form onSubmit={handleSubmit} className="calculator-form">
            <Box className="stepper-field">
              <IconInputField
                id="income"
                name="income"
                label="Annual Salary *"
                trackingDetails={TRACKING_NAF_OPTIONS}
                value={values.income}
                icon={<CurrencyPoundIcon className="input-field-icon" />}
                isValidValue={(e) => {
                  if (
                    e?.target?.value > 0 &&
                    e?.target?.value?.length <= 9 &&
                    floatRegex.test(parseFloat(e?.target?.value))
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                }}
                handleChangeValue={(e, isValidValue) => {
                  if (isValidValue) {
                    setFieldValue("income", e.target.value);
                  } else {
                    setFieldValue("income", "");
                  }
                }}
                handleBlurValue={handleBlur}
              />

              <TextInput
                id="hours_per_week"
                name="hours_per_week"
                label="Working hours per week *"
                trackingDetails={TRACKING_NAF_OPTIONS}
                value={values.hours_per_week}
                isValidValue={(e) => {
                  if (
                    e.target.value > 0 &&
                    e.target.value <= 40 &&
                    floatRegex.test(parseFloat(e.target.value))
                  )
                    return true;
                  else return false;
                }}
                handleChangeValue={(e, isValidValue) => {
                  if (isValidValue)
                    setFieldValue("hours_per_week", e.target.value);
                  else setFieldValue("hours_per_week", "");
                }}
                handleBlurValue={handleBlur}
              />

              <TextInput
                id="age"
                name="age"
                label="Age *"
                trackingDetails={TRACKING_NAF_OPTIONS}
                value={values.age}
                isValidValue={(e) => {
                  if (
                    e.target.value > 0 &&
                    e.target.value <= 120 &&
                    floatRegex.test(parseFloat(e.target.value))
                  )
                    return true;
                  else return false;
                }}
                handleChangeValue={(e, isValidValue) => {
                  if (isValidValue) setFieldValue("age", e?.target?.value);
                  else setFieldValue("age", "");
                }}
                handleBlurValue={handleBlur}
              />

              <IconInputField
                id="salarySacrifice"
                name="salarySacrifice"
                label="My other salary sacrifice (total amount)"
                trackingDetails={TRACKING_NAF_OPTIONS}
                value={values.salarySacrifice}
                icon={<CurrencyPoundIcon className="input-field-icon" />}
                isValidValue={(e) => {
                  if (
                    e?.target?.value > 0 &&
                    e?.target?.value?.length <= 9 &&
                    floatRegex.test(parseFloat(e?.target?.value))
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                }}
                handleChangeValue={(e, isValidValue) => {
                  if (isValidValue) {
                    setFieldValue("salarySacrifice", e?.target?.value);
                  } else {
                    setFieldValue("salarySacrifice", "");
                  }
                }}
                handleBlurValue={handleBlur}
              />

              <Stack direction="row" alignContent="center">
                <FormikCheckbox
                  name="PTCheckbox"
                  label=" Do not fall below Primary Threshold"
                  trackingDetails={TRACKING_NAF_OPTIONS}
                />
                <Tooltip
                  title="Please tick this box to maintain your Primary Threshold for National Insurance Contributions."
                  className="mt-18"
                >
                  <HelpOutlineIcon />
                </Tooltip>
              </Stack>
              <MaximumContribution />
              <Stack
                direction="row"
                justifyContent="center"
                spacing={2}
                className={`${
                  !isModalCalculator ? "d-flex justify-center" : ""
                }`}
              >
                {showApplyButton ? (
                  <LoadingButton
                    type="submit"
                    loading={isLoading}
                    disabled={isLoading || convertPlanLoading}
                    handleClick={() =>
                      setFieldValue("isExistingPlanConversion", false)
                    }
                    buttonTitle={applyButtonTitle}
                    trackingDetails={TRACKING_NAF_OPTIONS}
                    styleClass={`contained-btn btn mt-18 ${
                      islargeApplyButton ? "largebtn" : ""
                    }`}
                  />
                ) : null}
                {isModalCalculator && showConvertButton && (
                  <LoadingButton
                    type="submit"
                    handleClick={() =>
                      setFieldValue("isExistingPlanConversion", true)
                    }
                    loading={convertPlanLoading}
                    disabled={isLoading || convertPlanLoading}
                    buttonTitle={convertButtonTitle}
                    trackingDetails={TRACKING_NAF_OPTIONS}
                    styleClass={"contained-btn btn mt-18"}
                  />
                )}
                {isModalCalculator && showCloseButton && (
                  <LoadingButton
                    buttonTitle={closeButtonTitle}
                    trackingDetails={TRACKING_NAF_OPTIONS}
                    handleClick={handleClose}
                    styleClass="outlined-btn btn mt-18"
                  />
                )}
              </Stack>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    formDetails: state?.form?.form,
    organisation: state?.organisation?.organisation,
    user: state?.user?.user,
    scheme: state?.scheme?.scheme,
    isNHS: isNHSApplication(state),
  };
};

ContributionCalculator.propTypes = {
  sharedCostValues: PropTypes.object,
  isModalCalculator: PropTypes.bool,
  isNHS: PropTypes.bool,
  initialValues: PropTypes.object,
  handleClose: PropTypes.func,
  formDetails: PropTypes.object,
  organisation: PropTypes.object,
  user: PropTypes.object,
  scheme: PropTypes.object,
  applyButtonTitle: PropTypes.string,
  closeButtonTitle: PropTypes.string,
  convertButtonTitle: PropTypes.string,
  showApplyButton: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  showConvertButton: PropTypes.bool,
  islargeApplyButton: PropTypes.bool,
};

export default connect(mapStateToProps)(ContributionCalculator);

import * as yup from "yup";
import {
  passwordRegexp,
  UKMobileRegex,
  NINumberRegex,
  referralCodeRegex,
} from "../constants";
import moment from "moment";
import { convertToNumber, formatCurrency } from "../helpers";
import store from "../store";
import { formType } from "../constants/multiForm";

const currentDate = new Date();

export const emailValidationSchema = yup
  .string()
  .email("Please provide your valid email address")
  .required("Please provide your valid email address.");

const passwordValidationSchema = yup
  .string()
  .matches(
    passwordRegexp,
    "The password must be at least 8 characters in length."
  )
  .required(
    "A password is required, please enter one in the designated field."
  );

const incomeValidationSchema = yup
  .number()
  .required("Please provide the amount in the designated field.")
  .min(1, "Please provide the amount in the designated field.")
  .nullable();

const hoursPerWeekValidationSchema = yup
  .number()
  .required("Please provide the working hours.")
  .min(1, "Please provide minimum 1 in the working hours")
  .max(40, "Maximum allowed working hours are 40.")
  .nullable();

const firstNameValidationSchema = yup
  .string()
  .required("Please provide your first name.")
  .nullable();

const lastNameValidationSchema = yup
  .string()
  .required("Please provide your last name.")
  .nullable();

const dobValidationSchema = yup
  .date()
  .min(
    moment(`01-01-${currentDate.getFullYear() - 100}`, "DD-MM-YYYY"),
    "Please provide a valid date of birth"
  )
  .max(
    moment(`31-12-${currentDate.getFullYear() - 16}`, "DD-MM-YYYY"),
    "Please provide a valid date of birth"
  )
  .required("Date of birth is a mandatory field.")
  .typeError("Invalid date!")
  .nullable();

export const signinValidationSchema = yup.object({
  email: yup
    .string()
    .email(
      "The provided email address does not conform to the standard format for an email address."
    )
    .required("In order to proceed, please enter a valid email address !!"),
  Password: passwordValidationSchema,
});

export const signupValidationSchema = yup.object({
  organisation: yup
    .string()
    .required("Please choose at least one of the provided organisation."),
  firstName: firstNameValidationSchema,
  lastName: lastNameValidationSchema,
  email: emailValidationSchema,
  confirmEmail: yup
    .string()
    .when("email", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: yup
        .string()
        .oneOf(
          [yup.ref("email")],
          "The email addresses entered must match in order to proceed."
        ),
    })
    .required("Please re-enter your email address for confirmation."),
  Password: passwordValidationSchema,
  confirmPassword: yup
    .string()
    .when("Password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: yup
        .string()
        .oneOf(
          [yup.ref("Password")],
          "The passwords entered must match in order to proceed."
        ),
    })
    .required(
      "A password is required, please enter one in the designated field."
    ),
  dob: dobValidationSchema,
  employeeNumber: yup.string().required("Please provide your employee number."),
  platform: yup
    .string()
    .required("Please choose one of the provided organisation."),
  referral: yup
    .string()
    .email(
      "The referral email format is invalid, please check and enter a valid email address."
    )
    .required(
      "Please enter a valid referral email address in the designated field."
    ),
  LGPSCheckbox: yup
    .boolean()
    .oneOf(
      [true],
      "Acceptance of the terms and conditions is required to proceed."
    ),
  TnCCheckbox: yup
    .boolean()
    .oneOf(
      [true],
      "Acceptance of the terms and conditions is required to proceed."
    ),
});

export const personalDetailsValidationSchema = yup.object({
  ni_number: yup
    .string()
    .required("Please provide your national insurance number.")
    .min(9, "A national insurance number must be at least 9 letters in length.")
    .matches(NINumberRegex, "Please provide a valid national insurance number")
    .nullable(),
  employee_number: yup
    .string()
    .required("Please provide your employee number.")
    .test({
      message: () => "The employee number is required.",
      test: (value) => !!value?.trim().length,
    })
    .nullable(),
  title: yup
    .string()
    .required("Please choose at least one of the provided title.")
    .nullable(),
  first_name: firstNameValidationSchema,
  last_name: lastNameValidationSchema,
  date_of_birth: dobValidationSchema,
  mobile_number: yup
    .string()
    .required("Please provide your phone number.")
    .test(
      "Please provide your valid phone number.",
      "Please provide your valid phone number.",
      (val) => val?.replace(/ /g, "").length === 11
    )
    .matches(UKMobileRegex, "Please provide your valid phone number.")
    .nullable(),
  email: emailValidationSchema,
  address1: yup
    .string()
    .required("Please provide your home address#1.")
    .nullable(),
  town: yup.string().required("Please provide your town name.").nullable(),
  county: yup.string().required("Please provide your county name.").nullable(),
  postcode: yup.string().required("Please provide your post code.").nullable(),
});

const formSharedTitle = () => {
  let formTitle = store.getState().multiForm.formSharedTitle;
  return formTitle;
};

const avcAmountSchema = yup
  .number()
  .required("Please provide the amount in the designated field.")
  .test(
    `Please ensure that your ${formSharedTitle()} amount must not be greater than or equal to your income.`,
    `Please ensure that your ${formSharedTitle()} amount must not be greater than or equal to your income.`,
    function () {
      if (!this || !this.parent) return true;

      const { parent } = this;

      if (parent.avcAmount >= parent.income) {
        return false;
      } else return true;
    }
  )
  .min(
    2,
    `Your ${formSharedTitle()} amount requested should be greater than or equal to £2.`
  )
  .test(
    `Your ${formSharedTitle()} amount requested cannot be greater than £2`,
    (value, scheme) => {
      if (!value) return true;

      if (!Number(scheme?.parent?.max_contribution_amount)) {
        if (value === 2) {
          return true;
        }

        return scheme.createError({
          path: scheme.path,
          message: `Your ${formSharedTitle()} amount requested cannot be greater than £2`,
        });
      }

      if (value <= Number(scheme?.parent?.max_contribution_amount)) {
        return true;
      }

      return scheme.createError({
        path: scheme.path,
        message: `Your ${formSharedTitle()} amount requested cannot be greater than ${formatCurrency(
          scheme?.parent?.max_contribution_amount
        )}`,
      });
    }
  )
  .nullable();

export const sharedCostFormValidationSchema = () => {
  return yup.object({
    income: yup
      .number()
      .required("Please provide the amount in the designated field.")
      .min(1, "Please provide the amount in the designated field.")
      .nullable(),
    hours_per_week: yup
      .number()
      .required("Please provide the working hours.")
      .min(1, "Please provide minimum 1 in the working hours")
      .max(40, "Maximum allowed working hours are 40.")
      .nullable(),
    avcAmount: avcAmountSchema,
    referral_code: yup
      .string()
      .matches(
        referralCodeRegex,
        "Referral code must be 10 characters long. i.e LGR-123456"
      )
      .when("avcAmount", {
        is: (avcAmount) => avcAmount < 50,
        then: yup.string().test({
          message: () => "Referral code not valid",
          test: (value) => {
            if (!value?.trim || !referralCodeRegex.test(value?.trim())) {
              return true;
            }
            return false;
          },
        }),
      })
      .nullable(),
    incomeFrequency: yup
      .string()
      .required("Please choose one of the provided options.")
      .nullable(),
  });
};

export const contributionCalculatorValidationSchema = yup.object({
  income: yup
    .number()
    .required("Please provide the amount in the designated field."),
  hours_per_week: yup.number().required("Please provide your working hours."),
  age: yup.number().required("Please provide your age."),
});

export const confirmCheckboxValidationSchema = yup.object({
  confirmCheckbox: yup.boolean().when("showIncompleteJourneyDialog", {
    is: false,
    then: yup
      .boolean()
      .oneOf([true], "You must click checkbox first.")
      .nullable(),
    otherwise: yup.boolean(),
  }),
});

const pensionFund = yup
  .string()
  .required("Please choose one of the provided pension funds.")
  .nullable();

const pensionAgeSchema = yup
  .string()
  .nullable()
  .test({
    message: () => "Please provide the pension age.",
    test: (value, scheme) => {
      const isNHSApplication = scheme?.parent?.isNHSApplication;
      if (!value && isNHSApplication && scheme?.parent?.provider === "1") {
        return false;
      }
      return true;
    },
  })
  .nullable();

const planReasonValidationSchema = yup
  .number()
  .nullable()
  .when("isPlanReasons", {
    is: (isPlanReasons) => isPlanReasons,
    then: yup.number().required("Please provide the plan reason.").nullable(),
  })
  .required("Please provide reason of your plan.");

const otherReasonValidationSchema = yup.string().when("planReason", {
  is: (planReason) => planReason === -1,
  then: yup.string().required("Please provide the other reason.").nullable(),
});

const pensionSchemeBenefitsSchema = yup.boolean().test({
  message: () =>
    "Acceptance of the NHS pension scheme benefits is required to proceed.",
  test: (value) => {
    const isNHSApplication =
      store?.getState()?.multiForm.formType === formType.NHS_APPLICATION_FORM
        ? true
        : false;
    if (!value && isNHSApplication) {
      return false;
    }
    return true;
  },
});

export const pensionFundSchema = yup.object().shape({
  pensionFund: pensionFund,
});

const planCancellationReasonValidationSchema = yup
  .string()
  .nullable()
  .when("isCancellationReasons", {
    is: (isCancellationReasons) => isCancellationReasons,
    then: yup
      .string()
      .required("Please provide the plan cancellation reason.")
      .nullable(),
  })
  .required("Please provide the reason of your plan cancellation.");

const otherCancellationReasonValidationSchema = yup
  .string()
  .when("cancellationReason", {
    is: (cancellationReason) => cancellationReason === "other_reason",
    then: yup.string().required("Please provide the other reason.").nullable(),
  });

const otherOrganisationValidationSchema = yup
  .string()
  .when("cancellationReason", {
    is: (cancellationReason) =>
      cancellationReason === "leaving_or_left_employment",
    then: yup.string().required("Please choose the option.").nullable(),
  });

const otherOrganisationNameValidationSchema = yup
  .string()
  .when("isMovingToAnotherOrganisation", {
    is: (isMovingToAnotherOrganisation) =>
      isMovingToAnotherOrganisation === "yes",
    then: yup
      .string()
      .required("Please provide the organisation name.")
      .nullable(),
  });

const planAmendmentReasonValidationSchema = yup
  .string()
  .nullable()
  .when("isAmendmentReason", {
    is: (isAmendmentReason) => isAmendmentReason,
    then: yup
      .string()
      .required("Please provide the plan amendment reason.")
      .nullable(),
  })
  .required("Please provide the reason of your plan amendment.");

const otherAmendmentReasonValidationSchema = yup
  .string()
  .when("amendmentReason", {
    is: (amendmentReason) => amendmentReason === "Other – please state below",
    then: yup.string().required("Please provide the other reason.").nullable(),
  });

export const InvestmentChoicesStep2ValidationSchema = yup.object().shape({
  pensionFund: pensionFund,
  provider: yup
    .string()
    .required("Please choose one of the provided providers.")
    .nullable(),
  RnUCheckbox: yup
    .boolean()
    .oneOf(
      [true],
      "Acceptance of the terms and conditions is required to proceed."
    )
    .nullable(),
  totalPercent: yup
    .number()
    .test(
      "Please ensure that your selected investment options add up to 100% in total.",
      "Please ensure that your selected investment options add up to 100% in total.",
      function () {
        if (!this || !this.parent) return true;

        const { parent } = this;

        const sum = parent.investmentChoices.reduce(
          (accumulator, currentValue) =>
            accumulator + Number(currentValue.percentage),
          0
        );
        if (sum === 100) {
          return true;
        } else return false;
      }
    ),
  investmentChoices: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .required("Please Choose a investment Choices.")
          .test(
            "You have already choosed this investment choice.",
            "You have already choosed this investment choice.",
            function () {
              if (!this || !this.parent || this.from.length < 1) return true;

              const { from, parent, path } = this;

              const index = convertToNumber(path);

              const { investmentChoices } = from[1].value;

              if (investmentChoices.length === 1) {
                return true;
              }

              return !investmentChoices.some((item, itemIndex) => {
                if (index === itemIndex) {
                  return false;
                }

                return item.name == parent.name;
              });
            }
          ),
        percentage: yup
          .number()
          .required("Please provide the percentage.")
          .min(1, "Percentage cannot be zero.")
          .max(100, "Percentage cannot be greater than 100.")
          .nullable(),
      })
    )
    .required(),
  retirementAge: yup
    .number()
    .required("Please provide the retirement age.")
    .integer("Please provide a valid retirement age.")
    .min(55, "Please provide age between 55 and 75.")
    .max(75, "Please provide age between 55 and 75.")
    .nullable(),
  isNHSApplication: yup.boolean(),
  pensionAge: pensionAgeSchema,
  MPAAdate: yup.date().when("triggeredTheMpaa", {
    is: true,
    then: yup
      .date()
      .min(
        moment("01-01-2015", "DD-MM-YYYY"),
        `Please provide date between 01-01-2015 to ${moment().format(
          "DD-MM-YYYY"
        )}`
      )
      .max(
        moment(),
        `Please provide date between 01-01-2015 to ${moment().format(
          "DD-MM-YYYY"
        )}`
      )
      .required("Please provide MPAA date.")
      .typeError("Invalid date!")
      .nullable(),
    otherwise: yup.date(),
  }),
  flexiblyAccessedDate: yup.date().when("accessedPensionBtn", {
    is: true,
    then: yup
      .date()
      .required("Please provide first payment date.")
      .typeError("Invalid date!")
      .nullable(),
    otherwise: yup.date(),
  }),
});

export const AcknowledgementAndSignatureValidationSchema = yup.object({
  termCondition: yup
    .boolean()
    .oneOf(
      [true],
      "Acceptance of the terms and conditions is required to proceed."
    )
    .nullable(),
  prudentialConsent: yup
    .boolean()
    .oneOf(
      [true],
      "Acceptance of the prudential consent is required to proceed."
    )
    .nullable(),
  pensionSchemeBenefits: pensionSchemeBenefitsSchema,
  signature: yup.string().required("Please provide your signature.").nullable(),
  isPlanReasons: yup.boolean(),
  planReason: planReasonValidationSchema,
  otherReason: otherReasonValidationSchema,
});

export const AcknowledgementAndSignatureValidationSchemaWithoutPrudential =
  yup.object({
    termCondition: yup
      .boolean()
      .oneOf(
        [true],
        "Acceptance of the terms and conditions is required to proceed."
      )
      .nullable(),
    isPlanReasons: yup.boolean(),
    pensionSchemeBenefits: pensionSchemeBenefitsSchema,
    planReason: planReasonValidationSchema,
    otherReason: otherReasonValidationSchema,
  });

export const eventsFilterValidationSchema = yup.object({
  events: yup.string().nullable(),
  start_date: yup
    .date()
    .typeError("Invalid date!")
    .nullable()
    .test({
      message: () => "Start date must be less then end date.",
      test: (start_date, scheme) => {
        const { end_date } = scheme.parent;
        if (start_date && end_date) {
          return !!moment(start_date).isSameOrBefore(moment(end_date));
        }
        return true;
      },
    })
    .when("end_date", {
      is: (end_date) => end_date,
      then: yup
        .date()
        .required("Start date is required.")
        .typeError("Invalid date!")
        .nullable(),
    }),
  end_date: yup.date().typeError("Invalid date!").nullable(),
});

export const planCancellationValidationSchema = yup.object({
  isCancellationReasons: yup.boolean(),
  cancellationReason: planCancellationReasonValidationSchema,
  otherReason: otherCancellationReasonValidationSchema,
  isMovingToAnotherOrganisation: otherOrganisationValidationSchema,
  otherOrganisationName: otherOrganisationNameValidationSchema,
});

export const amendPlanValidationSchema = yup.object({
  title: yup
    .string()
    .required("Please choose at least one of the provided title.")
    .nullable(),
  first_name: firstNameValidationSchema,
  last_name: lastNameValidationSchema,
  date_of_birth: dobValidationSchema,
  mobile_number: yup
    .string()
    .required("Please provide your phone number.")
    .test(
      "Please provide your valid phone number.",
      "Please provide your valid phone number.",
      (val) => val?.replace(/ /g, "").length === 11
    )
    .matches(UKMobileRegex, "Please provide your valid phone number.")
    .nullable(),
  address1: yup
    .string()
    .required("Please provide your home address#1.")
    .nullable(),
  town: yup.string().required("Please provide your town name.").nullable(),
  county: yup.string().required("Please provide your county name.").nullable(),
  postcode: yup.string().required("Please provide your post code.").nullable(),
  avcAmount: avcAmountSchema,
  isAmendmentReason: yup.boolean(),
  amendmentReason: planAmendmentReasonValidationSchema,
  otherReason: otherAmendmentReasonValidationSchema,
});

export const requiredToAmendValidationSchema = yup.object({
  date_of_birth: dobValidationSchema,
  hours_per_week: hoursPerWeekValidationSchema,
  annual_salary: incomeValidationSchema,
});

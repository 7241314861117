import { gql } from "@apollo/client";

export const cancelEventBooking = gql`
  mutation cancelEventBooking($booking_id: Int!) {
    cancelBooking(booking_id: $booking_id) {
      message
    }
  }
`;

export const bookNewEvent = gql`
  mutation bookNewEvent(
    $organisation_id: Int!
    $presentation_id: Int!
    $five_years_to_retirement: Boolean!
  ) {
    NewEventBooking(
      five_years_to_retirement: $five_years_to_retirement
      organisation_id: $organisation_id
      presentation_id: $presentation_id
    ) {
      message
    }
  }
`;

export const markUserPositiveAttendence = gql`
  mutation markUserPositiveAttendence(
    $user_id: Int!
    $organisation_id: Int!
    $presentation_id: Int!
  ) {
    update_booked_user_presentations(
      _set: { attended: true }
      where: {
        _and: [
          { cancelled_at: { _is_null: true } }
          { user_id: { _eq: $user_id } }
          { organisation_id: { _eq: $organisation_id } }
          { presentation_id: { _eq: $presentation_id } }
        ]
      }
    ) {
      returning {
        attended
      }
    }
  }
`;

import { CircularProgress, InputAdornment, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import PropTypes from "prop-types";
import { checkFieldError } from "../../../helpers";
import useStepper from "../../../hooks/useStepper";
import useUserEvent from "../../../hooks/useUserEvent";
import debounce from "lodash/debounce";
import { useEffect, useState } from "react";

const TextInput = ({
  id,
  name,
  label,
  value,
  styleClass,
  trackingDetails,
  handleChangeValue,
  handleBlurValue,
  customError,
  isValidValue = () => true,
  disabled = false,
  loading = false,
}) => {
  const { touched, errors, handleChange, handleBlur, values } =
    useFormikContext();
  const { userTrackingMutation } = useUserEvent();
  const { trackingCode } = useStepper();
  const [fieldValue, setFieldValue] = useState(values[name] || value);

  const handleUserEvent = (element) => {
    if (element?.target?.value) {
      userTrackingMutation({
        variables: {
          ...trackingDetails,
          field_name: element?.target?.name || name || "",
          field_value: element?.target?.value?.toString() || "",
          avc_track_code: trackingCode,
        },
      });
    }
  };

  useEffect(() => {
    setFieldValue(values[name] || value);
  }, [values[name], value]);

  const debouncedHandleChange = debounce((e) => {
    handleChange(e);
    handleChangeValue && handleChangeValue(e, isValidValue(e));
  }, 500);

  return (
    <>
      <TextField
        id={id}
        name={name}
        label={label}
        value={fieldValue}
        disabled={disabled}
        variant="outlined"
        className={`${styleClass} mt-18`}
        fullWidth
        onChange={(e) => {
          debouncedHandleChange(e);
          isValidValue(e) ? setFieldValue(e?.target?.value) : setFieldValue("");
        }}
        onFocus={(e) => {
          handleUserEvent(e);
        }}
        onBlur={(e) => {
          handleUserEvent(e);
          handleBlur(e);
          handleBlurValue && handleBlurValue(e);
        }}
        error={
          customError && customError[name]
            ? customError[name]
            : checkFieldError(touched, errors, name)
        }
        helperText={
          customError && customError[name]
            ? customError[name]
            : checkFieldError(touched, errors, name)
        }
        InputProps={{
          endAdornment: (
            <>
              {loading && (
                <InputAdornment position="end">
                  <CircularProgress size={20} color="inherit" />
                </InputAdornment>
              )}
            </>
          ),
        }}
      />
    </>
  );
};

TextInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  trackingCode: PropTypes.string,
  styleClass: PropTypes.string,
  handleChangeValue: PropTypes.func,
  handleBlurValue: PropTypes.func,
  trackingDetails: PropTypes.object,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  isValidValue: PropTypes.func,
  customError: PropTypes.object,
};

export default TextInput;

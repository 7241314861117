import { Box, Grid, useMediaQuery } from "@mui/material";
import React from "react";
import avcwiseLogo from "../../../../assets/images/avcwise-logo.png";
import ButtonSection from "./ButtonSection";
import GlobalHeaderLinksection from "./GlobalHeaderLinksection";
import GobalHeaderMobile from "./GobalHeaderMobile";
import { TRACKING_HEADER_OPTIONS } from "../../../../constants/index.js";
import LogoButton from "../../../generic-components/logo-btn";

const GlobalHeader = () => {
  const Tablet = useMediaQuery("(max-width:900px)");

  return (
    <>
      <Grid item xs={6} md={2}>
        <Box className="header-logo-container">
          <LogoButton
            name="AVCWISE"
            logoPath={avcwiseLogo}
            trackingDetails={TRACKING_HEADER_OPTIONS}
            styleClass="header-logo"
          />
        </Box>
      </Grid>
      {!Tablet ? (
        <Grid item xs={7.5}>
          <GlobalHeaderLinksection />
        </Grid>
      ) : (
        ""
      )}
      <Grid item xs={6} md={2.5}>
        <Box className="header-Dropdown-container">
          {Tablet ? <GobalHeaderMobile /> : <ButtonSection />}
        </Box>
      </Grid>
    </>
  );
};
export default GlobalHeader;

import { Box, Container, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TRACKING_MANAGE_MY_SHARED_COST_OPTIONS } from "../../../../../constants";
import LoadingButton from "../../../../generic-components/button";

const ManageMySharedCostWithNoPlans = ({ organisation }) => {
  const navigate = useNavigate();

  const handleRedirection = (url, navigation = false) => {
    if (navigation) {
      navigate(url);
    } else {
      window.location.href = `${process.env.REACT_APP_AVCWISE_URL}${url}`;
    }
  };

  return (
    <Container>
      {organisation && (
        <Stack
          direction="row"
          justifyContent="center"
          className="organisation-large-logo"
        >
          <Box
            component="img"
            alt={organisation.name}
            className="organisation-logo"
            src={organisation.logo}
          />
        </Stack>
      )}
      <Box className="no-plan-description">
        <Typography className="mt-10">
          <strong>
            You don't have a Shared Cost AVC application or plan yet.
          </strong>
        </Typography>
        <Typography className="mt-10">
          Please click the button below to learn more about Shared Cost AVCs.
        </Typography>
        <LoadingButton
          buttonTitle="Click to learn more"
          trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
          handleClick={() => {
            handleRedirection("/home", true);
          }}
          styleClass="btn primary-clr-btn mt-30"
        />
      </Box>
      <Box className="no-plan-description mt-100 mb-30">
        <Typography className="strong-heading mt-50">
          <strong>Discover more using the below resources</strong>
        </Typography>
        <Stack
          className="bottom-header mt-12"
          direction={{ xs: "column", md: "row", sm: "row" }}
          justifyContent={{ xs: "center", md: "space-between" }}
          spacing={{ xs: 1, sm: 2, md: 2 }}
        >
          <LoadingButton
            buttonTitle="Book your place on a webinar"
            trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
            handleClick={() => {
              handleRedirection("/events", true);
            }}
            styleClass="btn primary-clr-btn mt-30"
          />
          <LoadingButton
            buttonTitle="Watch the bitesize videos"
            trackingDetails={TRACKING_MANAGE_MY_SHARED_COST_OPTIONS}
            handleClick={() => {
              handleRedirection("/videos", false);
            }}
            styleClass="btn primary-clr-btn mt-30"
          />
        </Stack>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    organisation: state.organisation.organisation,
  };
};

ManageMySharedCostWithNoPlans.propTypes = {
  organisation: PropTypes.object,
};

export default connect(mapStateToProps)(ManageMySharedCostWithNoPlans);

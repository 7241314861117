import { Box, Grid, Stack, useMediaQuery } from "@mui/material";
import React from "react";
import avcwiseLogo from "../../../../assets/images/avcwise-logo.png";
import Logoutsection from "./Logoutsection";
import HeaderLinksection from "./HeaderLinksection";
import HeaderMobile from "./HeaderMobile";
import LogoButton from "../../../generic-components/logo-btn";
import { TRACKING_HEADER_OPTIONS } from "../../../../constants/index.js";
import useStepper from "../../../../hooks/useStepper";
import LogoutButton from "./LogoutButton";

const LoggedinHeader = () => {
  const { personalDetails } = useStepper();
  const Tablet = useMediaQuery("(max-width:900px)");

  return (
    <>
      <Grid item xs={6} md={2}>
        <Box className="header-logo-container">
          <LogoButton
            name="AVCWISE"
            logoPath={avcwiseLogo}
            trackingDetails={TRACKING_HEADER_OPTIONS}
            styleClass="header-logo"
          />
        </Box>
      </Grid>
      {!Tablet && personalDetails && personalDetails.role_id ? (
        <Grid item xs={8}>
          <HeaderLinksection />
        </Grid>
      ) : (
        ""
      )}
      {personalDetails && personalDetails.role_id ? (
        <Grid item xs={6} md={2}>
          <Box className="header-Dropdown-container">
            {Tablet ? <HeaderMobile /> : <Logoutsection />}
          </Box>
        </Grid>
      ) : (
        <Grid item xs={6} md={10}>
          <Stack direction="row" justifyContent="flex-end">
            <LogoutButton />
          </Stack>
        </Grid>
      )}
    </>
  );
};
export default LoggedinHeader;

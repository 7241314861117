import { gql } from "@apollo/client";

export const getUserDetails = gql`
  query userDetails {
    me {
      id
      ni_number
      title
      first_name
      last_name
      date_of_birth
      telephone_number
      mobile_number
      latest_plan
      latest_active_plan
      latest_temp_plan
      address1
      address2
      town
      county
      postcode
      email
      employee_number
      is_employer_and_employee
      backend_user
      future_presentations
      total_roles
      role_id
      role_name
      investment_guidance
      organisation_id
      organisations
      tracking_code
      active_schemes
      allow_referral_code
    }
  }
`;
